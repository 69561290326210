import React from 'react'
import { observer } from 'mobx-react'
import { Button, Row, Col } from 'react-bootstrap'
import { observable, reaction } from 'mobx'
import { i18nTranslate, getLiveEventStatus, isExpressCheckout } from 'src/utils'
import { checkoutContainer, tokenExContainer, storeContainer } from 'src/models'
import PaymentForm from './PaymentForm'
import { BillingAddressForm } from './BillingAddressForm'
import { parsePhoneNumberField } from 'src/utils/signUpUtils'
import { IS_BROWSER } from 'config/appConfig'
import { trackGTMEvents } from 'src/utils'
import './styles.scss'

@observer
class PaymentMarketForm extends React.Component {
  @observable combinedFormData = {
    paymentData: {},
    addressData: {},
  }

  @observable paymentFormRef = React.createRef()
  @observable AddressFormRef = React.createRef()
  @observable paymentFormValid = false
  @observable addressFormValid = false
  @observable enableLiveEvent = getLiveEventStatus()
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const reactions = reaction(
      () => [this.paymentFormValid, this.addressFormValid],
      (values, reaction) => {
        if (values[0] && values[1]) {
          this.makeAPIsIfBothFormsAreValid()
        }
      }
    )
    if (this.enableLiveEvent === 'true') {
      // In Live Event enable shipping address by default for new payment
      if (this.props?.isFromCheckout) {
        if (!this.props?.isPaymentEdit) {
          checkoutContainer.paymentBillingSameAsShippingFlag = false
        }
      }
    }
  }
  makeAPIsIfBothFormsAreValid = async () => {
    let formData = {
      ...this.combinedFormData.paymentData,
      ...this.combinedFormData.addressData,
    }
    const {
      onSubmitForm = function () {},
      isFromEditPayment = false,
      isFromEditSubscription = false,
      isPaymentEdit = false,
    } = this.props

    let newTransformedFormData = {}
    const {
      phone = '',
      phoneCountryCode = '',
      zip = '',
      postalCode = '',
      addressLine2 = '',
      city = '',
    } = formData
    let phoneNumber = ''
    if (phone !== '') {
      const phoneObj =
        (await parsePhoneNumberField(phone, phoneCountryCode)) || {}
      phoneNumber = phoneObj?.number || ''
    }
    newTransformedFormData = {
      ...formData,
      billingSameAsShipping: checkoutContainer.paymentBillingSameAsShippingFlag,
      addPaymentToProfile: checkoutContainer.addPaymentToProfile,
      phone: phoneNumber,
      zip: zip || postalCode.toUpperCase(),
      addressLine2: addressLine2 || '',
      city: city || '',
    }
    if (isExpressCheckout()) {
      if (this.paymentFormRef?.current?.values?.addPaymentToProfile) {
        newTransformedFormData = {
          ...newTransformedFormData,
          addPaymentToProfile: true,
        }
      } else {
        newTransformedFormData = {
          ...newTransformedFormData,
          addPaymentToProfile: false,
        }
      }
    }

    const proceedSubmit = () => {
      if (typeof onSubmitForm === 'function') {
        let newFormData =
          !checkoutContainer.paymentBillingSameAsShippingFlag ||
          isFromEditPayment
            ? transformedFormData
            : isPaymentEdit
            ? { paymentMethodType: paymentType, ...newTransformedFormData }
            : newTransformedFormData
        const tokenizedData = {
          cardNumber: tokenExContainer.tokenExFormData?.lastFour,
          provider: tokenExContainer.tokenExFormData?.cardType?.toUpperCase(),
        }
        {
          if (
            !(
              storeContainer.storeIDValue?.toLowerCase() === 'canada' &&
              tokenExContainer.tokenExFormData?.cardType?.toUpperCase() ===
                'DISCOVER'
            )
          ) {
            formData?.setToDefaultPayment &&
              trackGTMEvents({
                event: 'paymentSetAsDefault',
                context: 'PROFILE',
                paymentType:
                  tokenExContainer.tokenExFormData?.cardType?.toUpperCase() ||
                  '',
                method: 'addPaymentLink',
              })
            onSubmitForm({
              ...newFormData,
              ...(isFromEditPayment ? {} : tokenizedData),
              ...(isFromEditSubscription ? tokenizedData : {}),
            })
          }
        }
      }
    }
    const {
      stateList = '',
      wardList = '',
      districtList = '',
      state = '',
      district = '',
      ...remainingFormData
    } = newTransformedFormData
    const stateValue = stateList || state
    const cityValue = wardList || city
    const districtValue = districtList || district
    const paymentType = 'CREDITCARD'
    const transformedFormData = {
      ...remainingFormData,
      state: stateValue,
      county: districtValue,
      city: cityValue,
      paymentMethodType: paymentType,
    }

    {
      isFromEditPayment
        ? proceedSubmit()
        : tokenExContainer.tokenize(proceedSubmit)
    }
  }

  handleSubmit = async () => {
    if (this.paymentFormRef?.current?.values?.addPaymentToProfile) {
      trackGTMEvents({
        event: 'paymentUsedPreviouslySaved',
        eventType: 'Payment used for Order saved to Profile',
      })
    }
    this.paymentFormValid = false
    this.addressFormValid = false
    await this.paymentFormRef.current.handleSubmit()
    !checkoutContainer.paymentBillingSameAsShippingFlag &&
      (await this.AddressFormRef.current.handleSubmit())
  }

  handlePaymentSubmit = data => {
    if (!tokenExContainer?.tokenExValidData?.isCvvValid) {
      return false
    }
    this.combinedFormData = { ...this.combinedFormData, paymentData: data }
    this.paymentFormValid = true
    this.addressFormValid = checkoutContainer.paymentBillingSameAsShippingFlag
      ? true
      : false
    const { isFromEditPayment = false, paymentData = {} } = this.props
    if (
      checkoutContainer.paymentBillingSameAsShippingFlag &&
      isFromEditPayment
    ) {
      this.combinedFormData = {
        ...this.combinedFormData,
        addressData: paymentData?.billingAddress,
      }
    }
  }

  handleAddressSubmit = data => {
    this.combinedFormData = { ...this.combinedFormData, addressData: data }
    this.addressFormValid = true
  }

  handleCancelButton = () => {
    const { onCancel = function () {} } = this.props
    if (typeof onCancel === 'function') {
      onCancel()
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
    }
  }

  renderSaveChangesButton = () => {
    return (
      <Button
        variant="primary"
        type="submit"
        form="payment-form"
        className="button-style"
        data-testid="qa-save-changes"
        onClick={() => {
          tokenExContainer.tokenize()
          this.handleSubmit()
        }}>
        {i18nTranslate('checkout.saveChanges', 'Save Changes')}
      </Button>
    )
  }

  renderContinueButton = () => {
    return (
      <Row className="d-flex w-100 mt-3 continue-btn-wrapper" noGutters>
        <Col className="new-payment-cancel-btn-wrapper pr-1">
          <Button
            variant="primary"
            type="submit"
            form="payment-form"
            className="button-style express-action-d-none"
            data-testid="qa-add-new-pay-save"
            id="add-new-pay-save"
            disabled={checkoutContainer.isPaymentApiInProgress}
            onClick={event => {
              // CX121-3102 - to prevent multiple clicks
              // https://stackoverflow.com/a/42664936
              if (!event.detail || event.detail == 1) {
                tokenExContainer.tokenize()
                this.handleSubmit()
              }
            }}>
            {i18nTranslate('checkout.save', 'Save')}
          </Button>
        </Col>
        <Col className="pl-1 new-payment-cancel-btn-wrapper">
          <Button
            id="new-payment-cancel-btn-checkout"
            variant="outline-primary"
            type="submit"
            form="payment-form"
            className="button-style express-action-d-none"
            data-testid="qa-add-new-pay-cancel"
            onClick={this.props?.cancelPaymentFlag}>
            {i18nTranslate('checkout.cancel', 'Cancel')}
          </Button>
        </Col>
      </Row>
    )
  }

  renderPaymentSubmitButton = () => {
    const { isFromEditPayment = false } = this.props
    return (
      <Row className="edit-payment-button-container-row w-100" noGutters>
        <Col className="edit-payment-button-container-first-col">
          {isFromEditPayment
            ? this.renderEditPaymentSubmitButton()
            : this.renderAddPaymentSubmitButton()}
        </Col>
        <Col className="edit-payment-button-container-last-col">
          {this.renderCancelButton()}
        </Col>
      </Row>
    )
  }

  renderAddPaymentSubmitButton = () => {
    return (
      <Button
        variant="primary"
        type="submit"
        form="payment-form"
        className="button-style payment-add-button add-button"
        data-testid="qa-add-card-submit"
        onClick={() => {
          tokenExContainer.tokenize()
          this.handleSubmit()
        }}>
        {i18nTranslate('payment.submit', 'Add Payment')}
      </Button>
    )
  }

  renderEditPaymentSubmitButton = () => {
    return (
      <Button
        variant="primary"
        type="submit"
        form="payment-form"
        className="button-style"
        data-testid="qa-save-changes"
        onClick={() => {
          tokenExContainer.tokenize()
          this.handleSubmit()
        }}>
        {i18nTranslate('payment.saveChanges', 'Save')}
      </Button>
    )
  }

  renderCancelButton = () => {
    return (
      <Button
        id="new-payment-cancel-btn-profile"
        variant="outline-primary"
        className="button-style"
        onClick={this.handleCancelButton}
        data-testid="qa-cancel-payment">
        {i18nTranslate('payment.cancel', 'Cancel')}
      </Button>
    )
  }

  renderSubmitButton = () => {
    const { isFromPayment = false, isFromEditSubscription = false } = this.props
    const continueButton = isFromEditSubscription
      ? this.renderSaveChangesButton()
      : this.renderContinueButton()

    const submitButtonView = isFromPayment ? (
      this.renderPaymentSubmitButton()
    ) : isFromEditSubscription ? (
      <Row className="edit-payment-button-container-row w-100" noGutters>
        <Col>{continueButton}</Col>
      </Row>
    ) : (
      continueButton
    )

    return (
      <Row noGutters className="justify-content-end">
        {submitButtonView}
      </Row>
    )
  }

  render() {
    const {
      paymentData = {},
      isPaymentEdit = false,
      isFromCheckout = false,
      formType = false,
      billingAddressType = '',
      billingAddress = {},
    } = this.props
    let className = formType ? formType : 'payment-market-form'

    if (this.enableLiveEvent === 'true') {
      if (isFromCheckout && !isPaymentEdit) {
        className += ' live-event-enabled'
      }
    }

    const isNewPaymentFomEventCheckout =
      this.enableLiveEvent === 'true' && isFromCheckout && !isPaymentEdit

    return (
      <div className={className}>
        {/*Payment Form */}
        <PaymentForm
          onSubmit={this.handlePaymentSubmit}
          refId={this.paymentFormRef}
          hideDefaultSubPaymentCheckbox={
            this.props.hideDefaultSubPaymentCheckbox || false
          }
          billingAddress={billingAddress}
          {...this.props}
        />
        {(!checkoutContainer.paymentBillingSameAsShippingFlag ||
          isNewPaymentFomEventCheckout) &&
          IS_BROWSER && (
            <>
              {/*Address Form */}
              <BillingAddressForm
                onSubmit={this.handleAddressSubmit}
                refId={this.AddressFormRef}
                isFromSubscriptionPayment={isPaymentEdit}
                addressInfo={paymentData?.billingAddress}
                type="payment"
                isFromCheckout={isFromCheckout}
                billingAddressType={billingAddressType}
                selectedMarket={checkoutContainer.selectedMarket}
                defaultMarket={storeContainer.defaultShippingRegion}
              />
            </>
          )}
        {this.renderSubmitButton()}
      </div>
    )
  }
}

export default PaymentMarketForm
export { PaymentMarketForm }
