import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Button, Row } from 'react-bootstrap'
import {
  i18nTranslate,
  getTranslatedShippingMethod,
  isExpressCheckout,
  getCheckoutGTMPageData,
  scrollState,
} from 'src/utils'
import {
  currencyFormat,
  isB2BAccount,
  isB2B2C,
  showLocationDetails,
  trackEnhancedCheckout,
  trackCustomCheckoutEvent,
} from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { checkoutContainer, cartContainer, accountsContainer } from 'src/models'
import { toastState, modalState, overlayState } from 'src/views/components'
import { isPromoWalletEnabled } from 'src/views/components/CheckoutAccordions/deps'
import TagManager from 'react-gtm-module'
import ExpressSectionHeader from '../../ExpressSectionHeader'

@observer
class ShippingMethodAccordion extends React.Component {
  @observable pickUpLocationResponse = {}
  @observable isToMakeLocationCall = false
  @observable selectedShippingMethod = {}
  @observable isCallInProgress = false
  @observable showList = !!!isExpressCheckout()
  @observable showLoaderOnCheck = false

  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    checkoutContainer.isToDisableSubmitOrderButton = true
    if (cartContainer?.cartResponse?.payments) {
      await checkoutContainer.deletePayment()
    }
    if (isB2BAccount() === true && !isB2B2C()) {
      await accountsContainer.getContracts()
    }
    // TODO EX: need to check  API response
    if (isExpressCheckout()) {
      const shippingMethods =
        cartContainer?.cartResponse?.deliveryDetails?.methods?.sort(
          (a, b) => a?.cost - b?.cost
        )
      if (shippingMethods?.length > 0) {
        const selectedShippingMethod = shippingMethods[0]
        this.handleClick(selectedShippingMethod.id, selectedShippingMethod.cost)
      }
    }
    this.getSelectedShippingMethod()
    this.setAlertZeroTotValWithDisableZeroCheckout()
  }

  getSelectedShippingMethod = async () => {
    const shippingMethods =
      cartContainer?.cartResponse?.deliveryDetails?.methods || []
    this.pickUpLocationResponse = {}
    this.selectedShippingMethod = shippingMethods?.filter(
      shippingMethod => shippingMethod.isSelected === true
    )
    if (this.selectedShippingMethod.length > 0)
      await this.makePickUpLocationCall(this.selectedShippingMethod[0])
  }

  makePickUpLocationCall = async selectedMethod => {
    const { properties = {} } = selectedMethod
    const bopisValue = properties?.BOPIS || false
    const locationCode = properties?.locationCode || ''
    this.isToMakeLocationCall = bopisValue === true && locationCode !== ''

    if (this.isToMakeLocationCall === true) {
      this.pickUpLocationResponse = await checkoutContainer.getPickUpLocations(
        locationCode
      )
    }
  }

  handleClick = async id => {
    this.isCallInProgress = true
    if (isExpressCheckout() && this.showLoaderOnCheck) {
      overlayState.showLoader()
    }
    let response = await checkoutContainer.updateShippingMethod(id)
    const { userId = '', cartId = '', accountsId = '' } = response

    response?.items?.forEach(item => {
      item &&
        item.skus &&
        item.skus?.map(bundleItem => {
          let inventoryProperties = bundleItem.inventoryProperties
          if (
            (inventoryProperties?.available === false &&
              inventoryProperties?.backOrdered === true) ||
            (inventoryProperties?.available === false &&
              inventoryProperties?.backOrdered === false) ||
            (inventoryProperties?.available === false &&
              inventoryProperties?.backOrdered === null)
          ) {
            toastState.setToastMessage(
              i18nTranslate(
                'checkout.backOrder',
                'Sorry, this item is on backorder or out of stock.'
              ),
              false
            )
            setTimeout(() => {
              let originUrl = window?.location?.href?.split('/checkout') || []
              window.location.replace(`${originUrl?.[0]}${pageNames?.viewCart}`)
            }, 1000)
          }
        })
    })

    if (!checkoutContainer.isSuccessResponse(response)) {
      toastState.setToastMessage(response.message)
      if (isExpressCheckout() && this.showLoaderOnCheck) {
        overlayState.hideLoader()
      }
    } else {
      isPromoWalletEnabled() && (await cartContainer.promoCodeList())
      await this.getSelectedShippingMethod()
      this.showList = !isExpressCheckout()
      if (isExpressCheckout() && this.showLoaderOnCheck) {
        scrollState.smoothScrollToModalElement(
          `#qa-checkout-shippingmethod-accordion`
        )
        overlayState.hideLoader()
      }
    }

    this.isCallInProgress = false

    TagManager.dataLayer({
      dataLayer: {
        event: 'shippingMethodChangeTotal',
        ...getCheckoutGTMPageData(),
        userId,
        accountsId,
        cartId,
        selectedShippingMethodDetails: this.selectedShippingMethod,
        platform: 'equinox',
      },
    })
    return response
  }

  handleNext = async () => {
    this.isCallInProgress = true
    const products = cartContainer?.cartResponse?.items || []
    const { title } = this.props
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '2', option: title },
      products: products,
    })
    trackCustomCheckoutEvent({
      eventType: 'shipping',
      cartResponse: cartContainer?.cartResponse,
    })
    if (isB2BAccount() && !isB2B2C()) {
      const costCenterValidateResponse =
        await checkoutContainer.validateCostCenter()
      const errMessage = costCenterValidateResponse?.message || ''

      if (!checkoutContainer.isSuccessResponse(costCenterValidateResponse)) {
        this.props.history.push(`${pageNames.viewCart}`)
        const message = errMessage
          ? errMessage
          : i18nTranslate(
              'checkout.validatecostCenter',
              'Please select a valid cost center'
            )
        toastState.setToastMessage(message)
      } else {
        this.props.onContinue()
      }
    } else {
      this.props.onContinue()
    }
    this.isCallInProgress = false
  }

  // TODO EX: need to check  the removed code
  renderShippingMethods = () => {
    // const currencyCode = get(cartContainer, 'cartResponse.currencyCode')
    const shippingMethods =
      cartContainer.cartResponse?.deliveryDetails?.methods || []
    const selectedShippingMethod = shippingMethods.find(
      method => method.isSelected
    )
    if (shippingMethods?.length == 0 && isExpressCheckout()) {
      return (
        <>
          <ExpressSectionHeader
            title={i18nTranslate('checkout.shippingMethod', 'Shipping Method')}
            disableAccordion={true}
          />

          <div className="emptyShipping-method p-3 mb-2">
            {i18nTranslate(
              'checkout.emptyShippingMethod',
              'Shipping Method will be available once you enter shipping address'
            )}
          </div>
        </>
      )
    }

    const shippingLabel = (
      id,
      price,
      styleClasses = 'justify-content-between'
    ) => {
      const shippingMethodName =
        getTranslatedShippingMethod(id, isExpressCheckout()) || ''
      if (isExpressCheckout()) {
        return (
          <div className="d-flex flex-column express-header-body">
            <div className={`d-flex ${styleClasses}`}>
              <span className="font-weight-400">
                {shippingMethodName?.[0] || ''}
              </span>
              <span className="font-weight-bold">{price}</span>
            </div>
            {shippingMethodName?.[1] && (
              <span className="shippingDesciption">
                {shippingMethodName?.[1] || ''}
              </span>
            )}
          </div>
        )
      }
      return `${shippingMethodName} ${price}`
    }

    const getShippingMethodClassName = selected => {
      if (isExpressCheckout()) {
        return selected
          ? 'express-checkout-check express-checkout-selected'
          : 'express-checkout-check'
      }
      return ''
    }

    return (
      <>
        {isExpressCheckout() && (
          <ExpressSectionHeader
            title={i18nTranslate('checkout.shippingMethod', 'Shipping Method')}
            isOpen={this.showList}
            onClick={() => {
              this.showList = !this.showList
              this.showLoaderOnCheck = true
            }}
          />
        )}

        {this.showList &&
          shippingMethods &&
          shippingMethods?.map(shippingMethod => {
            const { name, id, cost, isSelected, properties } = shippingMethod
            const storePickUpLocation = isExpressCheckout()
              ? properties?.BOPIS === true && properties?.locationCode !== ''
              : true
            //if case to hide storepick from API
            if (id.toLowerCase() !== 'storepickup') {
              const shippingCost = currencyFormat({
                // currency: currencyCode,
                price: cost,
              })

              return (
                <div
                  key={`shippingmethod-${name}`}
                  className={
                    isExpressCheckout()
                      ? `${!this.showList ? 'mb-4 border-bottom' : ''}`
                      : 'mb-2'
                  }
                  data-testid={
                    isSelected ? 'qa-ship-method-selected' : 'qa-ship-method'
                  }
                  tabIndex={0}>
                  <Form.Check
                    className={getShippingMethodClassName(isSelected)}
                    id={`methods-${name}`}
                    label={shippingLabel(id, shippingCost)}
                    type="radio"
                    checked={isSelected}
                    onChange={() => this.handleClick(id, cost)}
                  />
                  {isSelected &&
                    storePickUpLocation &&
                    this.isToMakeLocationCall &&
                    showLocationDetails(this.pickUpLocationResponse)}
                </div>
              )
            }
          })}

        {isExpressCheckout() && !this.showList && (
          <div className="w-100">
            {shippingLabel(
              selectedShippingMethod?.id,
              currencyFormat({
                price: selectedShippingMethod?.cost,
              }),
              'gap-1 shippingDesciption font-weight-bold text-dark'
            )}
          </div>
        )}
      </>
    )
  }

  setAlertZeroTotValWithDisableZeroCheckout = () => {
    if (checkoutContainer.isZeroTotValWithDisableZeroCheckout()) {
      modalState.setModalMessage(
        i18nTranslate(
          'shipping.alertZeroTotValWithDisableZeroCheckout',
          'Zero value checkout is not allowed. Please update your cart to proceed further.'
        ),
        i18nTranslate('modaView.buttonText', 'OK'),
        this.props,
        pageNames.viewCart
      )
    }
  }

  renderContinueButton = () => {
    if (isExpressCheckout()) {
      return ''
    }
    return (
      <Row className="justify-content-end" noGutters>
        <Button
          className="rounded-0 px-5 py-2 shipping-method-continue btn-bg-blue"
          onClick={this.handleNext}
          disabled={this.isCallInProgress}
          data-testid="qa-ship-methods-continue">
          {i18nTranslate('shippingMethod.continue', 'Continue')}
        </Button>
      </Row>
    )
  }

  render() {
    return (
      <Form>
        {this.renderShippingMethods()}
        {this.renderContinueButton()}
      </Form>
    )
  }
}

export { ShippingMethodAccordion }
export default ShippingMethodAccordion
