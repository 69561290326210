import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'react-bootstrap'
import { getAppConfig, APPConfig } from 'config/appConfig'
import {
  isB2B2C,
  checkIsSaleStarted,
  IS_BROWSER,
  i18nTranslate,
  currencyFormat,
  ApiContent,
  getLiveEventStatus,
  isExpressCheckout,
} from 'src/utils'
import { IndividualProductQuantityAndButtons } from './IndividualProductQuantityAndButtons'
import { Link } from 'react-router-dom'
import {
  ProductAddToList,
  ProductSubscribeButton,
  ProductAddToCart,
} from './ProductButtons'
import { ProductSkuSelection } from './ProductSkuSelection'
import { ProductPrice } from './ProductPrice'
import { ProductShortDescription } from './ProductShortDescription'
import { SubscribeAndSaveText } from './SubscribeAndSaveText'
import { RetailPrice } from './RetailPrice'
import { ProductEarnAndRedeemText } from './ProductEarnAndRedeemText'
// import { ProductPromotion } from './ProductPromotion'
import { ProductTitle } from './ProductTitle'
import { PreOrderTime } from '../PreOrderTime'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { ProductItemId } from '../../ProductItemId'
import Freegifts from './FreeGift'
import ProductLeaderPreviewMessages from './ProductLeaderPreviewMessages'
import {
  cartContainer,
  customerContainer,
  storeContainer,
  checkoutContainer,
} from 'src/models'
import { V2BundlePDP } from 'src/views/components/BundlePDP/V2BundlePDP'
import { productDeps } from 'src/deps'
import { ProductFavoritesWithText } from './ProductFavoritesWithText'
import { V2ProductSVCV } from './V2ProductSVCV'
import { PDPUnitPrice } from 'src/views/components/Price'
import { V2ProductQuantityAndButtons } from 'src/views/components/ProductComponents/V2ProductQuantityAndButtons/V2ProductQuantityAndButtons'
import './styles.scss'
import { ProductDetailsSectionBundleKits } from '../ProductDetailsSectionBundleKits'

@observer
class ProductDetail extends React.Component {
  static contextType = ApiContent
  constructor(props, context) {
    super(props, context)
  }

  getAccountType = () => {
    if (IS_BROWSER) {
      return customerContainer.profileResponse?.accounts?.type || ''
    }
    return this?.context?.customerType
  }
  setCurrentSku = (data = {}) => {
    const { selectSkuById } = this.props
    const skuId = data?.identifier
    selectSkuById(skuId)
  }

  getIsBundleOrCollection = (
    isBundleOrCollection = false,
    businessRelationship = ''
  ) => {
    const { product = {} } = this.props
    const { isCollection = false, isBundle = false } = product
    if (isBundleOrCollection) {
      return isCollection || isBundle
    } else {
      return (
        !isCollection &&
        !isBundle &&
        getAppConfig()?.businessRelationship === businessRelationship
      )
    }
  }

  renderButtonsForB2B = handleAddToBag => {
    const { product, ...remainingProps } = this.props
    return (
      <Row noGutters className="w-100 py-3">
        <Col xs={12} md={12} className="pb-3">
          <ProductAddToCart
            {...product}
            {...remainingProps}
            handleAddToBag={handleAddToBag}
          />
        </Col>
        <Col xs={12} md={6} className="pb-1">
          <ProductAddToList {...this.props} />
        </Col>
        <Col xs={12} md={6}>
          <ProductSubscribeButton {...this.props} />
        </Col>
      </Row>
    )
  }

  renderQtyAndButtons = (edit = '') => {
    const {
      product = {},
      isBundleView = false,
      isSubscribeBtnDisabled,
    } = this.props
    const inventory = this.props?.inventory
    const { subscriptions = false, isBundle = false } = product
    return (
      <IndividualProductQuantityAndButtons
        {...this.props}
        inventory={inventory}
        edit={edit}
        isAddToCartDisable={cartContainer.isDisableAddToCart}
        isSubscribeBtnDisabled={isSubscribeBtnDisabled}
      />
    )
  }

  renderSaleText = () => {
    const { product = {} } = this.props
    const eventProperties = checkIsSaleStarted()

    if (
      IS_BROWSER &&
      product?.salesText &&
      eventProperties?.isSaleInProgress &&
      !eventProperties?.isSaleEnded
    ) {
      return <div className="mt-1 sales-text">{product?.salesText}</div>
    }
    return <></>
  }

  getBundleKitSavings = () => {
    const customerType = this.getAccountType()
    const isBAorPreferred =
      customerType === 'Brand Affiliate' ||
      customerType === 'Brand Affiliate - Business Entity' ||
      customerType === 'Preferred Customer/Member' ||
      false

    const unitValue = productDeps.getUnitValue(this.props)

    // return productState.currentlySelectedPriceOption ===
    //   'one-time-price-button'
    //   ? unitValue?.priceFacets?.['Regular Price']?.['Regular PriceDiscount']
    //   : unitValue['Sub Wholesale Price']?.bundleDiscount

    if (productState.currentlySelectedPriceOption === 'one-time-price-button') {
      if (isBAorPreferred) {
        return unitValue?.priceFacets?.['Wholesale Price']?.bundleDiscount
      } else {
        return unitValue?.priceFacets?.['Regular Price']?.bundleDiscount
      }
    } else {
      if (isBAorPreferred) {
        return unitValue?.priceFacets?.['Sub Wholesale Price']?.bundleDiscount
      } else {
        return unitValue?.priceFacets?.['Sub Retail Price']?.bundleDiscount
      }
    }
  }
  isNegativeDiscount = unitValue =>
    unitValue?.priceAfterDiscount > unitValue?.originalPrice

  renderBundleKitSavings = () => {
    const { product = {} } = this.props
    let bundleKitSaving = this.getBundleKitSavings() || 0

    const formattedSavings = currencyFormat({
      currency: product?.currency || '',
      price: bundleKitSaving,
    })
    const isSpecialType = product.type === 'bundle' || product.type === 'kit'
    const unitValue = productDeps.getUnitValue(this.props)
    if (
      bundleKitSaving &&
      bundleKitSaving > 0 &&
      isSpecialType &&
      !this.isNegativeDiscount(unitValue)
    ) {
      let messageToDisplay = ''
      let borderClassName = ''
      let dataTestId = ''

      if (product.type == 'bundle') {
        messageToDisplay = i18nTranslate(
          'pdp.bundleSave',
          'By Bundling You Save',
          {
            nameSpace: 'ssr-resource',
          }
        )
        borderClassName = 'product-promo-message-bundle-border'
        dataTestId = 'qa-pdp-bundling-you-save'
      } else {
        messageToDisplay = i18nTranslate('pdp.kitSave', 'With a Kit You Save', {
          nameSpace: 'ssr-resource',
        })
        borderClassName = 'product-promo-message-kit-border'
        dataTestId = 'qa-pdp-kit-you-save'
      }

      return (
        <Row
          noGutters
          className={`product-promo-message ${borderClassName}`}
          data-testid={dataTestId}>
          {messageToDisplay} -{' '}
          <span className="product-promo-savings">{formattedSavings}</span>
        </Row>
      )
    }
    return <></>
  }
  renderBundleKitSubItems = () => {
    const product = this.props.product || {}
    const inventory = productState.inventory || {}
    const inventoryStatus = productState.inventoryStatus || []
    const bundleType = product?.type || 'product'
    const isBundleView = this.props?.isBundleView || false
    const handleBundleView = this.props?.handleBundleView || {}
    return (
      <V2BundlePDP
        isExpressPDP={this.props?.isExpressPDP}
        product={product}
        bundleType={bundleType}
        isBundleView={isBundleView}
        inventory={inventory}
      />
    )
  }
  renderIncludedInThisKit = () => {
    const { product = {} } = this.props
    if (
      product.type == 'kit' &&
      product?.skuKit?.length &&
      !isExpressCheckout()
    ) {
      return (
        <Row
          noGutters
          className="product-kit-description"
          data-testid="qa-pdp-included-in-this-kit">
          <div>
            {i18nTranslate('pdp.includedInKit', 'Included in this Kit', {
              nameSpace: 'ssr-resource',
            })}
            :
            <ul className="product-kit-list">
              {product?.skuKit?.map((subProduct, index) => (
                <li key={'sub' + index}>
                  <Link to={productDeps.getSubProductLink(subProduct)}>
                    {subProduct?.previousSku?.properties?.name || ''}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Row>
      )
    }
    return <></>
  }
  render() {
    const {
      product = {},
      subscribeAndSave = 0,
      isSubscribeBtnDisabled = false,
    } = this.props
    const {
      isBundle = false,
      isCollection = false,
      transactionPrice = 0,
      subscriptions = false,
    } = product
    //let dispaySubscribeAndSave = subscribeAndSave > 0 ? true : false

    // const pdtResponse = product?.isBundle
    //   ? productState?.bundlePromotionResponse
    //   : product
    let isExclusive = ''
    if (product?.sku?.length === 1) {
      isExclusive = product?.sku?.[0]?.properties?.isExclusive || ''
    } else if (product?.sku?.length > 1) {
      const currentlySelectedSkuId = product?.skuId
      isExclusive =
        product?.sku?.find(sku => sku.identifier == currentlySelectedSkuId)
          ?.properties?.isExclusive || ''
    }
    let profileResponse = customerContainer.profileResponse || {}
    let accounts = profileResponse?.accounts || {}
    let accType = accounts?.type || ''

    const hideLoyaltyAlways =
      APPConfig?.getAppConfig()?.hideLoyaltyAlways === 'true'
    const hideLoyaltyForUS =
      APPConfig?.getAppConfig()?.hideLoyaltyForUS === 'true'
    const storeIdBasedOnCountry =
      storeContainer?.storeIDValue?.toLowerCase() || ''
    const isUS = storeIdBasedOnCountry === 'us'
    const isToHideLoyaltyForUS = hideLoyaltyForUS && isUS
    const hideLoyaltyText = hideLoyaltyAlways || isToHideLoyaltyForUS
    const userCountry = customerContainer.getUsersCountry()
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    const enableLiveEvent = getLiveEventStatus() === 'true'
    const promotion = productDeps.getPromotionNode(this.props, productState)

    let productDetails = {}
    try {
      productDetails = JSON.parse(product?.productDetails)
    } catch {
      productDetails = {}
    }
    const enableProductDetailsSection =
      productDetails?.description?.length > 0 ||
      productDetails?.highlights?.length > 0 ||
      productDetails?.includedItems?.length > 0

    return (
      <>
        <div id="zoom-image-wrap" />
        <div className="product-title-wrapper">
          <ProductTitle {...this.props?.product} />
        </div>
        {/* new pdp changes, bundle kit and mobileview */}
        <Row noGutters className="itemid-and-fav-wrapper">
          {enableNewPDPStyle && (
            <ProductItemId
              isExpressPDP={this.props?.isExpressPDP}
              {...product}
            />
          )}
          {!enableNewPDPStyle && product.skuId && (
            <ProductItemId
              isExpressPDP={this.props?.isExpressPDP}
              {...product}
            />
          )}

          {enableNewPDPStyle && !enableLiveEvent && (
            <ProductFavoritesWithText
              product={this.props.product || {}}
              categoryId={this.props.categoryId || ''}
              location={this.props.location || {}}
              history={this.props.history || {}}
              className="product-fav-with-text-wrapper bg-transparent border-0 text-dark shadow-none"
            />
          )}
        </Row>
        {enableNewPDPStyle && (
          <V2ProductSVCV {...this.props} isFromPDP={true} />
        )}
        {/* to do for indivisual product price*/}
        {enableNewPDPStyle &&
          !(product?.type == 'kit' || product.type == 'bundle') && (
            <PDPUnitPrice {...this.props} />
          )}

        <PreOrderTime {...product} className="" />
        {/* @##pdp_price unit price for both normal and kit */}
        {/* not for quickview */}
        {!enableNewPDPStyle && !product?.isBundle && (
          <ProductPrice
            {...product}
            isFromPDP={true}
            shouldShowKlarnaMessage={false}
          />
        )}
        {enableNewPDPStyle && product?.type == 'kit' && (
          <div className="kit-price-with-label">
            <PDPUnitPrice {...this.props} />
          </div>
        )}
        {enableNewPDPStyle &&
          product.type === 'kit' &&
          this.renderBundleKitSavings()}
        {enableNewPDPStyle && this.renderIncludedInThisKit()}
        {enableNewPDPStyle &&
          product.type === 'bundle' &&
          this.renderBundleKitSubItems()}
        {enableNewPDPStyle &&
          product.type === 'bundle' &&
          this.renderBundleKitSavings()}
        {!enableNewPDPStyle && product?.isBundle && (
          <ProductPrice
            {...productState?.bundleUnitPriceResponse}
            type={product?.type}
            isBundle={product?.isBundle}
            isFromPDP={true}
            shouldShowKlarnaMessage={productDeps.isEnableAddToCartBtn(product)}
            shouldShowPaylaterMessage={true}
            userCountry={userCountry}
          />
        )}
        {this.renderSaleText()}
        {!enableNewPDPStyle &&
          (accType === 'Brand Affiliate' ||
            accType === 'Brand Affiliate - Business Entity' ||
            accType === 'Preferred Customer/Member') && (
            <RetailPrice {...product} />
          )}
        {!isSubscribeBtnDisabled && !product.isBundle && (
          <SubscribeAndSaveText
            subscribeAndSave={subscribeAndSave}
            {...product}
          />
        )}
        {!isSubscribeBtnDisabled && product.isBundle && (
          <SubscribeAndSaveText
            subscribeAndSave={subscribeAndSave}
            {...productState?.bundlePromotionResponse}
          />
        )}
        <ProductLeaderPreviewMessages
          isExclusive={isExclusive}
          {...this.props}
        />
        {/* <ProductRewards /> */}
        <ProductSkuSelection {...this.props} />
        {/* <ProductPromotion {...pdtResponse} isProductCard={true} /> */}
        {!hideLoyaltyText && <ProductEarnAndRedeemText {...product} />}
        <ProductShortDescription {...product} />
        {!enableNewPDPStyle &&
          (isB2B2C() || this.getIsBundleOrCollection(false, 'B2C')) &&
          this.renderQtyAndButtons('noEdit')}
        {enableNewPDPStyle &&
          (!(product?.type == 'kit' || product.type == 'bundle') ||
            this.props?.isExpressPDP) && (
            <V2ProductQuantityAndButtons
              {...this.props}></V2ProductQuantityAndButtons>
          )}
        {enableNewPDPStyle &&
          this.props?.isExpressPDP &&
          product.type == 'kit' &&
          this.renderBundleKitSubItems()}
        {!enableNewPDPStyle && !product.isBundle && (
          <Freegifts {...productState?.pdpPromotionResponse} />
        )}
        {!enableNewPDPStyle && product.isBundle && (
          <Freegifts
            {...productState?.bundlePromotionResponse}
            isProductCard={true}
          />
        )}
        {enableNewPDPStyle && <Freegifts promotion={promotion} />}
        {enableNewPDPStyle &&
          product.type == 'bundle' &&
          this.props.renderBundlePriceAndButtons?.(this.props?.isExpressPDP)}
        {enableNewPDPStyle &&
          product.type == 'kit' &&
          this.props.renderBundlePriceAndButtons?.(this.props?.isExpressPDP)}
        {enableNewPDPStyle &&
          !this.props?.isExpressPDP &&
          product.type == 'kit' &&
          this.renderBundleKitSubItems()}
        {/* product details for Bundle and Kit only*/}
        {isBundle && enableProductDetailsSection && (
          <div className="v2-product-description-wrapper">
            <ProductDetailsSectionBundleKits product={product} />
          </div>
        )}
      </>
    )
  }
}

export { ProductDetail }
export default ProductDetail
