import debounce from 'lodash/debounce'
import { observable, computed, action, observe } from 'mobx'
import { isTouchDevice, isEdgeOrInternetExplorer } from './deps'

const IS_BROWSER = typeof window === 'object'

/**
 * ApplicationStore class - Stores application state and data.
 */
class ApplicationStore {
  @observable
  previousAbortControllers = {}

  prev = {
    height: 0,
    width: 0,
  }
  @observable
  height = IS_BROWSER ? window.innerHeight : 0
  @observable
  width = IS_BROWSER ? window.innerWidth : 0
  @observable
  availHeight = IS_BROWSER ? window.screen.availHeight : 0
  @observable
  availWidth = IS_BROWSER ? window.screen.availWidth : 0

  @observable
  scroll = {
    vertical: IS_BROWSER ? window.scrollY : 0,
    horizontal: IS_BROWSER ? window.scrollX : 0,
    direction: 'none',
  }

  @observable
  hasError = false

  @observable
  orientation = 'portrait'

  @observable
  IsAppLoading = true

  @observable
  activeFetches = 0

  @observable
  isCallInProgress = false

  // fix for CX121-1779
  @observable
  isAlertLoader = true

  // does not need to be observable or computed - does not change
  isTouchDevice = IS_BROWSER && isTouchDevice()
  isModernBrowser = !isEdgeOrInternetExplorer()
  isOldBrowser = isEdgeOrInternetExplorer()

  observe = observe.bind(undefined, this)

  /**
   * Returns true if the window width is considered supersized.
   */
  @computed
  get isSuperSize() {
    return this.width >= 1200
  }

  /**
   * Returns true if the window width is considered a desktop.
   */
  @computed
  get isDesktop() {
    return this.width > 1024
  }

  /**
   * Returns true if the window width is considered a tablet.
   * Tablet width is between 768px and 1024px.
   */
  @computed
  get isTablet() {
    return this.width >= 768 && this.width <= 1024
  }

  /**
   * Returns true if the window width is considered a tablet or larger.
   */
  @computed
  get isTabletOrLarger() {
    return this.width >= 768
  }

  /**
   * Returns true if the window width is considered a tablet or smaller.
   */
  @computed
  get isTabletOrSmaller() {
    return this.isTablet || this.isPhone
  }

  /**
   * Returns true if the window width is considered a mobile device.
   * A mobile device is defined as anything smaller than a tablet (less than 768px wide).
   */
  @computed
  get isMobile() {
    return this.isPhone
  }

  /**
   * Returns true if the window width is considered a mobile device or larger.
   * A mobile device is defined as anything smaller than a tablet (less than 768px wide).
   * isMobileOrLarger will return true for mobile and tablet sizes.
   */
  @computed
  get isMobileOrLarger() {
    return this.isPhone
  }

  /**
   * Returns true if the window width is considered a phone device.
   * A phone device is defined as anything smaller than a tablet (less than 768px wide).
   */
  @computed
  get isPhone() {
    return IS_BROWSER ? window.innerWidth < 768 : this.width < 768
  }

  /**
   * Returns true if the window width is considered an extra small mobile device.
   * An extra small mobile device is defined as anything smaller than 576px wide.
   */
  @computed
  get isSmallPhone() {
    return this.width < 576
  }

  /**
   * Returns true if the window width is considered a desktop or larger.
   * Desktop is defined as anything larger than 1024px wide.
   */
  @computed
  get isDesktopOrLarger() {
    return this.width > 1024
  }

  /**
   * Returns the device type based on the app state.
   * Checks isDesktop, isTablet, returning the device type string.
   */
  @computed
  get deviceType() {
    return this.isDesktop ? 'desktop' : this.isTablet ? 'tablet' : 'mobile'
  }

  /**
   * Handle window resize event.
   * Checks if on touch device first,
   * logs message, and returns early if so.
   * Otherwise calls updateDimensions() to update app state.
   */
  @action.bound
  handleResize(event) {
    if (this.isTouchDevice) {
      console.info('[state:application] handleResize ... LIAR')
      return
    }

    this.updateDimensions()
  }

  /**
   * Sets the initial dimensions in the application state
   * based on the user agent data received from the server.
   * This allows correct responsive behavior on initial server render.
   */
  setDimensionFromUserAgent(userAgentData) {
    /**
     * *init for ssr*
     * 1) Here we are setting the initial value of screen width
     *    based on the userAgent received in express request
     * 2) Initially, this width will be used for server side rendering
     * 3) This avoids glitches when the application loads in browser
     *    i.e, Glitches are avoided when actual width is set
     */
    if (userAgentData.isTablet) {
      /**
       * if userAgent is tablet,
       * regex tests sets both isTablet and isPhone to 'true'
       * so don't change this order
       */
      this.width = 768
    } else if (userAgentData.isPhone) {
      this.width = 320
    } else {
      this.width = 1024
    }
  }

  /**
   * Generates a responsive image URL based on the provided width, height
   * and format options.
   *
   * @param {Object} object - Object containing image src, width, height
   * and format options
   * @returns {String} The responsive image URL
   */
  getResponsiveImage(object) {
    let width = object.width ? '&w=' + object.width : ''
    let height = object.height ? '&h=' + object.height : ''
    let format =
      object.tearSheetSVG || (object.format && object.format == 'auto')
        ? ''
        : '&fm=jpg'

    if (object.src) {
      return `${object.src}?q=75${format}${width}${height}`
    }
    return require('../../deps/images/placeholder.jpg')
    //  require('../ /deps/images/placeholder.jpg')
  }

  /**
   * Updates the dimensions in the application state
   * based on the current browser window size.
   *
   * This allows the app to respond to window resizes.
   */
  @action
  updateDimensions() {
    // if we trigger this like this, it will always trigger an update
    // since we already debounce, struct is not 100% needed
    this.prev = {
      height: this.height,
      width: this.width,
    }

    this.height = (IS_BROWSER && window.innerHeight) || ''
    this.width = (IS_BROWSER && window.innerWidth) || ''
    this.availHeight = (IS_BROWSER && window.screen.availHeight) || ''
    this.availWidth = (IS_BROWSER && window.screen.availWidth) || ''
    // clientWidth: document.body.clientWidth,
  }

  /**
   * Handles orientation change events by calling the application
   * container's updateDimensions method.
   *
   * This allows the application container to update dimensions and handle
   * responsive behavior when the orientation changes.
   */
  @action.bound
  handleOrientation(event) {
    this.updateDimensions()
  }
}

/**
 * Handles resize events by calling the application container's
 * handleResize method.
 *
 * This allows the application container to update dimensions and handle
 * responsive behavior on window resize.
 */
function handleResize(event) {
  applicationContainer.handleResize(event)
}

/**
 * Handles orientation change events by calling the application container's
 * handleOrientation method.
 *
 * This allows the application container to update dimensions and handle
 * responsive behavior when the orientation changes.
 */
function handleOrientation(event) {
  applicationContainer.handleOrientation(event)
}

/**
 * Subscribes to browser events for handling responsive behavior.
 * Listens for resize and orientation change events based on device type.
 */
function subscribe() {
  if (isTouchDevice() === false) {
    window.addEventListener('resize', debounce(handleResize, 60))
  }
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/Events/orientationchange
   */
  if (isTouchDevice()) {
    //  window.addEventListener('orientationchange', handleOrientation)
    screen?.orientation?.addEventListener('change', handleOrientation)
  }
}

/**
 * Binds browser scroll behavior by subscribing to resize and orientation change events.
 * Conditionally sets the window.application global if on browser.
 */
function bindBrowserScroll() {
  if (IS_BROWSER) {
    subscribe()
    window.application = applicationContainer
  }
}

/**
 * Checks if the browser is running on a mobile device.
 *
 * Uses regex to match against common mobile identifiers in the userAgent string.
 * Returns a boolean indicating if the device is mobile.
 *
 * This is an implementation detail, not part of the exported API.
 */
function checkIsMobile() {
  // https://stackoverflow.com/a/11381730
  // using width is not helping as mobile devices width are uncertain
  let isMobileDevice = false
  if (IS_BROWSER) {
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        isMobileDevice = true
    })(navigator.userAgent || navigator.vendor || window.opera)
  }
  return isMobileDevice
}

/**
 * Gets the device type based on the user agent.
 * Checks against regex patterns for mobile, tablet and desktop.
 * Returns 'mobile', 'tablet' or 'desktop'.
 */
const getDeviceType = () => {
  const ua = navigator.userAgent
  if (IS_BROWSER) {
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }
}

/**
 * Returns the type of browser being used.
 *
 * Checks `navigator.userAgent` and compares against known browser patterns to determine if the browser is "mobile", "tablet", or "desktop".
 *
 * @returns {string} 'mobile', 'tablet', or 'desktop'
 */
const getBrowserType = () => {
  const browserConfig = {
    name: '',
    version: '',
  }

  try {
    let agent = navigator?.userAgent,
      temp,
      matched =
        agent?.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || []
    if (/trident/i.test(matched[1])) {
      temp = /\brv[ :]+(\d+)/g.exec(agent) || []
      return { name: 'IE', version: temp[1] || '' }
    }
    if (matched[1] === 'Chrome') {
      temp = agent?.match(/\bOPR\/(\d+)/)
      if (temp != null) {
        return { name: 'Opera', version: temp[1] }
      }
    }
    if (window?.navigator?.userAgent?.indexOf('Edge') > -1) {
      temp = agent?.match(/\Edge\/(\d+)/)
      if (temp != null) {
        return { name: 'Edge', version: temp[1] }
      }
    }
    if (window?.navigator?.userAgent?.indexOf('Edg') > -1) {
      temp = agent?.match(/\Edg\/(\d+)/)
      if (temp != null) {
        return { name: 'Edge', version: temp[1] }
      }
    }
    matched = matched[2]
      ? [matched[1], matched[2]]
      : [navigator?.appName, navigator?.appVersion, '-?']
    if ((temp = agent?.match(/version\/(\d+)/i)) != null) {
      matched?.splice(1, 1, temp[1])
    }

    const isIos = /(iPhone|iPod|iPad).*AppleWebKit/i.test(agent)
    if (isIos) {
      matched[0] = 'iOS'
      matched[1] = agent
        ?.match(/OS [\d_]+/i)[0]
        ?.substr(3)
        ?.split('_')
        ?.map(n => parseInt(n))?.[0]
    }

    const isAndroid = /(Android).*AppleWebKit/i.test(agent)
    if (isAndroid) {
      matched[0] = 'Android'
      matched[1] = agent.match(/android\s([0-9\.]*)/i)?.[1]
    }

    browserConfig.name = matched[0]
    browserConfig.version = matched[1]
  } catch (e) {
    console.warn('Fn getBrowserType exception:', e)
  }
  return browserConfig
}

const applicationContainer = new ApplicationStore()
export {
  IS_BROWSER,
  ApplicationStore,
  applicationContainer,
  applicationContainer as application,
  bindBrowserScroll,
  checkIsMobile,
  getDeviceType,
  getBrowserType,
}
export default applicationContainer
