import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import { pageNames } from 'src/routes/pathParams'
import {
  i18nTranslate,
  trackErrorInInstana,
  isExpressCheckout,
  getCheckoutGTMPageData,
  trackExpressCheckoutEvents,
  getLocalStorage,
  getLocaleCodeFromUrl,
  trackExpressCheckoutSubmitOrder,
} from 'src/utils'
import {
  cartContainer,
  checkoutContainer,
  storeContainer,
  sessionContainer,
  customerContainer,
} from 'src/models'
import { APPConfig, convertToBoolean } from 'config/appConfig'
import { toastState, overlayState } from 'src/views/components'
import { isPaylaterMessageRequired } from 'src/views/components/PayPal'
import { IS_BROWSER } from 'src/utils/application'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'

class PaypalContainer extends CommonContainer {
  @observable isPayPalSuccess = false // This observable not used anywhere, we can remove it
  @observable isPaypalAccepted = false
  @observable submitOrderEnable = false
  @observable paypalInitiate = false
  @observable paypalClientCreated = false
  @observable selectedShippingMethod = ''
  @observable isInvalidAddress = false
  @observable paypalInstance = null
  paypalOrderId = ''
  isPayPalDefaultPayment = false
  paymentId = ''
  paypalDisplayName = ''

  /**
   * Validates a PayPal payment by calling the validatePaypalPayment endpoint.
   *
   * @param {Object} paypalDetails - Details of the PayPal payment to validate.
   * @returns {Promise} Response from the validatePaypalPayment endpoint.
   */
  validatePaypalPayment = async paypalDetails => {
    const loadParams = {
      endPointName: 'validatePaypalPayment',
      postData: paypalDetails,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  getPriceBreakup = () => {
    const priceBreakup = {
      itemTotal:
        cartContainer?.cartResponse?.value?.priceAfterMarkdown?.toString(),
      shipping:
        cartContainer?.cartResponse?.value?.totalDeliveryCharge?.toString(),
      taxTotal: cartContainer?.cartResponse?.value?.overAllTax?.toString(),
      discount: cartContainer?.cartResponse?.value?.overAllDiscount?.toString(),
    }
    return priceBreakup
  }

  /**
   * Adds a PayPal payment by calling the addPaypalPayment endpoint.
   *
   * @param {Object} paypalDetails - Details of the PayPal payment to add.
   * @returns {Promise} Response from the addPaypalPayment endpoint.
   */
  addPaypalPayment = async paypalDetails => {
    const loadParams = {
      endPointName: 'addPaypalPayment',
      postData: paypalDetails,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Creates a PayPal order for checkout.
   *
   * Retrieves the user's country from their profile.
   * Falls back to the store's country if not found.
   *
   * Sets the PayPal order amount, currency, intent and billing agreement based on cart and store details.
   *
   * Returns a Promise resolving to the created PayPal order.
   */
  createPayPalOrder = paypalCheckoutInstance => {
    let userCountry = ''
    customerContainer?.profileResponse?.accounts?.properties?.forEach(
      element => {
        if (element.attributeId === 'countryCode') {
          userCountry = element.value === 'CA' ? 'Canada' : element.value
        }
      }
    )
    let storeCountry =
      storeContainer.storeIDValue === 'CA'
        ? 'Canada'
        : storeContainer.storeIDValue
    userCountry = userCountry || storeCountry
    let currencyCodeFromStoreResponse =
      storeContainer?.activeStoreResponse?.defaultCurrency || ''
    return paypalCheckoutInstance.createPayment({
      flow: 'checkout', // Required
      amount: Math.abs(
        cartContainer?.cartResponse?.paymentValue?.totalAmountPaid
      ), // Required
      currency: currencyCodeFromStoreResponse || 'USD', // Required

      intent: 'capture', // Must match the intent passed in with loadPayPalSDK
      requestBillingAgreement: !isPaylaterMessageRequired(userCountry || 'US'),
    })
  }

  /**
   * onApprove handles the approval callback from PayPal after a payment is authorized.
   * It calls tokenizePayment to get a payment nonce token from PayPal, then submits the order using that nonce token.
   * If the order submission succeeds, it displays a success toast, redirects to the order confirmation page, and clears cart/loyalty data.
   * If the order submission fails, it calls onError to handle the error case.
   */
  onApprove = async (data, paypalCheckoutInstance) => {
    return paypalCheckoutInstance.tokenizePayment(
      data,
      async function (err, payload) {
        // Submit `payload.nonce` to your server

        overlayState.toggleLoader()
        const submitOrderResponse = await checkoutContainer.submitOrder({
          properties: {
            nonceToken: payload?.nonce || '',
          },
        })

        if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
          trackErrorInInstana({
            errorData: submitOrderResponse,
            errorReport: 'Paypal - Order Placed Successfully',
          })
          await toastState.setToastMessage(
            i18nTranslate(
              'order.submitSuccess',
              'Your order has been submitted.'
            ),
            true
          )
          if (typeof window !== 'undefined') {
            paypalContainer.paypalClientCreated = false
            localStorage.setItem('paymentSuccess', submitOrderResponse.orderId)
            const urlMatch = [
              pageNames.checkout,
              pageNames.expressCheckout,
              pageNames.expressPDP,
            ].find(element => window?.location?.href.includes(element))
            const arr = window?.location?.href.split(urlMatch)
            let isNativeApp = checkIsNativeApp() || false
            if (isNativeApp) {
              window?.location?.replace(
                `${arr[0]}${pageNames.orderConfirmation}?isNativeApp=true`
              )
            } else {
              window?.location?.replace(
                `${arr[0]}${pageNames.orderConfirmation}`
              )
            }
            // The line below is not executing after the page redirection, so the below code is commented.
            // overlayState.toggleLoader()
            // await cartContainer.viewCart()
            // await loyaltyContainer.getLoyaltyPoints()
          } else {
            overlayState.toggleLoader()
            paypalContainer.onError()
          }
        } else {
          overlayState.toggleLoader()
          paypalContainer.onError()
        }
      }
    )
  }

  /**
   * Handles cancel event from PayPal checkout.
   *
   * Logs error and payment cancellation details.
   * Clears payment method from checkout.
   * Resets paypalInitiate flag.
   * Redirects browser back to checkout page.
   */
  onCancel = async (data = {}) => {
    if (IS_BROWSER) {
      trackErrorInInstana({
        errorData: data,
        errorReport: 'Paypal - Payment Cancelled',
      })
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.paypalCancelMessage',
          'Your Paypal payment request was successfully cancelled. Please select a payment option and try again.'
        )
      )
      overlayState.toggleLoader()
      localStorage.setItem('paymentFail', true)
      console.error('PayPal payment cancelled', JSON.stringify(data, 0, 2))
      let timeout = 0
      if (isExpressCheckout()) {
        timeout = 5000
      }
      if (cartContainer?.cartResponse?.payments) {
        await checkoutContainer.deletePayment()
      }
      paypalContainer.paypalInitiate = false
      overlayState.toggleLoader()
      setTimeout(() => {
        window?.location?.replace(`${window?.location?.href}`)
      }, timeout)
    }
  }

  /**
   * Handles PayPal checkout errors.
   *
   * Logs error details.
   * Sets paymentError flag in localStorage.
   * Deletes payment method from checkout.
   * Resets paypalInitiate flag.
   * Redirects browser back to checkout page.
   */
  onError = async (err = {}) => {
    if (IS_BROWSER) {
      trackErrorInInstana({
        errorData: err,
        errorReport: 'Paypal - Payment Error',
      })

      if (err?.customMessage && err?.message) {
        toastState.setToastMessage(err?.message)
      } else {
        toastState.setToastMessage(
          isExpressCheckout()
            ? i18nTranslate(
                'checkout.paymentGeneralError',
                'Sorry, we were unable to process your payment. Please try again.'
              )
            : i18nTranslate(
                'checkout.paypalCancelMessage',
                'Your Paypal payment request was successfully cancelled. Please select a payment option and try again.'
              )
        )
      }
      overlayState.toggleLoader()
      console.error('PayPal error', err)
      localStorage.setItem('paymentError', true)
      let timeout = 0
      if (isExpressCheckout()) {
        timeout = 5000
      }
      if (cartContainer?.cartResponse?.payments) {
        await checkoutContainer.deletePayment()
      }
      paypalContainer.paypalInitiate = false
      paypalContainer.paypalClientCreated = false
      overlayState.toggleLoader()
      setTimeout(() => {
        window?.location?.replace(`${window?.location?.href}`)
      }, timeout)
    }
  }

  createPaypalButton = paypalCheckoutInstance => {
    paypal
      .Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: async () => {
          overlayState.toggleLoader()
          const reviewResponse = await checkoutContainer.reviewOrder()
          if (checkoutContainer.isSuccessResponse(reviewResponse)) {
            overlayState.toggleLoader()
            customerContainer?.isRegisterUser &&
              (await sessionContainer.refreshSession())
            return this.createPayPalOrder(paypalCheckoutInstance)
          } else {
            trackErrorInInstana({
              errorData: reviewResponse?.responseMessage,
              errorReport: 'Paypal - ReviewOrder API Error',
            })
            toastState.setToastMessage(reviewResponse?.responseMessage)
            overlayState.toggleLoader()
            let arr = window?.location?.href?.split('/checkout')
            window?.location?.replace(` ${arr[0]}${pageNames?.viewCart}`)
          }
        },

        onApprove: data => {
          this.onApprove(data, paypalCheckoutInstance)
        },

        onCancel: this.onCancel,
        onError: this.onError,
      })
      .render('#paypal-container')
      .then(() => {
        // The PayPal button will be rendered in an html element with the ID
        // `paypal-button`. This function will be called when the PayPal button
        // is set up and ready to be used
        this.submitOrderEnable = true
        if (!isExpressCheckout()) {
          this.disablePaypalSDKButton()
        }
      })
  }

  /**
   * Loads the PayPal SDK and initializes PayPal checkout.
   *
   * Checks for errors loading the SDK. If errors, logs details, sets paymentError flag,
   * deletes payment method, resets paypalInitiate flag, and redirects to checkout page.
   *
   * On success, loads SDK with provided config and renders PayPal button.
   * Handles approve, cancel, and error events.
   * Disables default PayPal button after render.
   */
  async onPaypalLoadSDK(paypalCheckoutErr, paypalCheckoutInstance) {
    /**
     * @description
     * Stop if there was a problem creating PayPal Checkout.
     * This could happen if there was a network error or if it's incorrectly configured.
     */
    if (
      (paypalCheckoutErr || typeof paypalCheckoutInstance === 'undefined') &&
      IS_BROWSER &&
      typeof window !== 'undefined'
    ) {
      trackErrorInInstana({
        errorData: paypalCheckoutErr,
        errorReport: 'Paypal - Payment Error',
      })
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      )
      overlayState.toggleLoader()
      console.error('Error creating PayPal Checkout:', paypalCheckoutErr)
      localStorage.setItem('paymentError', true)
      if (cartContainer?.cartResponse?.payments) {
        await checkoutContainer.deletePayment()
      }
      paypalContainer.paypalInitiate = false
      overlayState.toggleLoader()
      window?.location?.replace(`${window?.location?.href}`)
      return
    }
    let currencyCodeFromStoreResponse =
      storeContainer?.activeStoreResponse?.defaultCurrency || ''
    if (this.paypalInstance && isExpressCheckout()) {
      this.createPaypalButton(paypalCheckoutInstance)
    } else {
      paypalCheckoutInstance.loadPayPalSDK(
        {
          currency: currencyCodeFromStoreResponse || 'USD',
          intent: 'capture',
          'enable-funding': 'paylater',
        },
        () => {
          this.createPaypalButton(paypalCheckoutInstance)
        }
      )
    }
  }

  /**
   * disablePaypalSDKButton hides the PayPal checkout button and container
   * after order submission to prevent duplicate orders.
   */
  disablePaypalSDKButton = () => {
    if (
      document.querySelectorAll('button.paypal-order-button-color')?.[0]
        ?.disabled
    ) {
      const paypalContainerElem = document.getElementById('paypal-container')
      const paypalElements = paypalContainerElem.children || []
      for (let i = 0; i < paypalElements?.length; i++) {
        if (paypalElements?.[i]?.tagName === 'DIV') {
          paypalElements[i].style.display = 'none'
        }
      }
    }
  }
  /**
   * Creates a PayPal checkout component using the provided Braintree client instance.
   *
   * @param {Object} clientInstance - The Braintree client instance to use for PayPal checkout.
   *
   * Initializes PayPal checkout by calling the Braintree JS SDK's `paypalCheckout.create` method.
   * Provides callback functions to handle the response from `create` call.
   */
  createPaypalCheckout = clientInstance => {
    if (
      typeof braintree !== 'undefined' &&
      typeof clientInstance !== 'undefined'
    )
      if (this.paypalInstance && isExpressCheckout()) {
        this.onPaypalLoadSDK(null, this.paypalInstance)
        return
      }
    // Create a PayPal Checkout component.
    braintree?.paypalCheckout?.create(
      {
        client: clientInstance,
      },
      (paypalCheckoutErr, paypalCheckoutInstance) => {
        this.onPaypalLoadSDK(paypalCheckoutErr, paypalCheckoutInstance)
      }
    )
  }
  /**
   * Creates a PayPal checkout component using the provided Braintree client instance.
   * Handles any errors from client creation before initializing PayPal checkout.
   */
  createPayPalCheckoutComponent = async (clientErr, clientInstance) => {
    /**
     * @description
     * Stop if there was a problem creating the client.
     * This could happen if there is a network error or if the authorization
     * is invalid.
     */
    if (clientErr && IS_BROWSER) {
      trackErrorInInstana({
        errorData: clientErr,
        errorReport: 'Paypal - Payment Error',
      })
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      )
      overlayState.toggleLoader()
      console.error('Error creating client:', clientErr)
      localStorage.setItem('paymentError', true)
      paypalContainer.paypalInitiate = false
      if (cartContainer?.cartResponse?.payments) {
        await checkoutContainer.deletePayment()
      }
      overlayState.toggleLoader()
      window?.location?.replace(`${window?.location?.href}`)
      return
    }

    this.createPaypalCheckout(clientInstance)
  }

  getShippingOptions = currency => {
    return (
      cartContainer?.cartResponse?.deliveryDetails?.methods?.map(method => {
        return {
          label: method.name,
          amount: {
            value: method.cost,
            currency: currency,
          },
          id: method.id,
          type: 'SHIPPING',
          selected: method.isSelected,
        }
      }) || []
    )
  }

  /**
   * Retrieves the allowed country codes based on the user's locale and account type.
   *
   * @returns {string[]} An array of allowed country codes.
   */
  getAllowedCountryCodes() {
    const locale =
      getLocaleCodeFromUrl()?.split('_')?.[1]?.toUpperCase() || 'US'
    const allowedCountryCodes =
      localStorage?.getItem('accountType')?.includes('Retail Customer') ||
      !customerContainer?.isRegisterUser
        ? [locale]
        : ['US', 'CA']
    return allowedCountryCodes
  }

  getNamefromShippingAddress(payload) {
    const recipientName = payload?.shippingAddress?.recipientName
    if (recipientName) {
      const name = recipientName?.split(' ')
      return {
        firstName: name[0] || '',
        lastName: name?.slice(1)?.join(' ') || '',
      }
    }
    return {
      firstName: payload?.firstName || '',
      lastName: payload?.lastName || '',
    }
  }

  /**
   * Retrieves the phone number from the provided payment and profile data.
   *
   * @param {Object} params.paymentData - The payment data object.
   * @param {Object} params.profileDetail - The profile detail object.
   * @param {boolean} [params.enableDummyPhoneForGuest=false] - Flag to enable dummy phone number for guest users.
   * @returns {string} - The retrieved phone number or a dummy phone number for guest users if enabled.
   */
  getPhoneNumber = ({
    paymentData = {},
    profileDetail = {},
    enableDummyPhoneForGuest = false,
  }) => {
    const phone =
      paymentData?.phoneNumber ||
      paymentData?.phone ||
      profileDetail?.phoneNumber
    if (phone) {
      return phone
    }
    if (!customerContainer?.isRegisterUser && enableDummyPhoneForGuest) {
      //  Enable Dummy phone for guest user if enableDummyPhoneForGuest is true
      return '9999999999'
    }
    return ''
  }

  /**
   * Handles the redirection for PayPal payment process - ** EXPRSS CHECKOUT** .
   *
   * @async
   * @function handlePayPalRedirection
   * @param {Error} clientErr - The error object if there was an error creating the PayPal client instance.
   * @param {Object} clientInstance - The PayPal client instance.
   * @returns {Promise<void>}
   *
   * @description
   * This function handles the redirection process for PayPal payments. It checks for errors in creating the PayPal client instance and handles them accordingly. If the client instance is successfully created, it initializes the PayPal SDK and sets up the PayPal buttons for payment. It also handles various PayPal events such as order creation, payment approval, shipping address change, shipping options change, payment cancellation, and errors.
   *
   */
  handlePayPalRedirection = async (clientErr, clientInstance) => {
    if (
      (clientErr || typeof clientInstance === 'undefined') &&
      IS_BROWSER &&
      typeof window !== 'undefined'
    ) {
      trackErrorInInstana({
        errorData: clientErr,
        errorReport: 'Paypal - Payment Error',
      })
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      )
      overlayState.toggleLoader()
      console.error('Error creating PayPal Checkout:', paypalCheckoutErr)
      localStorage.setItem('paymentError', true)
      if (cartContainer?.cartResponse?.payments) {
        await checkoutContainer.deletePayment()
      }
      paypalContainer.paypalInitiate = false
      overlayState.toggleLoader()
      window?.location?.replace(`${window?.location?.href}`)
      return
    }
    const currencyCodeFromStoreResponse =
      storeContainer?.activeStoreResponse?.defaultCurrency || ''
    const allowedCountryCodes = paypalContainer.getAllowedCountryCodes()
    const enableDummyPhoneForGuest =
      convertToBoolean(
        APPConfig?.getAppConfig()?.enableExpressDummyPhonePaypalCheckout
      ) || false
    braintree?.paypalCheckout?.create(
      {
        client: clientInstance,
      },
      (paypalCheckoutErr, paypalCheckoutInstance) => {
        this.paypalInstance = paypalCheckoutInstance
        paypalCheckoutInstance.loadPayPalSDK(
          {
            currency: currencyCodeFromStoreResponse || 'USD',
            intent: 'capture',
            'enable-funding': 'paylater',
          },
          () => {
            const currency = currencyCodeFromStoreResponse || 'USD'
            paypal
              .Buttons({
                fundingSource: paypal.FUNDING.PAYPAL,
                createOrder: async function () {
                  if (cartContainer?.cartResponse?.payments) {
                    await checkoutContainer.deletePayment()
                  }
                  trackExpressCheckoutEvents({
                    link_text: 'paypal',
                    link_url: window?.location?.pathname,
                    session_id: getLocalStorage('sessionId'),
                    ...getCheckoutGTMPageData({
                      pageTitle: 'Express Payment',
                    }),
                  })
                  // when createPayment resolves, it is automatically passed to the PayPal JS SDK
                  const response = await paypalCheckoutInstance.createPayment({
                    flow: 'checkout',
                    amount:
                      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid?.toString(),
                    currency: currency,
                    intent: 'capture',
                    enableShippingAddress: true,
                    shippingOptions:
                      paypalContainer.getShippingOptions(currency),
                  })
                  return response
                },
                onApprove: function (data, actions) {
                  return paypalCheckoutInstance
                    .tokenizePayment(data)
                    .then(async function (payload) {
                      const paymentData = payload.details
                      const profileDetail = customerContainer?.profileResponse

                      const formData = {
                        ...paypalContainer.getNamefromShippingAddress(
                          paymentData
                        ),
                        email: paymentData?.email || '',
                        phone: paypalContainer.getPhoneNumber({
                          paymentData,
                          profileDetail,
                          enableDummyPhoneForGuest,
                        }),
                        addressLine1: paymentData?.shippingAddress?.line1 || '',
                        country:
                          paymentData?.shippingAddress?.countryCode || '',
                        city: paymentData?.shippingAddress?.city || '',
                        state: paymentData?.shippingAddress?.state,
                        zip: paymentData?.shippingAddress?.postalCode,
                      }
                      if (paymentData?.shippingAddress?.line2) {
                        formData['addressLine2'] =
                          paymentData?.shippingAddress?.line2 || ''
                      }
                      if (formData?.countryCode === 'CA') {
                        formData['type'] = 'shipping'
                      }
                      await checkoutContainer.setShippingAddress(formData)
                      const shippingMethod =
                        paypalContainer.selectedShippingMethod ||
                        cartContainer?.cartResponse?.deliveryDetails?.methods?.find(
                          method => method?.isSelected
                        )?.id ||
                        cartContainer?.cartResponse?.deliveryDetails
                          ?.methods?.[0]?.id
                      await checkoutContainer.updateShippingMethod(
                        shippingMethod
                      )
                      await checkoutContainer.setCommunicationPreference(
                        cartContainer?.cartResponse?.deliveryDetails?.address ||
                          {}
                      )
                      const bagValue = Math.abs(
                        cartContainer?.cartResponse?.paymentValue
                          ?.totalAmountUnpaid || ''
                      )
                      const postData = [
                        {
                          name: 'paypal',
                          status: 'active',
                          type: 'PAYPAL',
                          amount: bagValue,
                          isValid: true,
                        },
                      ]

                      await checkoutContainer.addAlternatePayment(postData)
                      const submitOrderResponse =
                        await checkoutContainer.submitOrder({
                          properties: {
                            nonceToken: payload?.nonce || '',
                          },
                        })

                      if (
                        checkoutContainer.isSuccessResponse(submitOrderResponse)
                      ) {
                        trackErrorInInstana({
                          errorData: submitOrderResponse,
                          errorReport: 'Paypal - Order Placed Successfully',
                        })
                        toastState.setToastMessage(
                          i18nTranslate(
                            'order.submitSuccess',
                            'Your order has been submitted.'
                          ),
                          true
                        )

                        if (isExpressCheckout()) {
                          trackExpressCheckoutSubmitOrder({
                            id: checkoutContainer?.orderId || '',
                            gtmData: cartContainer?.cartResponse || {},
                            paymentType: 'paypal',
                          })
                        }

                        if (typeof window !== 'undefined') {
                          paypalContainer.paypalClientCreated = false
                          localStorage.setItem(
                            'paymentSuccess',
                            submitOrderResponse.orderId
                          )
                          const urlMatch = [
                            pageNames.checkout,
                            pageNames.expressCheckout,
                            pageNames.expressPDP,
                          ].find(element =>
                            window?.location?.href.includes(element)
                          )
                          const arr = window?.location?.href.split(urlMatch)
                          let isNativeApp = checkIsNativeApp() || false
                          let url = `${arr[0]}${pageNames.orderConfirmation}`

                          if (isNativeApp) {
                            url += '?isNativeApp=true'
                          }
                          window?.location?.replace(url)
                        } else {
                          paypalContainer.onError()
                        }
                      } else {
                        let error = {}
                        if (submitOrderResponse?.code === 'ECBTE0001') {
                          error = {
                            customMessage: true,
                            message: i18nTranslate(
                              'expressCheckout.paypalPaymentDeclined',
                              "The Card you entered In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment"
                            ),
                          }
                        }
                        if (!customerContainer.isRegisterUser) {
                          await customerContainer.logout(true)
                          await customerContainer.getProfile()
                        }
                        paypalContainer.onError(error)
                      }
                    })
                },
                onShippingAddressChange: async function (data, actions) {
                  const shippingDetails = data.shippingAddress
                  const profileDetail = customerContainer?.profileResponse
                  if (
                    !allowedCountryCodes.includes(
                      shippingDetails?.countryCode?.toUpperCase()
                    )
                  ) {
                    paypalContainer.isInvalidAddress = true
                    return actions.reject(
                      data?.errors?.COUNTRY_ERROR ||
                        "Your order can't be shipped to this country."
                    )
                  }
                  const formData = {
                    firstName:
                      shippingDetails?.givenName ||
                      profileDetail?.firstName ||
                      '',
                    lastName:
                      shippingDetails?.familyName ||
                      profileDetail?.lastName ||
                      '',
                    email: shippingDetails?.email || profileDetail?.email || '',
                    phone:
                      shippingDetails?.phoneNumber ||
                      profileDetail?.phoneNumber ||
                      '',
                    addressLine1: shippingDetails?.city || '',
                    country:
                      shippingDetails?.country_code ||
                      shippingDetails?.countryCode ||
                      '',
                    city: shippingDetails?.city || '',
                    state: shippingDetails?.state || '',
                    zip:
                      shippingDetails?.postal_code ||
                      shippingDetails?.postalCode ||
                      '',
                  }
                  const res = await checkoutContainer.setShippingAddress(
                    formData
                  )
                  if (res?.status === 'failure') {
                    paypalContainer.isInvalidAddress = true
                    return actions.reject(
                      data?.errors?.ADDRESS_ERROR ||
                        "Your order can't be shipped to this address."
                    )
                  }
                  paypalContainer.selectedShippingMethod = ''
                  paypalContainer.isInvalidAddress = false
                  const updatedShippingMethods =
                    paypalContainer.getShippingOptions(currency)
                  return paypalCheckoutInstance.updatePayment({
                    paymentId: data?.paymentId || data?.orderID,
                    amount:
                      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid?.toString(),
                    currency: currency,
                    shippingOptions: updatedShippingMethods,
                    amountBreakdown: paypalContainer.getPriceBreakup(),
                  })
                },
                onShippingOptionsChange: async function (data, actions) {
                  if (paypalContainer.isInvalidAddress) {
                    return actions.reject(
                      "Your order can't be shipped to this country."
                    )
                  }

                  paypalContainer.selectedShippingMethod =
                    data?.selectedShippingOption?.id
                  await checkoutContainer.updateShippingMethod(
                    paypalContainer.selectedShippingMethod
                  )
                  return paypalCheckoutInstance.updatePayment({
                    paymentId: data.paymentId || data?.orderID,
                    amount:
                      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid?.toString(),
                    shippingOptions:
                      paypalContainer.getShippingOptions(currency),
                    taxTotal:
                      cartContainer?.cartResponse?.value?.overAllTax?.toString(),
                    currency: currency,
                    amountBreakdown: paypalContainer.getPriceBreakup(),
                    shippingOptions:
                      paypalContainer.getShippingOptions(currency),
                  })
                },

                onCancel: function (data) {
                  paypalContainer.onCancel(data)
                },

                onError: function (err) {
                  paypalContainer.onError(err)
                },
                // Add other options, e.g. onApproved, onCancel, onError
              })
              .render('#express-paypal-container')
          }
        )
      }
    )
  }
}
const paypalContainer = new PaypalContainer()

export { PaypalContainer, paypalContainer }
export default paypalContainer
