import {
  IS_BROWSER,
  getLocaleCodeFromUrl,
  setLocalStorage,
  getImageFromProperty,
  getLiveEventStatus,
  checkForSkusArrayInfo,
  convertToBoolean,
  getCookie,
  encodeSHA1,
} from 'src/utils'
import TagManager from 'react-gtm-module'
import { storeContainer } from 'src/models/Store'
import { APPConfig, getLocalStorage } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'

/**
 * Adds a user ID to local storage for tracking purposes.
 * Nuskin will use it for their tracking
 *
 * @param {Object} options - Options object
 * @param {string} options.userId - The user ID to add
 */
function addUserIdForTracking(options) {
  const { userId } = options || {}

  if (userId) {
    setLocalStorage('nuskin.account', { user: { id: userId } })
  }
}

/**
 * Sets data layer event with GTM
 *
 * @param {string} event - Name of the event
 * @param {string} name - Name of the cookie
 * @param {string} value - Value of the cookie
 */
function setDataLayer(event = 'TrackCookie', name, value) {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event,
        name,
        value,
        platform: 'equinox',
      },
    })
  } catch (error) {
    console.log('GTM Error', error)
  }
}

/**
 * Gets the currency code based on the country code and locale.
 * Looks up the locale in the countryCode object to find the currency code for that locale.
 * Falls back to empty string if no currency code found.
 */
function getCurrency() {
  const countryCode = storeContainer?.countryConfig
  const locale = getLocaleCodeFromUrl() || ''
  const overallLocales = Object.assign({}, countryCode.US, countryCode.Canada)
  return overallLocales[locale]?.currency || ''
}

/**
 * Tracks a cookie by sending its name and value to the data layer.
 *
 * @param {Object} options - Options object
 * @param {string} options.cookieName - Name of the cookie to track
 * @param {string} options.cookieValue - Value of the cookie to track
 */
function trackCookie(options) {
  const { cookieName, cookieValue } = options || {}

  if (cookieName && cookieValue) {
    setDataLayer(eventName, cookieName, cookieValue)
  }
}

/**
 * Gets the product data formatted for Google Tag Manager from the provided options.
 *
 * @param {Object} options - Options object containing product data
 * @returns {Object} - Product data formatted for Google Tag Manager
 */
function getGTMProducts(options) {
  const { price = '', variant = '', apiResponse } = options || {}
  const product = apiResponse?.[0]?.skus?.[0] || options?.product?.[0] || {}
  const productProperties = product?.skuProperties || product?.properties
  const categoryName = options?.product?.[0]?.properties?.categoryId || ''

  return {
    name:
      productProperties?.name ||
      productProperties?.slug ||
      options?.item?.productName ||
      '',
    id:
      product?.skuId || product?.skus?.[0]?.skuId || options?.item?.skuId || '',
    price: price || product?.price || options?.item?.totalPrice || '',
    // brand: 'Nuskin',
    category:
      categoryName ||
      productProperties?.categoryId ||
      options?.item?.categoryId ||
      '',
    variant: variant || options?.item?.variantLabel || '',
    quantity:
      product?.quantity ||
      options?.product?.[0]?.quantity ||
      options?.item?.quantity ||
      '',
    band: productProperties?.brand || options?.item?.band || '',
  }
}

/**
 * Tracks an enhanced ecommerce cart event with product data.
 *
 * @param {Object} options - Options object
 * @param {string} options.type - Event type
 * @param {string} [options.method=''] - Ecommerce method (e.g. 'add', 'remove')
 * @param {Object} options.products - Product data
 */
function trackEnhancedCart(options = {}) {
  const { type, method = '' } = options
  const products = getGTMProducts(options)
  const gtmData = {
    event: type,
    ecommerce: {
      currencyCode: getCurrency(),
      [method]: {
        products: [{ ...products }],
      },
    },
  }
  trackDataLayer(gtmData)
}

function trackEnhancedCartEvent(options = {}) {
  const { type, method = '', price = '' } = options
  const products = getGTMProducts(options)
  const gtmData = {
    event: type,
    currency: getCurrency(),
    value: price,
    items: [{ ...products }],
  }
  trackDataLayer(gtmData)
}

/**
 * Tracks a Google Tag Manager product click event.
 *
 * @param {Object} options - Options object
 * @param {string} [options.type] - Event type
 * @param {string} [options.action] - Action field for GTM event
 * @param {Object} options.product - Product data
 * @param {boolean} [options.isSpecialProduct=false] - Whether product is a special product
 * @param {string} [options.categoryId] - Product category ID
 */
function trackProductClick(options = {}) {
  const {
    type,
    action,
    product,
    isSpecialProduct = false,
    categoryId,
  } = options

  const sku = findDefaultSku({ sku: product?.sku })

  const products = []
  products.push({
    name: sku?.properties?.name || '',
    id: sku?.identifier,
    price: isSpecialProduct ? product?.prices?.[0]?.price : sku?.price,
    category: isSpecialProduct
      ? categoryId
      : product?.properties?.productCategory,
  })

  const gtmData = {
    event: type || 'productClick',
    ecommerce: {
      click: {
        actionField: action,
        products,
      },
    },
  }
  trackDataLayer(gtmData)
}

function trackProductClickEvent(options = {}) {
  const {
    type,
    product,
    isSpecialProduct = false,
    categoryId,
    promotion,
    promotionClass = '',
    offerId = '',
  } = options
  const sku = findDefaultSku({ sku: product?.sku })

  const productsOfEvent = []
  productsOfEvent.push({
    item_id: sku?.identifier,
    item_name: sku?.properties?.name || '',
    price: isSpecialProduct
      ? product?.prices?.[0]?.price
      : sku?.price || product?.totalValue?.priceAfterDiscount,
    item_category: isSpecialProduct
      ? categoryId
      : product?.properties?.productCategory,
    item_brand: sku?.properties.brand || '',
    index: 0,
    discount: product?.totalValue?.totaldiscount || '',
    quantity: '',
  })
  let gtmData = {}
  if (promotion) {
    gtmData = {
      event: type || '',
      creative_name: '',
      creative_slot: promotionClass,
      promotion_id: offerId,
      promotion_name: '',
      items: productsOfEvent,
    }
  } else {
    gtmData = {
      event: type || '',
      item_list_id: sku?.identifier,
      item_list_name: sku?.properties?.name || '',
      items: productsOfEvent,
    }
  }
  trackDataLayer(gtmData)
}

/**
 * Tracks a Google Tag Manager promotion impression event.
 *
 * @param {Object} options - Options object
 * @param {Object} [options.promotionDetails={}] - Promotion details
 */
function trackPromotionImpression(options = {}) {
  const { promotionDetails = {} } = options
  const gtmData = {
    event: 'promotionImpression',
    ecommerce: {
      promoView: {
        promotions: promotionDetails,
      },
    },
  }
  trackDataLayer(gtmData)
}

/**
 * Transforms the provided products array into a checkout products array.
 *
 * Maps over the products array and constructs a checkout product object for each product.
 * The shape of the object depends on the isFromCustomEvent flag.
 *
 * If true, maps to the enhanced ecommerce checkout object shape.
 * If false, maps to the standard ecommerce checkout object shape.
 *
 * @param {Object[]} products - The products array to transform
 * @param {boolean} isFromCustomEvent - Whether the data is from a custom event
 * @returns {Object[]} The transformed checkout products array
 */
function getCheckoutProduct(
  products = {},
  isFromCustomEvent = false,
  isFromUTTEvent = false
) {
  return products.map((product, idx) => {
    let item = {}
    const name = product?.skus?.[0]?.skuProperties?.name ?? ''
    const id = product?.skus?.[0]?.skuId ?? ''
    const brand = product?.skus?.[0]?.skuProperties?.brand ?? ''
    const category = product?.skus?.[0]?.skuProperties?.productCategory ?? ''
    const price =
      product?.unitValue?.priceAfterDiscount ?? product?.skus?.[0]?.price ?? 0
    const quantity = product?.quantity ?? 0
    const variant = product?.skus?.[0]?.skuProperties?.variantLabel ?? ''
    const discount = product?.unitValue?.discount ?? ''
    const subTotal = price * quantity ?? 0
    if (isFromCustomEvent) {
      item = {
        item_id: id,
        item_name: name,
        coupon: '',
        discount,
        index: idx + 1,
        item_brand: brand,
        item_category: category,
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price,
        quantity,
      }
    } else if (isFromUTTEvent) {
      item = {
        name: name,
        sku: id,
        subTotal: subTotal,
        category: category,
        quantity: quantity,
      }
    } else {
      item = {
        name,
        id,
        price,
        brand,
        category,
        variant,
        quantity,
      }
    }
    return item
  })
}

function trackExpressCheckoutSubmitOrder(props) {
  const { gtmData = {}, id = '', paymentType = '' } = props
  const actionfeild = {
    id: id,
    memberId: gtmData?.userId || '',
    orderType: 'Standard',
    affiliation: 'Online Store',
    revenue: gtmData?.properties?.oneTimeItemsTotal || 0,
    tax: gtmData?.value?.overAllTax || '',
    shipping: gtmData?.value?.cartDeliveryCharge || '',
    coupon: gtmData?.promotionDetails?.codes?.[0]?.code || '',
    action: 'purchase',
  }
  const payLoad = {
    event: 'express-purchase',
    paymentType: paymentType,
    ecommerce: {
      currencyCode: gtmData?.currencyCode || '',
      purchase: {
        actionField: { ...actionfeild },
        products: getCheckoutProduct(gtmData?.items),
      },
    },
  }
  trackGTMEvents(payLoad)
}

/**
 * Transforms the product data from the subscription drawer into an array of item details for tracking purposes.
 *
 * @param {Object} drawerData - The product data from the subscription drawer.
 * @returns {Object[]} The transformed array of item details.
 */
function getSubscriptionDrawerData(drawerData = {}) {
  const itemDetailsArr = []
  Object.values(drawerData)?.map((product, idx) => {
    let item = {}

    const {
      skuInfo = {},
      totalValue = {},
      subscription = {},
      orderProcessedDate = '',
      quantity = '',
      itemId = '',
      skuId = '',
    } = product || {}
    const discount = totalValue?.discount || 0
    const total = totalValue?.grandTotal || ''
    const frequency =
      subscription?.frequencyPeriod + '-' + subscription?.frequencyType

    item = {
      item_id: itemId,
      discount,
      total,
      quantity,
      frequency,
      processing_day: orderProcessedDate.toString(),
    }

    itemDetailsArr.push(item)
  })
  return itemDetailsArr
}

/**
 * Transforms the checkout products data into an array of item details for tracking purposes.
 *
 * @param {Object} checkoutResponse - The checkout response data.
 * @param {Object[]} checkoutResponse.items - The array of checkout items.
 * @returns {Object[]} The transformed array of item details.
 */

function getCheckoutProductV2(checkoutResponse = {}) {
  const products = checkoutResponse?.items || []
  const itemDetailsArr = []
  products.map((product, idx) => {
    let item = {}
    if (product?.isSubscription) {
      const transformedSkusInfo = checkForSkusArrayInfo(product?.skus)
      const { skuInfo = {} } = transformedSkusInfo?.[0] || {}
      const id = skuInfo?.skuId || ''
      const itemId = product?.itemId || ''
      const quantity = product?.quantity || ''
      const discount = product?.totalValue?.discount || 0
      const total = product?.totalValue?.grandTotal || ''
      const frequency =
        product?.subscription?.frequencyPeriod +
        '-' +
        product?.subscription?.frequencyType

      item = {
        item_id: itemId,
        discount,
        total,
        quantity,
        frequency,
        processing_day: '',
      }
      itemDetailsArr.push(item)
    }
  })
  return itemDetailsArr
}

/**
 * Tracks a subscription order event in Google Tag Manager.
 *
 * @param {Object} subscriptionResponse - The subscription order response data.
 * @param {Object} subscriptionResponse.order - The subscription order details.
 * @param {Object[]} subscriptionResponse.selectedSubscriptions - The selected subscription details.
 * @param {string} subscriptionResponse.orderId - The subscription order ID.
 * @param {string} subscriptionResponse.userId - The user ID associated with the subscription order.
 */
function trackSubscriptionOrderNowGTMData(subscriptionResponse = {}) {
  const orderInfo = subscriptionResponse?.order?.value || {}
  const gtmData =
    subscriptionResponse?.selectedSubscriptions?.[0]?.subscriptions || []

  const productsArray = []
  gtmData.forEach(subscription => {
    const itemDetails = subscription?.itemInfo?.[0] || {}
    const transformedSkusInfo = checkForSkusArrayInfo(itemDetails?.skus)
    const { skuInfo = {} } = transformedSkusInfo?.[0] || {}
    const product = {
      name: skuInfo?.name || '',
      id: skuInfo?.id || '',
      price: itemDetails?.price || 0,
      brand: skuInfo?.skuProperties?.brand || '',
      category: skuInfo?.skuProperties?.productCategory || '',
      variant: skuInfo?.skuProperties?.variantLabel || '',
      quantity: itemDetails?.quantity || 0,
    }
    productsArray.push(product)
  })
  let actionField = {
    id: subscriptionResponse?.orderId || '',
    memberId: subscriptionResponse?.userId || '',
    orderType: 'Subscription',
    affiliation: 'Online Store',
    revenue: orderInfo?.grandTotal || 0,
    tax: orderInfo?.overAllTax || 0,
    shipping: orderInfo?.totalDeliveryCharge || 0,
    coupon: '',
    action: 'purchase',
  }

  trackGTMEvents({
    event: 'subscriptionOrder',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: { ...actionField },
        products: productsArray,
      },
    },
  })

  trackGTMEvents({
    event: 'checkout-purchase',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          ...actionField,
        },
        products: productsArray,
      },
    },
  })
}

/**
 * Transforms the product data from the checkout response into an array of item details for the agreement section.
 *
 * @param {Object} products - The products data from the checkout response.
 * @param {boolean} isSubscription - Indicates whether the products are subscriptions.
 * @returns {Object[]} The transformed array of item details.
 */

function getProductsForAgreementSection(products = {}, isSubscription = false) {
  const itemDetailsArr = []
  Object.values(products).map((product, idx) => {
    let item = {}
    if (product?.isSubscription || isSubscription) {
      const transformedSkusInfo = checkForSkusArrayInfo(product?.skus)
      const { skuInfo = {} } = transformedSkusInfo?.[0] || {}
      const name = skuInfo?.skuProperties?.name || ''
      const id = skuInfo?.skuId || ''
      const price = product?.totalValue?.priceAfterDiscount || ''

      item = {
        name,
        id,
        price,
      }
      itemDetailsArr.push(item)
    }
  })
  return itemDetailsArr
}

/**
 * Tracks an enhanced ecommerce checkout event in Google Tag Manager.
 *
 * @param {Object} options - Options object
 * @param {string} options.type - The type of checkout event (checkout, checkout_option)
 * @param {string} options.action - The checkout action (add, remove, purchase, etc)
 * @param {Object[]} options.products - Array of product data objects
 *
 * The function constructs the GTM data layer object with the mapped
 * event, action, currency, and transformed checkout products array.
 */
function trackEnhancedCheckout(options = {}) {
  const { type, action, products } = options
  const checkoutProducts = getCheckoutProduct(products)
  const gtmData = {
    event: type,
    ecommerce: {
      checkout: {
        actionField: action,
        products: checkoutProducts,
      },
    },
  }
  trackDataLayer(gtmData)
}

function trackEnhancedCheckoutEvent(options = {}) {
  const { type, action, products, value = '' } = options

  let gtmData = {}
  const checkoutProducts = products && getCheckoutProduct(products, true)
  if (type === 'store_credits') {
    gtmData = {
      event: type,
      currency: getCurrency(),
      amount: value,
    }
  } else {
    gtmData = {
      event: type,
      currency: getCurrency(),
      value: value,
      items: checkoutProducts,
    }
  }
  trackDataLayer(gtmData)
}

/**
 * Gets the selected shipping method from the cart response.
 *
 * @param {Object} options - Options object
 * @param {Object} options.cartResponse - Cart response object
 * @returns {Object} The selected shipping method object
 */
function getShippingMethod(options = {}) {
  const { cartResponse } = options
  const selectedDeliveryMethod = cartResponse?.deliveryDetails?.methods?.find(
    deliveryMethod => {
      return deliveryMethod?.isSelected == true
    }
  )
  return selectedDeliveryMethod
}

/**
 * Tracks a custom checkout event in Google Tag Manager.
 *
 * @param {Object} options - Options object
 * @param {Object} options.cartResponse - The cart response object
 * @param {string} options.eventType - The type of event to track (shipping or payment)
 *
 * The function checks the eventType and maps it to the appropriate GTM event name.
 * It then constructs the GTM data layer object with the mapped event, currency,
 * and checkout products.
 *
 */
function trackCustomCheckoutEvent(options = {}) {
  const { cartResponse, eventType } = options
  const eventTypes = {
    shipping: 'add_shipping_info',
    payment: 'add_payment_info',
  }

  const event = eventTypes[eventType]

  if (event) {
    const gtmData = {
      event,
      currency: cartResponse?.currencyCode || '',
      value: cartResponse?.value?.grandTotal || '',
      items: getCheckoutProduct(cartResponse?.items || [], true),
    }

    if (eventType == 'shipping') {
      const selectedDeliveryMethod = getShippingMethod({ cartResponse })
      gtmData['shipping_tier'] = selectedDeliveryMethod?.id || ''
    } else if (eventType == 'payment') {
      // Added 1st payment since GTM supports only one
      gtmData['payment_tier'] = cartResponse?.payments?.[0]?.type || ''
    }
    trackDataLayer(gtmData)
  }
}

/**
 * Tracks an enhanced ecommerce product impression event in Google Tag Manager.
 *
 * Constructs a GTM data layer object containing product impression data
 * for each product, including name, id, price, category, variant, list,
 * and position. The data layer event name is 'productImpression'.
 */
function trackEnhancedProductImpression(data = {}) {
  let productsData = []
  data?.product?.forEach((product, index) => {
    const sku = findDefaultSku({ sku: product?.sku })
    const productData = {
      name: sku?.properties?.name || product?.properties?.name || '',
      // in PLP call, for bundles and kits, we will not be receiving SKU data
      id: sku?.identifier || product?.identifier || '',
      // price: sku?.prices?.[0]?.price,
      price:
        sku?.totalValue?.priceAfterDiscount ||
        product?.totalValue?.priceAfterDiscount ||
        sku?.prices?.[0]?.price ||
        product?.prices?.[0]?.price ||
        '',
      // brand: 'Nuskin',
      category: data?.category?.properties?.name,
      variant: '',
      list: 'PLP',
      position:
        (data?.pageableInfo?.page - 1) * data?.pageableInfo?.size + index + 1,
    }
    productsData.push(productData)
  })
  const GTMLayer = {
    event: 'productImpression',
    ecommerce: {
      currencyCode: getCurrency(),
      impressions: productsData,
    },
  }
  trackDataLayer(GTMLayer)
}

function trackEnhancedProductViewEvent(data = {}) {
  const { type = '' } = data
  let productsData = []
  data?.product?.forEach((product, index) => {
    const sku = findDefaultSku({ sku: product?.sku })
    const productData = {
      item_name: sku?.properties?.name || product?.properties?.name || '',
      // in PLP call, for bundles and kits, we will not be receiving SKU data
      item_id: sku?.identifier || product?.identifier || '',
      item_brand: sku?.properties.brand || '',
      price:
        sku?.totalValue?.priceAfterDiscount ||
        product?.totalValue?.priceAfterDiscount ||
        sku?.prices?.[0]?.price ||
        product?.prices?.[0]?.price ||
        '',
      discount: product?.totalValue?.totaldiscount || '',
      category:
        data?.category?.properties?.name ||
        product?.properties?.productCategory ||
        '',
    }
    productsData.push(productData)
  })
  const GTMLayer = {
    event: type || 'view_item_list',
    ecommerce: {
      items: productsData,
    },
  }
  trackDataLayer(GTMLayer)
}

/**
 * Tracks an enhanced ecommerce product detail impression event in Google Tag Manager.
 *
 * Constructs a GTM data layer object containing product detail impression data
 * for the given product, including name, id, price, brand, category.
 * The data layer event name is 'productDetailImpression'.
 */
function trackEnhancedProductDetailImpression(data = {}) {
  const product = data?.product?.[0]
  const replacementProductClick = data?.replacementProductClick || false

  const products = getTrackingProducts({
    product,
    isToTrackBundleItems: false,
    replacementProductClick,
  })

  const GTMLayer = {
    event: 'productDetailImpression',
    ecommerce: {
      detail: {
        actionField: { list: '', action: 'detail' },
        products,
      },
    },
  }
  trackDataLayer(GTMLayer)
}

function trackV2SubscriptionButtonClick(data) {
  trackDataLayer(data)
}
/**
 * Finds the default SKU from a list of SKUs.
 *
 * @param {Object} options - The options object
 * @param {Object[]} options.sku - The list of SKUs to search
 * @returns {Object} The default SKU object
 */
function findDefaultSku(options) {
  const { sku } = options || {}
  return sku?.find(sku => sku?.default == true)
}

/**
 * Gets the SKUs to use for tracking/reporting for a product.
 * For bundles, gets the default SKU for each bundled product if isToTrackBundleItems is true.
 * Otherwise returns the top-level bundle product.
 * For non-bundles, returns the default SKU if one exists.
 *
 * @param {Object} options - The options object
 * @param {Object} options.product - The product
 * @param {Boolean} options.isToTrackBundleItems [isToTrackBundleItems=true] - whether to track bundleItems default value is true
 * @returns {Object[]} The list of tracking products
 */
function getSkus(options) {
  const { product, isToTrackBundleItems = true } = options || {}

  const skus = []
  if (product?.skuKit || product?.type?.toLowerCase() == 'bundle') {
    if (isToTrackBundleItems) {
      product?.skuKit.forEach(skuKit => {
        const defaultSku = findDefaultSku({ sku: skuKit?.sku })
        if (defaultSku) {
          skus.push(defaultSku)
        }
      })
    } else {
      skus.push(product)
    }
  } else {
    const defaultSku = findDefaultSku({ sku: product?.sku })
    if (defaultSku) {
      skus.push(defaultSku)
    }
  }
  return skus
}

/**
 * Gets the product data to use for tracking/reporting.
 * Maps the SKUs to a simplified tracking format.
 *
 * @param {Object} options - The options object
 * @param {Object} options.product - The product
 * @returns {Object[]} The list of tracking products
 */
function getTrackingProducts(options) {
  const { product, replacementProductClick } = options || {}
  const categoryName = product?.category?.[0]?.properties?.name || ''

  const skus = getSkus(options)

  return skus.map(sku => {
    const isproductStatus =
      sku?.properties?.productStatus?.toLowerCase() == 'discontinued'

    return {
      name: sku?.properties?.name || '',
      id: sku?.identifier || '',
      price: (sku?.totalValue?.priceAfterDiscount || sku?.price || '').toFixed(
        2
      ),
      brand: 'Nuskin',
      category: categoryName,
      item_type: replacementProductClick
        ? 'replacement_product'
        : isproductStatus
        ? sku?.properties?.productStatus?.toLowerCase()
        : '',
    }
  })
}

/**
 * Tracks search result events.
 *
 * If there are search results, sends a search event with the provided options.
 * Uses the searchType to determine event details.
 *
 * If no results, calls trackNoSearchResult() instead.
 *
 * @param {Object} options - The tracking options
 * @param {string} options.searchType - The type of search
 * @param {Object[]} options.products - The matching products
 * @param {boolean} options.isPlainSearch - True if a regular search
 */
function trackSearchResult(options = {}) {
  const { searchType, products, isPlainSearch } = options

  if (products || searchType == 'fullSearch') {
    if (isPlainSearch) {
      const gtmData = {
        searchType,
      }
      trackSearchEvent({ ...options, gtmData })
    }
  } else {
    trackNoSearchResult(options)
  }
}

function trackSearchResultEvent(options = {}) {
  const { searchType, products, isPlainSearch, suggestionList = {} } = options
  if (products || searchType == 'fullSearch') {
    if (isPlainSearch) {
      let selected = {}
      if (products.length == 1) {
        let trackingItem = products[0]
        selected = {
          item_id: trackingItem?.identifier,
          item_name: trackingItem?.properties?.name || '',
          price: trackingItem?.prices?.[0]?.price || '',
          pv: trackingItem?.priceFacets?.PV || '',
          cv: trackingItem?.priceFacets?.CV || '',
        }
      }

      const gtmData = {
        type: searchType,
        suggesstions: suggestionList,
        selected,
      }
      trackSearchEvent({ ...options, gtmData, isEvent: true })
    }
  } else {
    trackNoSearchResultEvent(options)
  }
}

/**
 * Tracks a no search result event.
 *
 * When no search results are found, sends a searchNoResults event
 * with the provided options.
 *
 * @param {Object} options - The tracking options
 * @param {string} options.searchTerm - The search term used
 */
function trackNoSearchResult(options = {}) {
  const { searchTerm } = options
  const gtmData = {
    event: 'searchNoResults',
    searchType: 'searchNoResults',
    typedQuery: searchTerm,
    query: searchTerm,
  }

  trackDataLayer(gtmData)
}

function trackNoSearchResultEvent(options = {}) {
  const { searchTerm } = options
  const gtmData = {
    event: 'search',
    type: 'no_result',
    typedQuery: searchTerm,
  }
  trackDataLayer(gtmData)
}
/**
 * Tracks a recent search event.
 *
 * When a user searches using a recent search term,
 * sends a searchRequested event with the provided options.
 *
 * @param {Object} options - The tracking options
 * @param {string} options.searchTerm - The recent search term used
 */
function trackRecentSearch(options = {}) {
  const { searchTerm } = options

  const gtmData = {
    searchType: 'recentSearch',
  }
  trackSearchEvent({ ...options, gtmData })
}

function trackRecentSearchEvent(options = {}) {
  const { searchTerm, recentSearchList } = options

  const gtmData = {
    type: 'recent_search',
    selected: {},
    suggestions: recentSearchList,
  }

  const enableLiveEvent = getLiveEventStatus()
  const { isEvent = true } = options || {}
  if (enableLiveEvent === 'true' && isEvent) {
    gtmData.event = 'search'
  } else {
    gtmData.event = 'searchRequested'
  }
  trackDataLayer(gtmData)
}
/**
 * Tracks a predictive search event.
 *
 * When a user searches and gets predictive search results,
 * sends a search event with predictiveSearch searchType
 * and additional product data in gtmData.
 *
 * @param {Object} options - The tracking options
 * @param {string} options.typedQuery - The text typed by the user
 * @param {string} options.searchTerm - The actual search term used
 * @param {Object} options.product - The product result object
 */
function trackPredictiveSearch(options) {
  const { typedQuery, searchTerm, product } = options || {}
  const gtmData = {
    searchType: 'predictiveSearch',
    item: {},
  }

  let trackingItem = product

  if (product?.sku) {
    trackingItem = product?.sku?.find(sku => sku?.default == true)
  }

  if (product) {
    const imageUrl = getImageFromProperty(trackingItem?.properties) || ''
    gtmData.item = {
      img: imageUrl,
      itemName: trackingItem?.properties?.name || '',
      sku: trackingItem?.identifier,
      price: trackingItem?.prices?.[0]?.price || '',
      pv: trackingItem?.priceFacets?.PV || '',
      cv: trackingItem?.priceFacets?.CV || '',
      isExclusive: trackingItem?.properties?.isExclusive || false,
      title: trackingItem?.properties?.name,
      fullImage: imageUrl,
    }
  }

  trackSearchEvent({ ...options, gtmData })
}

function trackPredictiveSearchEvent(options) {
  const { typedQuery, searchTerm, product, suggestionProductList } =
    options || {}
  const gtmData = {
    type: 'predictive_search',
    selected: {},
    suggestions: [],
  }
  let trackingItem = product

  if (product?.sku) {
    trackingItem = product?.sku?.find(sku => sku?.default == true)
  }

  if (product) {
    const imageUrl = getImageFromProperty(trackingItem?.properties) || ''
    gtmData.selected = {
      item_id: trackingItem?.identifier,
      item_name: trackingItem?.properties?.name || '',
      price: trackingItem?.prices?.[0]?.price || '',
      pv: trackingItem?.priceFacets?.PV || '',
      cv: trackingItem?.priceFacets?.CV || '',
    }
    gtmData.suggestions = suggestionProductList
  }

  trackSearchEvent({ ...options, gtmData, isEvent: true })
}

/**
 * Tracks a search suggestion event.
 *
 * When a user gets search suggestions, sends a search event
 * with suggestion searchType and search result data in gtmData.
 *
 * @param {Object} options - The tracking options
 * @param {string} options.searchTerm - The search term used
 * @param {Object[]} options.searchResult - The suggestion search results
 */
function trackSearchSuggestions(options) {
  const { searchTerm, searchResult } = options || {}
  const gtmData = {
    searchType: 'suggestion',
    searchMode: 'custom',
    result: searchResult,
  }
  trackSearchEvent({ ...options, gtmData })
}

/**
 * Tracks a search event.
 *
 * Sends a search event to the data layer
 * with search query data from the options parameter.
 *
 * @param {Object} options - The tracking options
 */
function trackSearchEvent(options) {
  const enableLiveEvent = getLiveEventStatus()
  const {
    typedQuery,
    searchTerm,
    searchResult,
    gtmData = {},
    isEvent = false,
  } = options || {}

  gtmData.query = searchTerm || ''
  gtmData.typedQuery = typedQuery || searchTerm || ''
  if (enableLiveEvent === 'true' && isEvent) {
    gtmData.event = 'search'
  } else {
    gtmData.event = 'searchRequested'
  }
  trackDataLayer(gtmData)
}

/**
 * Tracks a product add to cart event.
 *
 * Sends add to cart event data to the data layer
 * when a product is added to the cart on the PDP.
 *
 * @param {Object[]} options - The product data
 */
function trackPDPAddToCart(options) {
  const locale = getLocaleCodeFromUrl()
  let userId = ''
  try {
    userId = JSON.parse(localStorage.getItem('nuskin.account') || '{}')?.user
      ?.id
  } catch (e) {}

  const gtmData = {
    event: options?.event || 'nuskin-shop-add-to-cart',
    sku: options?.[0]?.skus?.[0]?.skuId,
    //  to do for bundle, existing issue
    qty: options?.[0]?.quantity,
    maxQty: 99,
    cntryCd: locale.split('_')[1],
    language: locale.split('_')[0],
    ...(!options?.isExpressCheckout && {
      userId,
      isAdr: false,
      isPitchCart: false,
    }),
    referrer: options?.referrer || 'productPage',
    domain: IS_BROWSER ? document.domain : '',
    country: locale.split('_')[1],
  }
  if (options?.price) {
    gtmData['price'] = options?.price
  }
  if (options?.isExpressCheckout) {
    gtmData['item_name'] = options?.[0]?.properties?.slug || ''
    gtmData['item_brand'] = 'Nuskin'
    gtmData['item_id'] = options?.[0]?.itemId || ''
    gtmData['link_text'] = 'buy now'
    gtmData['link_url'] = window?.location?.pathname
    gtmData['session_id'] = getLocalStorage('sessionId') || ''
  }
  trackDataLayer(gtmData)
}

/**
 * Tracks a quick view add to cart event.
 *
 * Sends add to cart event data to the data layer
 * when a product is added to the cart from the quick view modal.
 *
 * @param {Object[]} options - The product data
 */
function trackQuickViewAddToCart(options) {
  const locale = getLocaleCodeFromUrl()
  let userId = ''
  try {
    userId = JSON.parse(localStorage.getItem('nuskin.account') || '{}')?.user
      ?.id
  } catch (e) {}

  const gtmData = {
    event: 'nuskin-shop-add-to-cart',
    sku: options?.[0]?.skus?.[0]?.skuId,
    product: options?.[0]?.skus?.[0],
    qty: options?.[0]?.quantity,
    userId,
    cntryCd: locale.split('_')[1],
    adr: false,
    referrer: 'productCard',
    domain: IS_BROWSER ? document.domain : '',
  }
  trackDataLayer(gtmData)
}

/**
 * Tracks adding a product to a subscription.
 *
 * Sends add to cart event data to the data layer when a
 * product is added to a subscription.
 *
 * @param {Object} options - The options object
 * @param {Object} options.product - The product object
 * @param {string} options.product.skuId - The product SKU id
 * @param {number} options.quantity - The quantity added
 */
function trackAddToSubscription(options = {}) {
  const gtmData = {
    event: 'nuskin-shop-add-to-cart',
    sku: options?.product?.skuId,
    qty: options?.quantity,
    adr: true,
    product: options?.product,
    referrer: 'productCard',
  }
  trackDataLayer(gtmData)
}

/**
 * @description
 * This function is used to track the user events in the My Profile section.
 * It sends the event to Google Tag Manager with the event type, category, and event trigger.
 * The function takes an object as an argument with the following properties:
 * @param { String } - The event type.
 * @param { String } - The category of the event.
 * @param { String } - The name of the event.
 * @param { Object } - The preferences of the event.
 */
function trackMyProfileEvents(options = {}) {
  const {
    type = '',
    categoryName = '',
    eventName = '',
    preferences = {},
  } = options
  const gtmData = {
    event: type,
    category: categoryName,
    eventType: eventName,
    eventTrigger: preferences,
  }
  trackDataLayer(gtmData)
}

/**
 * Sends event data to the Google Tag Manager data layer.
 *
 * @param {Object} options - Event data to send to the data layer.
 */
function trackGTMEvents(option = {}) {
  trackDataLayer(option)
}

function trackBuyItAgainEvent(options = {}) {
  const { type = '', items = [], order_id = '' } = options
  const gtmData = {
    event: type,
    currency: getCurrency(),
    transaction_id: order_id,
    items: items,
  }
  trackDataLayer(gtmData)
}

/**
 * Tracks an account upgrade event.
 *
 * @param {Object} options - Event data to include:
 * @param {string} options.event - The event name to track
 * @param {Object} options - Additional event data
 */
function trackAccountUpgrade(options = {}) {
  const gtmData = {
    event: 'nuskin-account-conversion',
    ...options,
  }
  trackDataLayer(gtmData)
}

/**
 * Tracking filter selection
 * @date 2023/9/1 - 17:59:09
 *
 * @param { Object } - options
 * @param { String } - options.filterKey - Filter identifier
 * @param { Object } - options.filterValue - Selected filter object
 * @param { Object } - options.faceFilter - All the available filter object
 */
function trackFilter(options = {}) {
  const { filterKey, filterValue, facetFilter } = options

  // On selection it will be still on false. The value will set true after invoking the API
  if (filterValue.selected == false) {
    const selectedFilter = facetFilter.filter(filter => filter.key == filterKey)
    const type = selectedFilter?.[0]?.name
    if (type) {
      const gtmData = {
        event: 'filterSearch',
        type,
        value: filterValue?.name,
      }
      trackDataLayer(gtmData)
    }
  }
}

/**
 * @description - Adds items to the Data Layer array within the global scope. Note: Assumes presence of a variable named ‘window’ that contains a reference to the Global Window Object. Additionally assumes existence of a member property on said window object labeled ‘dataLayer’ that represents the Array instance used by the GTM container to capture and process Data Layer information.
 * @param {Object} options
 * @param {string} options.event - A unique name identifying the specific activity occurring on the site. Typically corresponds directly to one of the pre-defined triggers defined within the GTM UI.
 * @param {string} options.name - Arbitrary additional details pertaining to the triggered event
 * @param {string} options.value - Numeric quantities associated with the given event such as monetary amounts, number of items added to cart, etc. May also contain more structured data if desired but keep in mind potential size constraints placed on the event
 * @param {string} [options.platform='equinox'] - Identifies the particular platform through which the event occurred. Useful for filtering or reporting purposes later on once raw data has been collected. Defaults to the literal string ‘equinox’ indicating this remains
 */
function trackDataLayer(options = {}) {
  options.platform = 'equinox'
  if (IS_BROWSER) {
    TagManager.dataLayer({ dataLayer: { ...options } })
  }
}

/**
 * Tracks signup events.
 *
 * @param {Object} options
 * @param {string} [options.eventName='Signup'] - Name of the event
 * @param {string} options.eventType - Type of the event
 */
function trackSignUpEvents(options) {
  const { eventName = 'Signup', eventType } = options || {}
  const gtmData = {
    event: eventName,
    type: eventType,
  }
  trackDataLayer(gtmData)
}

function trackOrderConfirmationEvent(options = {}) {
  const { type, transactionId, price, product } = options
  const gtmData = {
    event: type,
    transaction_id: transactionId,
    currency: getCurrency(),
    value: price,
    items: product,
  }
  trackDataLayer(gtmData)
}

async function trackUTTConversion(options) {
  const enableImpactTracking = convertToBoolean(
    APPConfig.getAppConfig()?.enableImpactTracking
  )

  const {
    orderId,
    customerId,
    customProfileId,
    customerEmail,
    currencyCode,
    orderPromoCode,
    orderDiscount,
    items,
    customerStatus,
  } = options || {}
  const sha1CustomerEmail = await encodeSHA1(customerEmail)
  if (enableImpactTracking) {
    console.log('trackUTTConversion')
    if (typeof ire != 'undefined') {
      console.log('ire found and tracking', options)
      ire(
        'trackConversion',
        49210,
        {
          orderId: orderId,
          customProfileId: customProfileId,
          customerId: customerId,
          customerEmail: sha1CustomerEmail,
          customerStatus: customerStatus,
          currencyCode: currencyCode,
          orderPromoCode: orderPromoCode,
          orderDiscount: orderDiscount,
          items: items,
        },
        {
          verifySiteDefinitionMatch: true,
        }
      )
    }
  }
}

async function trackImpactIdentify(customerContainer) {
  if (typeof ire != 'undefined') {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse
    // when customer call is made for first time, data is found inside customer node
    const customerId = customerResponse?.accounts?.referenceAttribute || ''
    const email = customerResponse?.email || ''
    const encryptedEmail = email ? await encodeSHA1(email) : ''
    const customProfileId = customerResponse?.id || ''
    // 5. Pass customer id as Nu Skinid
    // 6. Pass Equinox id as profile id
    const postData = {
      customerId,
      customerEmail: encryptedEmail,
      customProfileId,
    }
    if (IS_BROWSER) {
      const isLoginCallbackPage = window.location.pathname.includes(
        pageNames.loginCallback
      )
      if (isLoginCallbackPage) {
        if (customerId != '') {
          // if current page is login callback page,
          // track only for register user
          console.log(
            'identify data isLoginCallbackPage - registered user',
            // postData,
            window.location.pathname
          )
          ire('identify', postData)
        } else {
          // don't track for guest user profile call
          // just adding console statement
          console.log(
            'identify data isLoginCallbackPage - guest tracking blocked, will be tracked only for register user',
            // postData,
            window.location.pathname
          )
        }
      } else {
        console.log(
          'identify data (normal page - not login callback page)',
          // postData,
          window.location.pathname
        )
        ire('identify', postData)
      }
    }
  }
}

function startTracking(customerContainer) {
  // const enableImpactTracking = convertToBoolean(
  //   APPConfig.getAppConfig().enableImpactTracking
  // )
  const enableClarityTracking = convertToBoolean(
    APPConfig.getAppConfig().enableClarityTracking
  )
  // if (enableImpactTracking) {
  //   trackImpactIdentify(customerContainer)
  // }
  if (enableClarityTracking) {
    if (typeof clarity !== 'undefined') {
      // https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent#step-2
      // to enable clarity tracking
      clarity('consent')
    }
  }
}
function getConsentCookie() {
  const consentCookieKey =
    APPConfig.getAppConfig().consentCookieKey || 'cmapi_cookie_privacy'
  const consentCookieValue = getCookie(consentCookieKey) || ''
  return consentCookieValue
}

function isConsentAcceptedForTracking() {
  const consentCookieValue = getConsentCookie()
  return consentCookieValue.includes('1,2')
  // cookie value will be "1,2",
  // when mandatory and functional cookie consent are accepted
}

function checkConsentCookieAndEnableTracking(customerContainer) {
  try {
    const consentCookieValue = getConsentCookie()
    if (consentCookieValue) {
      // if cookie value is empty, keep looping
      // if cookie value is found, check for consent acceptance
      if (isConsentAcceptedForTracking()) {
        // when functional and tracking cookie consent accepted,
        startTracking(customerContainer)
      } else {
        // cookie consent rejected
        const enableClarityTracking = convertToBoolean(
          APPConfig.getAppConfig().enableClarityTracking
        )
        if (enableClarityTracking) {
          if (typeof consent !== 'undefined') {
            // https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent#step-2
            // to disable clarity tracking and delete cookies
            clarity('consent', false)
          }
        }
      }
    } else {
      setTimeout(() => {
        checkConsentCookieAndEnableTracking(customerContainer)
      }, 300)
    }
  } catch (error) {
    console.log('error while checking cookies for consent', error)
  }
}

function trackCheckoutGiftReceipt(options) {
  const { eventName = 'select_gift', eventType, message } = options || {}
  const gtmData = {
    event: eventName,
    eventType: eventType,
    message: message,
  }
  trackDataLayer(gtmData)
}

function trackCheckoutCouponCodeEvents(options) {
  const { eventName = 'checkout_coupon', coupon } = options || {}
  const gtmData = {
    event: eventName,
    coupon: coupon,
  }
  trackDataLayer(gtmData)
}

function trackExpressCheckoutEvents(options) {
  const { eventName = 'express_checkout' } = options || {}
  const gtmData = {
    event: eventName,
    ...options,
  }
  trackDataLayer(gtmData)
}

export {
  addUserIdForTracking,
  trackCookie,
  trackEnhancedCart,
  trackPromotionImpression,
  trackProductClick,
  trackEnhancedCheckout,
  trackEnhancedProductImpression,
  trackEnhancedProductDetailImpression,
  trackSearchResult,
  trackRecentSearch,
  trackPredictiveSearch,
  trackMyProfileEvents,
  trackPDPAddToCart,
  trackQuickViewAddToCart,
  trackAddToSubscription,
  trackGTMEvents,
  trackAccountUpgrade,
  trackSearchSuggestions,
  getCheckoutProduct,
  getCheckoutProductV2,
  trackSubscriptionOrderNowGTMData,
  getSubscriptionDrawerData,
  getProductsForAgreementSection,
  trackFilter,
  trackCustomCheckoutEvent,
  trackSignUpEvents,
  trackV2SubscriptionButtonClick,
  trackPredictiveSearchEvent,
  trackRecentSearchEvent,
  trackProductClickEvent,
  trackEnhancedCartEvent,
  trackEnhancedCheckoutEvent,
  trackSearchResultEvent,
  trackEnhancedProductViewEvent,
  trackOrderConfirmationEvent,
  trackBuyItAgainEvent,
  trackUTTConversion,
  trackImpactIdentify,
  checkConsentCookieAndEnableTracking,
  trackCheckoutCouponCodeEvents,
  trackCheckoutGiftReceipt,
  trackExpressCheckoutEvents,
  trackExpressCheckoutSubmitOrder,
}
