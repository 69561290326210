import { i18nTranslate } from 'src/utils'
import {
  APPConfig,
  deleteFromLocalStorage,
  getLocalStorage,
  IS_BROWSER,
  localDomain,
} from 'config/appConfig'
import { customerContainer, sessionContainer } from 'src/models'
import {
  setLocalStorage,
  checkMysiteOrPersonalOffer,
  getShoppingContext,
  getLiveEventStatus,
} from 'src/utils'
import { toastState } from 'src/views/components'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'

/**
 * Initiates sign in flow with redirect to Okta authorization endpoint.
 *
 * @param {string} isUrlReturn - The URL to redirect to after successful sign in.
 */
export async function signInwithRedirect(isUrlReturn) {
  const response = await sessionContainer.oktaAuthorize()
  let oktaAuthorizeUrl = response?.oktaAuthorizeUrl || ''

  let oktaDomain = oktaAuthorizeUrl.substring(
    0,
    oktaAuthorizeUrl.indexOf('.com')
  )
  oktaDomain = oktaDomain.concat('.com')
  localStorage.setItem('oktaDomain', oktaDomain)
  const codeVerifier = response?.codeVerifier || ''
  localStorage.setItem('codeVerifier', codeVerifier)

  // Added support to use the current domain
  const oktaUrl = new URL(oktaAuthorizeUrl)
  let redirectUri = oktaUrl?.searchParams?.get('redirect_uri')
  const curLocaleFromUrl = getLocaleCodeFromUrl()?.toLowerCase()?.split('_')
  const locale = curLocaleFromUrl[1] + '/' + curLocaleFromUrl[0]

  if (document.domain != localDomain) {
    if (redirectUri?.includes('https://')) {
      redirectUri = redirectUri.replace('https://', '')
      const slashPos = redirectUri?.indexOf('/')
      redirectUri = redirectUri.substring(slashPos)
    }

    // Appending locale when redirect URI missing locale in URL
    if (!redirectUri?.includes(locale)) {
      redirectUri = `/${locale}${redirectUri}`
    }

    oktaUrl?.searchParams?.set(
      'redirect_uri',
      `https://${document.domain}${redirectUri}`
    )

    oktaAuthorizeUrl = oktaUrl.toString()
  }

  if (response.statusCode === 200 && typeof window !== 'undefined') {
    // const shoppingContext = getLocalStorage('shoppingContext') || {}
    const shoppingContext = getShoppingContext()
    if (Object.keys(shoppingContext).length > 0) {
      /**
       * @info
       * remove mysiteCheckout from local storage if it's already there
       */
      if (getLocalStorage('mysiteCheckout')) {
        localStorage.removeItem('mysiteCheckout')
      }
      if (isUrlReturn === 'mysiteCheckout') {
        setLocalStorage('mysiteCheckout', 'mysiteCheckout')
      }
    }
    if (isUrlReturn === true) {
      return oktaAuthorizeUrl
    } else {
      window.location.href = oktaAuthorizeUrl
    }
  } else {
    deleteFromLocalStorage('isExpressPDPLogin')
    toastState.setToastMessage(
      i18nTranslate('signIn.authorizeFailMessage', 'Authorize call failure'),
      false
    )
    setTimeout(() => {
      window.location.href = window.location.origin
    }, 3000)
  }
}

/**
 * Publishes a 'nuskin-account-logout' event when the user logs out.
 * This allows other parts of the app to respond to the logout event.
 *
 * Checks if it is a native mobile app first, and only publishes the event
 * if it is a web app.
 */
export async function publishLogoutEvent() {
  const enableLiveEvent = getLiveEventStatus()
  // Fix CX121-4987
  const isNativeMobileApp =
    window?.isNativeApp == true || window?.isVeraApp == true
  if (!isNativeMobileApp) {
    const { events } = await import('@nuskin/ns-util')
    if (events) {
      events?.publish?.('nuskin-account-logout')
      if (enableLiveEvent === 'true') {
        events?.publish?.('signout')
      }
    }
  }
}

/**
 * Navigates to a new page after a timeout.
 *
 * @param {Object} options - Options for navigation
 * @param {number} [options.timeout=2000] - Timeout before navigating in ms
 * @param {string} options.url - URL to navigate to
 */
async function pageNavigate(options) {
  const { timeout = 2000, url } = options || {}

  setTimeout(async () => {
    await publishLogoutEvent()
    if (url) {
      window.location.href = url
    }
  }, timeout)
}

/**
 * Logs the user out and navigates to the homepage.
 * Gets the current locale from the URL.
 * Checks if it's a mySite/personal offer logout, and if so
 * redirects to the mySite logout redirect URI.
 * Otherwise redirects to the homepage with the current locale.
 */
export async function customerLogout() {
  await customerContainer.logout()

  // const curLocale = getLocalStorage('defaultLocale').replace(/"/g, '')
  const curLocale = getLocaleCodeFromUrl({
    at: 'pathParam',
    isReverseType: true,
  })
  const [language, country] = curLocale.includes('_')
    ? curLocale.split('_')
    : curLocale.split('-')
  if (typeof window !== 'undefined') {
    // condition to check mysite / personal offer logout - CX121-4221
    // const shoppingContext = getLocalStorage('shoppingContext') || {}
    const contextValue = checkMysiteOrPersonalOffer()
    if (contextValue == 'personal_offer' || contextValue == 'storefront') {
      const mySiteLogoutRedirectURI =
        APPConfig?.getAppConfig()?.MYSITE_REDIRECT || ''
      let mySiteDomain
      let urlDetails = sessionStorage.getItem('storefront') || '{}'
      if (urlDetails) {
        urlDetails = JSON.parse(urlDetails) || {}
      }
      if (Object.keys(urlDetails?.storefront).length > 0) {
        mySiteDomain = Object.keys(urlDetails?.storefront)
        mySiteDomain = mySiteDomain?.[0] || ''
      }
      pageNavigate({
        url: mySiteLogoutRedirectURI.replace('<%domain%>', mySiteDomain),
        timeout: 3000,
      })
    } else {
      pageNavigate({
        url: `${window.location.origin}/${country}/${language}`.toLowerCase(),
      })
    }
  }
}

/**
 * Logs the user out and navigates to the homepage.
 * Gets the current locale from the URL.
 * Checks if it's a mySite/personal offer logout, and if so
 * redirects to the mySite logout redirect URI.
 * Otherwise redirects to the homepage with the current locale.
 */
export async function signOutWithRedirect() {
  const { postOktaLogoutRedirectURI, defaultLocale } = APPConfig.getAppConfig()

  const curLocaleFromUrl = getLocaleCodeFromUrl({
    isReverseType: true,
  })

  const curLocale = defaultLocale || curLocaleFromUrl

  /**
   * @todo
   * we can remove the includes condition once the locale ('-'), defaultLocale ('_')
   * code updated in proper standards
   */
  const [language, country] = curLocale.includes('_')
    ? curLocale.split('_')
    : curLocale.split('-')

  if (IS_BROWSER && localStorage.getItem('idToken')) {
    if (checkMysiteOrPersonalOffer()) {
      // Redirecting to Okta page only for mysite or personal offer as Okta logout API is not working in these scenarios
      const oktaLogoutUri = APPConfig.getAppConfig().oktaLogoutUri
      const composedLogoutURL = `${oktaLogoutUri}id_token_hint=${localStorage?.getItem(
        'idToken'
      )}&post_logout_redirect_uri=https://${
        document?.domain
      }${postOktaLogoutRedirectURI.replace(
        '<%LOCALE_CODE%>',
        `${country}/${language}`.toLowerCase()
      )}`
      window.location.replace(composedLogoutURL)
    } else {
      await customerLogout()
    }
  } else {
    /**
     * @info
     * below logout functionaliy will happen when the user login from CSR site
     * added timeout inorder to display toast message before redirection
     */
    await customerContainer.csrlogout()

    await publishLogoutEvent()

    if (IS_BROWSER) {
      setTimeout(() => {
        window.location.href =
          `${window.location.origin}/${country}/${language}`.toLowerCase()
      }, 2000)
    }
  }
}
