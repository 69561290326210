import React, { lazy, Suspense } from 'react'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { Row, Image, Modal } from 'react-bootstrap'
import { enableOutfits } from 'config/appConfig'
import { application, checkIsMobile, getLiveEventStatus } from 'src/utils'
import { ProductBadge } from '../ProductBadge'
import { ProductAddToOutfit } from '../ProductAddToOutfit'
import { ProductImageCarousel } from './ProductImageCarousel'
import { ProductFavorites } from '../ProductFavorites'
import { ProductPromotion } from 'src/views/components/ProductComponents'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { quickViewState } from 'src/views/components'
import { productDeps } from 'src/deps'
import { APPConfig, IS_BROWSER } from 'config/appConfig'
import { promotionContainer } from 'src/models'
import isEmpty from 'lodash/isEmpty'
import './styles.scss'

// Dynamic import of the below component.
const LazyProductImage = lazy(() =>
  import(/* webpackChunkName: "ProductImageWithZoom" */ './ProductImage')
)

@observer
class ProductImageWithFavorites extends React.Component {
  static defaultProps = {
    iconsRenderingMode: 'vertical',
    imageRenderType: 'carousel',
    openInModal: false,
  }
  currentIndex = 0
  scrollRef = ''
  @observable isModalVisible = false
  enableLiveEvent = getLiveEventStatus()

  @action
  toggleModal = (props = {}) => {
    const { visibility } = props

    this.isModalVisible =
      visibility !== undefined ? visibility : !this.isModalVisible
  }

  handleImageClick = index => {
    this.currentIndex = index
    this.toggleModal({
      visibility: true,
    })
  }

  renderContent = () => {
    if (application.isTabletOrLarger) {
      return (
        <Row
          className="flex-column"
          noGutters
          ref={element => (this.scrollRef = element)}>
          {this.props?.product?.imageListWithVideo?.map((data, index) => (
            <Image src={data?.source} index={index} fluid key={index} />
          ))}
        </Row>
      )
    } else {
      const data =
        this.props?.product?.imageListWithVideo?.[this.currentIndex] || {}
      return (
        <Row noGutters className="overflow-auto">
          <Image src={data?.source} />
        </Row>
      )
    }
  }

  renderFavorites = () => {
    const { enableFavorites = true, product } = this.props
    let isReplacementItem = false
    if (product?.type === 'bundle' || product?.type === 'kit') {
      isReplacementItem = productDeps.getIsReplacementItem(product)
    } else {
      const selectedSku = productDeps.getUserSelectedSku(product)
      isReplacementItem = productDeps.getIsReplacementItem(selectedSku)
    }
    let enableFavoritesIcon = enableFavorites && !isReplacementItem

    return enableFavoritesIcon && <ProductFavorites {...this.props?.product} />
  }

  renderOutfits = () => {
    const { isFromQuickView = false, showOutfitIcon = true } = this.props
    return (
      enableOutfits &&
      showOutfitIcon &&
      !isFromQuickView && <ProductAddToOutfit {...this.props} />
    )
  }

  render() {
    const {
      iconsRenderingMode = defaultProps.iconsRenderingMode,
      badgeRendertype = 'diagonal',
      imageRenderType = 'list',
      enableZoom = false,
      openInModal = defaultProps.openInModal,
      product = {},
      isFromQuickView = false,
    } = this.props || {}
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    const { imageListWithVideo = [] } = product

    let pdtResponse = []
    if (
      enableNewPDPStyle &&
      (this.props.isFromQuickView || this.props.isFromPDP)
    ) {
      let promotion = []
      if (
        productState.currentlySelectedPriceOption == 'one-time-price-button'
      ) {
        if (isEmpty(promotionContainer.subscriptionV2PromoResponse)) {
          promotion = this.props.product.promotion
        } else {
          promotion = promotionContainer.subscriptionV2PromoResponse.promotion
        }
      } else {
        if (isEmpty(promotionContainer.subscriptionV2PromoResponse)) {
          promotion = this.props.product.subscriptionPromotion
        } else {
          promotion =
            promotionContainer.subscriptionV2PromoResponse.subscriptionPromotion
        }
      }
      pdtResponse = { promotion: promotion || [] }
    } else {
      if (product?.isBundle) {
        pdtResponse = productState?.bundlePromotionResponse
      } else if (isFromQuickView) {
        if (isEmpty(quickViewState.pdpPromotionResponse)) {
          // on initial load
          // this case is invalid
          // to be removed
          // check how browseResponse is set in getQuickViewPromotion function
          pdtResponse = product
        } else {
          // this is executed always
          pdtResponse = quickViewState.pdpPromotionResponse
        }
      } else {
        // PDP - normal product
        if (isEmpty(productState.pdpPromotionResponse)) {
          pdtResponse = product
        } else {
          pdtResponse = productState.pdpPromotionResponse
        }
      }
    }
    return (
      <div className="product-wrap mb-2">
        <Modal
          centered={true}
          size="lg"
          className={`image-modal bg-white`}
          {...(application.isTabletOrLarger && {
            onEnter: () => {
              this.scrollRef.children?.[this.currentIndex]?.scrollIntoView()
            },
          })}
          onHide={() => this.toggleModal(false)}
          dialogClassName="mw-100 m-0"
          show={this.isModalVisible}>
          <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
          <Modal.Body>{this.renderContent()}</Modal.Body>
        </Modal>
        <ProductBadge
          productBadge={this.props?.product?.productBadge || ''}
          badgeRendertype={badgeRendertype}
        />
        {(imageRenderType === 'carousel' && application.isTabletOrLarger) ||
        checkIsMobile ? (
          <ProductImageCarousel
            product={product}
            isExpressPDP={this.props?.isExpressPDP}
          />
        ) : (
          <div
            className={`product-image-wrap ${
              imageRenderType === 'grid'
                ? 'grid-image-wrap'
                : 'd-flex flex-md-column'
            }`}>
            {IS_BROWSER && (
              <Suspense fallback="">
                {imageListWithVideo?.map((data, index) => (
                  <LazyProductImage
                    source={data.source}
                    type={data.type}
                    index={index}
                    key={index}
                    enableZoom={enableZoom}
                    openInModal={openInModal}
                    handleImageClick={this.handleImageClick}
                  />
                ))}
              </Suspense>
            )}
          </div>
        )}
        <Row
          noGutters
          className={`${
            iconsRenderingMode === 'vertical'
              ? 'flex-row-reverse'
              : 'flex-column'
          } position-absolute product-icons-wrapper pt-2`}>
          {!enableNewPDPStyle &&
            this.enableLiveEvent !== 'true' &&
            this.renderFavorites()}
          {this.renderOutfits()}
        </Row>
        {/* @##pdp_promotion_message */}
        {/* for both quickview and pdp */}
        {/* for both normal and bundle */}
        <ProductPromotion isToShowPromoOnImage {...pdtResponse} />
      </div>
    )
  }
}

export { ProductImageWithFavorites }
