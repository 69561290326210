import React, { Suspense, useState, useEffect } from 'react'
import ReactDOM, { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSSR, withTranslation } from 'react-i18next'
import { loadableReady } from '@loadable/component'
import loadable from '@loadable/component'
import TagManager from 'react-gtm-module'
import {
  isToEnableSSR,
  APPConfig,
  isProduction,
  convertToBoolean,
} from 'config/appConfig'
import i18n, { initI18nForClient } from 'config/i18nConfig'
import { setAppConfigByStore } from 'config/storeController'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { Routes } from 'src/routes'
import {
  ApiContent,
  IS_BROWSER,
  getLocalePathParam,
  setMysiteOrPersonalOffer,
  setFraudDetectionCbkStatus,
  setUTMInfo,
  trackErrorInInstana,
} from 'src/utils'
import { detectIncognito } from 'detectincognitojs'
import './styles.scss'

import { initDatadog } from './datadog'

let timeoutId

const NsUtil = loadable.lib(() => import('@nuskin/ns-util'), {
  ssr: false,
})

// We don't need to redirect to region selector page.
// function RedirectToRegion() {
//   const redirectTo = () => {
//     if (IS_BROWSER) {
//       const storeId = getLocalStorage('storeId')
//       // const appLocale = getLocalStorage('defaultLocale')
//       const appLocale = getLocaleCodeFromUrl({
//         url: location.href,
//         at: 'pathParam',
//         defaultLocale: 'en_US',
//         isReverseType: true,
//       })

//       if (
//         (!storeId || !appLocale) &&
//         !window.location.href.includes('selectregion')
//       ) {
//         window.location.href = `${window.location.origin}/selectregion`
//       }
//     }
//   }

//   redirectTo()
//   return <></>
// }

function loadClientTranslation() {
  if (i18n.hasLoadedNamespace('csr-resource')) {
    document.removeEventListener('click', loadClientTranslation)
    return
  }

  i18n.loadNamespaces('csr-resource')
}

function FraudDetectionScript() {
  const acceritfySrcUrl = APPConfig?.getAppConfig()?.acceritfySrcUrl

  const [scroll, setScroll] = useState(false)

  const handleScroll = e => {
    setScroll(true)
  }
  if (window && window.addEventListener) {
    window.addEventListener('scroll', handleScroll)
  }

  useEffect(() => {
    if (scroll) {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (scroll) {
    loadClientTranslation()
    return (
      <>
        <Helmet>
          <script
            src={acceritfySrcUrl}
            id="bcn"
            dvc="a"
            dvct="500"
            // async="true"
            defer
            type="text/javascript"
            onLoad={initFraudDetectionCbk()}></script>
        </Helmet>
      </>
    )
  }
  return <></>
}

function showCookieConsent() {
  try {
    // Using document.getElementById, since the DOM is coming from 'getHtml'
    document.getElementById('consent_blackbar').style.display = 'block'
    // document.getElementById('teconsent').style.display = 'block'
  } catch (e) {
    console.log(e)
  }
}

function WrapperComponent() {
  if (isLoadingInIframe() && IS_BROWSER) {
    console.warn('Site loading in iframe')
    top.location = self.location
  }

  setUTMInfo()
  const tagManagerArgs = {
    gtmId: APPConfig?.getAppConfig()?.gtmKey,
  }
  TagManager.initialize(tagManagerArgs)

  // const isUserConsent = getLocalStorage('hasUserConsent')
  let baseName = getLocalePathParam()
  let isNativeAppQueryParamString =
    new URL(location?.href)?.searchParams?.get('isNativeApp') || false
  if (isNativeAppQueryParamString && IS_BROWSER) {
    window.isNativeApp = true
  }

  const isNativeMobileApp =
    window?.isNativeApp == true || window?.isVeraApp == true

  if (!isNativeMobileApp) {
    loadGDPRScript()
    showCookieConsent()
  }
  const isCookieEnabled = navigator.cookieEnabled

  document.addEventListener('click', loadClientTranslation)

  return (
    <>
      {/* <RedirectToRegion /> */}
      <FraudDetectionScript />
      {!isNativeMobileApp && isCookieEnabled && (
        <NsUtil>
          {({ init: nsUtilInit }) => {
            nsUtilInit()
            return ''
          }}
        </NsUtil>
      )}
      <BrowserRouter basename={baseName}>
        <Routes />
      </BrowserRouter>
    </>
  )
}

function ServerApp() {
  let providerValue = {}
  if (typeof __initialData !== 'undefined') {
    providerValue = __initialData
  }

  useSSR(window.initialI18nStore, window.initialLanguage)

  // __initialData = ''
  // https://github.com/facebook/react/issues/10879 - Expected error in dev mode

  return (
    <ApiContent.Provider value={providerValue}>
      <Suspense fallback="">
        <WrapperComponent />
      </Suspense>
    </ApiContent.Provider>
  )
}

/**
 * Initializes the fraud detection callback.
 *
 * Sets a timeout to wait 700ms, then if in the browser calls the Beacon
 * setSubmissionCallback method to set the callback that will receive the
 * fraud detection status.
 */
function initFraudDetectionCbk() {
  setTimeout(() => {
    if (IS_BROWSER) {
      window._bcn?.dvc?.setSubmissionCallback(status => {
        setFraudDetectionCbkStatus(status)
      })
    }
  }, 700)
  return null
}

function renderClientApp() {
  const ClientApp = withTranslation()(WrapperComponent)

  ReactDOM.render(
    <Suspense fallback="">
      <ClientApp />
    </Suspense>,
    document.getElementById('app-root')
  )
}

/**
 * Store front initiates here
 * @date 2023/8/17 - 14:16:01
 */
function initApp() {
  if (isProduction) {
    initDatadog()
  }
  if (isToEnableSSR) {
    /**
     * @note
     * runs via `yarn devServer` | `yarn runProductionSSR`
     */
    loadableReady(() => {
      initI18nForClient(true)
      hydrate(<ServerApp />, document.getElementById('app-root'))
    })
  } else {
    /**
     * @note
     * runs via `yarn dev`
     * CSR => Client Side Rendering
     */
    if (IS_BROWSER) {
      const appLocale = getLocaleCodeFromUrl({
        url: location.href,
        at: 'pathParam',
        defaultLocale: 'en_US',
        isReverseType: true,
      })

      document.querySelector('body').setAttribute('id', appLocale)
      setAppConfigByStore({
        host: document.domain,
        pageUrl: window.location.href,
        localeProp: 'defaultLocale',
        cbk: () => {
          /**
           * @note
           * rendering the app only after
           * - locale chosen
           * - store settings collected
           */
          initI18nForClient(false)

          renderClientApp()
        },
      })
    }
  }
  setMysiteOrPersonalOffer()
}

initApp()

function isLoadingInIframe() {
  if (IS_BROWSER) {
    const siteUsingIframe = document.getElementsByTagName('iframe').length
    const totalIframeLength = window.parent.frames.length

    if (totalIframeLength !== siteUsingIframe) {
      return true
    }
  }
  return false
}

// Fix for Chunk error issue if we redeploy a new build
// Window event listener is not triggering so used console error
const origin = console.error
console.error = (error, message) => {
  // Older chunk is failed due to new version
  if (/chunk [\d]+ failed/.test(error.message)) {
    cacheBurst({ error })
  } else {
    origin(error, message)
  }
}

function cacheBurst(options) {
  const { error } = options || {}
  trackErrorInInstana({ errorReport: 'Chunk failed to load', errorData: error })
  const cacheBurst = new Date().getTime()
  const url = new URL(window.location.href)
  url.searchParams.set('t', cacheBurst)
  window.location.href = url.href
}

async function loadGDPRScript() {
  const result = await detectIncognito()
  const { gdpr } = APPConfig?.getAppConfig()
  const isToEnableGDPR = convertToBoolean(gdpr.enableIncognito) || false

  if (isToEnableGDPR) {
    if (result.isPrivate) {
      checkTrustArcLoad()
    }
  }
}

function checkTrustArcLoad() {
  const { gdpr } = APPConfig?.getAppConfig()
  const timeoutDuration = parseInt(gdpr?.timeoutDuration || 6000, 10)

  const checkInterval = setInterval(() => {
    if (window?.truste && window?.truste?.eu) {
      clearInterval(checkInterval)
      clearTimeout(timeoutId)
      return true
    }
  }, 100)

  timeoutId = setTimeout(() => {
    clearInterval(checkInterval)
    if (!window?.truste) {
      loadFallbackConsent()
    }
    return false
  }, timeoutDuration)
}

function loadFallbackConsent() {
  console.log('loadFallbackConsent')
  const { gdpr } = APPConfig?.getAppConfig()
  const locale = getLocaleCodeFromUrl()
  let country = locale
  country = country?.split?.('_')?.[1]?.toLowerCase()

  const scriptUrl = gdpr?.scriptUrl
    ?.replace('{COUNTRY}', country)
    ?.replace('{LOCALE}', locale)

  const script = document.createElement('script')
  script.src = scriptUrl
  document.body.appendChild(script)
}

export { i18n }
