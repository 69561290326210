import React, { lazy, Suspense } from 'react'
import { Tab, Tabs, Button, Row, Col, Accordion, Card } from 'react-bootstrap'
import { IoIosAdd, IoIosRemove } from 'react-icons/io'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate, getContentFromCS } from 'src/utils'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import {
  isToEnableItemLevelShipping,
  IS_BROWSER,
  APPConfig,
  convertToBoolean,
} from 'config/appConfig'
import { stringifyAddress } from 'src/deps'
import { isB2BUser, isB2B2C, isExpressCheckout, scrollState } from 'src/utils'
import { overlayState, toastState } from 'src/views/components'
import { MultipleShipping } from './MultipleShipping'
import { ShippingAddressList } from './ShippingAddressComponents'
import {
  checkoutContainer,
  cartContainer,
  customerContainer,
  storeContainer,
} from 'src/models'
import { signInwithRedirect } from 'src/utils/signInUtils'
import { pageNames } from 'src/routes/pathParams'
import { countriesWithoutPostalCodes } from 'src/static/geography'
import ExpressSectionHeader from '../ExpressSectionHeader'
import { setLocalStorage } from 'src/utils'
import './styles.scss'

@observer
class ShippingAddressListWithForm extends React.Component {
  @observable currentlySelectedAddress = ''
  @observable isToDisableContinueBtn = false
  @observable disableRadioBtn = false
  loadContentFromCS = true
  @observable showNewForm = false
  @observable showList = false
  signInType =
    APPConfig.getConfigFromJSON()?.defaultProps?.signInType || 'default'
  checkout = 'mysiteCheckout'
  enableEQFastFollow = convertToBoolean(
    APPConfig?.getAppConfig()?.enableEQFastFollow || false
  )
  loadContentFromCS = this.enableEQFastFollow || false
  AddressForm = ''
  currentMarket = storeContainer?.defaultShippingRegion
    ? 'Address' + storeContainer?.defaultShippingRegion
    : 'AddressUS'
  AddressForm = lazy(() =>
    import(
      'src/views/addresses/' + this.currentMarket + '/' + this.currentMarket
    )
  )

  constructor(props) {
    super(props)
    this.state = {
      contentData: {},
    }
    if (
      props.addressToSelectOrPrefill &&
      props.addressToSelectOrPrefill.addressLine1
    ) {
      this.currentlySelectedAddress = props.addressToSelectOrPrefill
    } else if (props.addressList && props.addressList.length) {
      this.currentlySelectedAddress = props.addressList.find(
        accountAddress => accountAddress.isDefault
      )
      if (
        isExpressCheckout() &&
        customerContainer?.isRegisterUser &&
        !this.currentlySelectedAddress
      ) {
        this.showList = true
      }
    }
    const defaultAddress = props.addressList.find(address => address.isDefault)
    if (defaultAddress) {
      // Put 'Default address' to index 0
      props.addressList.splice(
        props.addressList.findIndex(address => address.isDefault),
        1
      )
      props.addressList.splice(0, 0, defaultAddress)
    }
    let index = props.addressList.findIndex(obj => {
      return (
        obj.addressId ===
        Number(this.currentlySelectedAddress?.profileAddressId)
      )
    })
    if (index > 1) {
      props.addressList.splice(1, 0, props.addressList[index])
      if (index + 1 < props.addressList.length)
        props.addressList.splice(index + 1, 1)
    }
  }

  async componentDidMount() {
    const isAddressIncomplete =
      cartContainer.cartResponse?.deliveryDetails?.address?.firstName ===
        null ||
      cartContainer.cartResponse?.deliveryDetails?.address?.firstName === ''
    if (this.loadContentFromCS) {
      await this.getContentFromCSCheck()
    }
    if (
      isExpressCheckout() &&
      !customerContainer?.isRegisterUser &&
      Object.keys({ ...this.props?.addressToSelectOrPrefill })?.length > 0
    ) {
      if (isAddressIncomplete) {
        this.showList = false
      } else {
        this.showList = true
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.shippingMethodAdded !== prevProps?.shippingMethodAdded) {
      if (
        Object.keys({ ...this.props?.addressToSelectOrPrefill })?.length > 0 &&
        this.props?.shippingMethodAdded &&
        isExpressCheckout()
      ) {
        this.toggleShowList()
        scrollState.smoothScrollToModalElement(
          `#qa-checkout-shipping-accordion`
        )
        if (customerContainer?.isRegisterUser) {
          this.showList = false
          this.showNewForm = false
          this.initializeData(this.props)
        }
      }
    }
  }
  handleOverlayClose() {
    const interval = setInterval(() => {
      if (
        IS_BROWSER &&
        !!window?.performance &&
        window?.performance?.navigation?.type === 2
      ) {
        clearInterval(interval)
        overlayState?.hideLoader()
      }
    }, 500)
  }

  componentWillUnmount() {
    if (isExpressCheckout()) {
      overlayState?.hideLoader()
    }
  }

  initializeData = props => {
    if (
      props?.addressToSelectOrPrefill &&
      props?.addressToSelectOrPrefill?.addressLine1
    ) {
      this.currentlySelectedAddress = props?.addressToSelectOrPrefill
    } else if (props?.addressList && props?.addressList.length) {
      this.currentlySelectedAddress = props?.addressList.find(
        accountAddress => accountAddress?.isDefault
      )
    }
    const defaultAddress = props?.addressList?.find(
      address => address?.isDefault
    )
    if (defaultAddress) {
      // Put 'Default address' to index 0
      props?.addressList?.splice(
        props?.addressList?.findIndex(address => address.isDefault),
        1
      )
      props?.addressList?.splice(0, 0, defaultAddress)
    }
    let index = props?.addressList?.findIndex(obj => {
      return (
        obj?.addressId ===
        Number(this.currentlySelectedAddress?.profileAddressId)
      )
    })
    if (index > 1) {
      props?.addressList?.splice(1, 0, props?.addressList?.[index])
      if (index + 1 < props?.addressList?.length)
        props?.addressList?.splice(index + 1, 1)
    }
  }

  getContentFromCSCheck = async () => {
    const contentData = await getContentFromCS()
    let promise = new Promise((resolve, reject) => {
      if (Object.keys(contentData).length > 0) {
        resolve(contentData)
      } else {
        reject(Error('Promise rejected'))
      }
    })

    promise.then(
      result => {
        this.setState({ contentData: result })
      },
      function (error) {
        this.setState({ contentData: error })
      }
    )
  }
  @action
  toggleShowList = () => {
    this.showList = !this.showList
    this.initializeData(this.props)
    if (!this.showList) {
      this.showNewForm = false
    }
  }
  @action
  toggleShowListForm = () => {
    this.showNewForm = !this.showNewForm
  }

  handleSelect = accountAddress => {
    this.currentlySelectedAddress = accountAddress
    isFunction(this.props.handleAddressSelect) &&
      this.props.handleAddressSelect(accountAddress)
    if (isExpressCheckout()) {
      this.props?.handleAddressContinue(this.currentlySelectedAddress)
    }
  }

  newFormSelectionChecker = () => {
    // this flow will be executed only if addressList.length > 0
    let selectedAddressIndex = -1
    if (this.props.addressToSelectOrPrefill === '') {
      // if there is no address is in cart
      // but has addressList
      // select addresslist tab
      return false
    } else {
      // has address in cart
      // check whether cart address(addressToSelectOrPrefill) is in `addressList`
      selectedAddressIndex = this.props.addressList.findIndex(
        accountAddress =>
          stringifyAddress(accountAddress) ===
          stringifyAddress(this.props.addressToSelectOrPrefill)
      )
      // if selectedIndex === -1
      // then address is not available in addressList of myaccount
      // so choose new address form
      return selectedAddressIndex === -1
    }
  }

  renderContinueButton = () => {
    const { handleAddressContinue, addressToSelectOrPrefill } = this.props
    return (
      <Button
        disabled={
          this.props.isToDisableShippingContinueBtn ||
          this.isToDisableContinueBtn ||
          false
        }
        className="rounded-0 shipping-continue px-5 py-2 shipping-button btn-bg-blue"
        data-testid="qa-ship-continue"
        onClick={() =>
          handleAddressContinue(
            this.currentlySelectedAddress || addressToSelectOrPrefill
          )
        }>
        {i18nTranslate('checkout.continue', 'Continue')}
      </Button>
    )
  }

  renderSaveChangesButton = () => {
    const { handleAddressContinue, addressToSelectOrPrefill } = this.props
    return (
      <Button
        disabled={this.props.isToDisableShippingContinueBtn || false}
        className="shipping-save-changes px-5 py-2 shipping-button"
        data-testid="qa-ship-continue"
        onClick={() =>
          handleAddressContinue(
            this.currentlySelectedAddress || addressToSelectOrPrefill
          )
        }>
        {i18nTranslate('checkout.saveChanges', 'Save Changes')}
      </Button>
    )
  }

  handleAddressSubmit = async formData => {
    const formData1 = {
      ...formData,
      // country: storeContainer.storeIDValue,
    }
    const { handleModal, addressList = [] } = this.props
    const { state, country, stateList, ...remainingFormData } = formData1
    const stateValue = this.isStateFieldDropDown === true ? stateList : state
    const transformedFormData = {
      state: stateValue,
      country: country?.value ? country?.value : country,
      ...(countriesWithoutPostalCodes && {
        zip: formData1.postalCode || '000',
      }),
      ...remainingFormData,
    }
    const newAddressData = {
      ...transformedFormData,
      default: true,
      street1: transformedFormData.addressLine1,
      street2: transformedFormData.addressLine2,
      zipCode: transformedFormData.zip || transformedFormData.postalCode,
    }

    if (!isArray(addressList) || addressList.length < 1000) {
      handleModal(transformedFormData, newAddressData)
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'checkoutAddress.limitExceeded',
          'Maximum number of addresses that can be setup is already reached'
        ),
        false
      )
    }
  }
  renderTabs = props => {
    const { index, AddressForm } = props
    const savedAddress = i18nTranslate('checkout.savedAddress', 'Saved Address')
    const expressSavedAddress = i18nTranslate(
      'expressCheckout.addShippingAddress',
      'Ship To'
    )
    const newAddress = i18nTranslate(
      'checkout.addNewAddress',
      'Add New Address'
    )
    const shouldSelectNewAddressFormTab = this.newFormSelectionChecker()
    const {
      isFromEditSubscription = false,
      addressToSelectOrPrefill = {},
      addressList = [],
      isFromCheckout = false,
    } = this.props
    const shippingButton = isFromEditSubscription
      ? this.renderSaveChangesButton()
      : this.renderContinueButton()

    if (isExpressCheckout()) {
      return (
        <>
          <ExpressSectionHeader
            title={expressSavedAddress}
            isOpen={this.showList}
            onClick={() => this.toggleShowList()}
          />

          {!this.showList && this.currentlySelectedAddress && (
            <div className="d-flex flex-column express-header-body">
              <span className="fname-normal-shiping-express">
                {`${this.currentlySelectedAddress?.firstName} ${
                  this.currentlySelectedAddress?.firstName?.toUpperCase() !==
                  this.currentlySelectedAddress?.lastName?.toUpperCase()
                    ? this.currentlySelectedAddress?.lastName
                    : ''
                }`}
              </span>
              <span className="d-flex flex-row express-address-payment mt-2 flex-wrap">
                <span
                  className="express-shiping-address-data"
                  data-testid="qa-street">
                  {this.currentlySelectedAddress?.addressLine1 || ''}
                </span>
                <span
                  className="express-shiping-address-data"
                  data-testid="qa-street">
                  {this.currentlySelectedAddress?.addressLine2 === ''
                    ? this.currentlySelectedAddress?.addressLine2 || ''
                    : `${this.currentlySelectedAddress?.addressLine2}, ` || ''}
                  , {this.currentlySelectedAddress?.city || ''},{' '}
                  {this.currentlySelectedAddress?.state || ''},{' '}
                  {this.currentlySelectedAddress?.zip && (
                    <span
                      className="express-shiping-address-data"
                      data-testid="qa-zip">
                      {this.currentlySelectedAddress?.zip || ''},{' '}
                    </span>
                  )}
                </span>
                <span
                  className="express-shiping-address-data"
                  data-testid="qa-country">
                  {this.currentlySelectedAddress?.country || ''}
                </span>
              </span>
            </div>
          )}
          {!this.showList && !this.currentlySelectedAddress && (
            <Suspense fallback="">
              <div className="border-bottom">
                <AddressForm
                  shippingAddresses={addressList}
                  onSubmit={this.handleAddressSubmit}
                  renderSubmitButton={this.renderAddAddressContinueButton}
                  type="checkout"
                  isAddressNotAdded={this.props?.isAddressNotAdded}
                />
              </div>
            </Suspense>
          )}
          {this.showList && (
            <ShippingAddressList
              handleContinueBtnState={this.handleContinueBtnState}
              currentlySelectedAddress={this.currentlySelectedAddress}
              isAddressChecked={this.props.isAddressChecked}
              addressList={addressList}
              index={index}
              handleSelect={this.handleSelect}
              disableRadioBtn={this.props.isToDisableShippingContinueBtn}
              validationCallback={this.props.validationCallback}
              isAddressNotAdded={this.props?.isAddressNotAdded}
              setIsAddressNotAdded={this.props?.setIsAddressNotAdded}
            />
          )}
          {(!isB2BUser() || isB2B2C()) && IS_BROWSER && (
            <>
              {this.showList && (
                <h6
                  onClick={() => this.toggleShowListForm()}
                  className="mb-2 mt-2 d-flex use-different-btn align-items-center">
                  <>
                    <IoIosAdd />{' '}
                    <span>
                      {i18nTranslate(
                        'expressCheckout.expressUseDifferentAddress',
                        'Use a different address'
                      )}
                    </span>
                  </>
                </h6>
              )}
              {this.showNewForm && (
                <Suspense fallback="">
                  <div className="border-bottom">
                    <AddressForm
                      shippingAddresses={addressList}
                      onSubmit={this.handleAddressSubmit}
                      renderSubmitButton={this.renderAddAddressContinueButton}
                      type="checkout"
                      isAddressNotAdded={this.props?.isAddressNotAdded}
                    />
                  </div>
                </Suspense>
              )}
            </>
          )}
        </>
      )
    }
    return (
      <Tabs
        className="my-3"
        defaultActiveKey={
          shouldSelectNewAddressFormTab ? 'newAddress' : 'savedAddress'
        }
        id="">
        <Tab
          eventKey="savedAddress"
          title={savedAddress}
          data-testid="qa-address-container">
          <ShippingAddressList
            handleContinueBtnState={this.handleContinueBtnState}
            currentlySelectedAddress={this.currentlySelectedAddress}
            isAddressChecked={this.props.isAddressChecked}
            addressList={addressList}
            index={index}
            handleSelect={this.handleSelect}
            disableRadioBtn={this.props.isToDisableShippingContinueBtn}
            validationCallback={this.props.validationCallback}
          />
          <Row className="justify-content-end pt-3" noGutters>
            {shippingButton}
          </Row>
        </Tab>
        {(!isB2BUser() || isB2B2C()) && IS_BROWSER && (
          <Tab eventKey="newAddress" title={newAddress}>
            <Suspense fallback="">
              {isExpressCheckout() && (
                <ExpressSectionHeader
                  title={expressSavedAddress}
                  disableAccordion={true}
                />
              )}
              <AddressForm
                //Commented below code for CX16-9938 for StoppingAutofill in AddNewAddress
                // addressInfo={
                //   shouldSelectNewAddressFormTab
                //     ? addressToSelectOrPrefill || ''
                //     : ''
                // }
                shippingAddresses={addressList}
                onSubmit={this.handleAddressSubmit}
                renderSubmitButton={this.renderAddAddressContinueButton}
                type={'checkout'}
              />
            </Suspense>
          </Tab>
        )}
      </Tabs>
    )
  }

  // renderShippingAddressComponent = (addressInfo, index) => {
  //   return (
  //     <AddressList2
  //       addressInfo={addressList}
  //       index={index}
  //       key={`addressInfo${addressInfo.addressId}`}
  //     />
  //   )
  // }

  handleContinueBtnState = value => {
    this.isToDisableContinueBtn = value
  }

  renderChangeMultipleAddressOption = () => {
    if (this.props.isMultipleAdressActive) {
      return (
        <div
          className="shipping-address-link text-right"
          onClick={this.props.toggleMultipleAddress}>
          {i18nTranslate('checkout.shipToSingle', 'Ship to single addresses')}
        </div>
      )
    } else {
      return (
        <div
          className="shipping-address-link text-right d-none"
          onClick={this.props.toggleMultipleAddress}>
          {i18nTranslate(
            'checkout.shipToMultiple',
            'Ship to multiple addresses'
          )}
        </div>
      )
    }
  }

  renderAddAddressContinueButton = () => {
    return (
      <Row noGutters className="justify-content-end">
        <Button
          className="continue-button mt-3 px-5 py-2 btn-bg-blue add-continue-button"
          disabled={this.props.isToDisableShippingContinueBtn || false}
          type="submit"
          data-testid="qa-ship-continue">
          {i18nTranslate('checkoutAddress.submit', 'Continue')}
        </Button>
      </Row>
    )
  }

  renderShippingAddress = AddressForm => {
    const {
      isFromSubscription = false,
      addressList = [],
      isFromCheckout = false,
      addressToSelectOrPrefill = {},
    } = this.props

    const addressFormRender = () => {
      return (
        <AddressForm
          addressInfo={
            this.props?.addressList?.length ? addressToSelectOrPrefill : {}
          }
          onSubmit={this.handleAddressSubmit}
          shippingAddresses={addressList}
          type="checkout"
          renderSubmitButton={this.renderAddAddressContinueButton}
          loadContentFromCS={this.loadContentFromCS}
          enableEQFastFollow={this.enableEQFastFollow}
        />
      )
    }
    if (
      this.props.isMultipleAdressActive &&
      isToEnableItemLevelShipping &&
      !isFromSubscription
    ) {
      return <MultipleShipping {...this.props} />
    } else {
      if (this.props.addressList && this.props.addressList.length) {
        return <>{this.renderTabs({ AddressForm: this.AddressForm })}</>
      } else {
        if (IS_BROWSER) {
          if (isExpressCheckout()) {
            let prefillAddress = Object.keys({
              ...addressToSelectOrPrefill,
            })?.length
            const currentlySelectedAddress = { ...addressToSelectOrPrefill }
            const isAddressIncomplete =
              currentlySelectedAddress?.firstName === null ||
              currentlySelectedAddress?.firstName === ''
            if (isAddressIncomplete) {
              prefillAddress = 0
            }
            return (
              <>
                <ExpressSectionHeader
                  title={i18nTranslate('expresscart.shiptotitle', 'Ship to')}
                  disableAccordion={prefillAddress === 0}
                  isOpen={!this.showList}
                  onClick={
                    prefillAddress > 0 ? () => this.toggleShowList() : () => {}
                  }
                />
                {this.signInType !== 'default' &&
                  !customerContainer.isRegisterUser && (
                    <h6 className="align-items-center d-flex" tabIndex={0}>
                      {i18nTranslate(
                        'signin.expressSignInMessage',
                        'Already have an account ? '
                      )}
                      <button
                        className="okta-button express_signin_option"
                        data-testid="qa-okta-login"
                        onClick={() => {
                          if (this.props?.isFromPDP) {
                            setLocalStorage('isExpressPDPLogin', true)
                          }
                          overlayState?.showLoader()
                          this.handleOverlayClose()
                          signInwithRedirect(this.checkout)
                        }}>
                        {i18nTranslate('signin.okta', 'Sign In')}
                      </button>
                    </h6>
                  )}
                {this.showList && (
                  <div className="d-flex flex-column express-header-body">
                    <span className="fname-normal-shiping-express">
                      {`${currentlySelectedAddress?.firstName} ${
                        currentlySelectedAddress?.firstName?.toUpperCase() !==
                        currentlySelectedAddress?.lastName?.toUpperCase()
                          ? currentlySelectedAddress?.lastName
                          : ''
                      }`}
                    </span>
                    <span className="d-flex flex-row express-address-payment mt-2 flex-wrap">
                      <span
                        className="express-shiping-address-data"
                        data-testid="qa-street">
                        {currentlySelectedAddress?.addressLine1 || ''}
                      </span>
                      <span
                        className="express-shiping-address-data"
                        data-testid="qa-street">
                        {currentlySelectedAddress?.addressLine2 === ''
                          ? currentlySelectedAddress?.addressLine2 || ''
                          : `${currentlySelectedAddress?.addressLine2}, ` || ''}
                        , {currentlySelectedAddress?.city || ''},{' '}
                        {currentlySelectedAddress?.state || ''},{' '}
                        {currentlySelectedAddress?.zip && (
                          <span
                            className="express-shiping-address-data"
                            data-testid="qa-zip">
                            {currentlySelectedAddress?.zip || ''},{' '}
                          </span>
                        )}
                      </span>
                      <span
                        className="express-shiping-address-data"
                        data-testid="qa-country">
                        {currentlySelectedAddress?.country || ''}
                      </span>
                    </span>
                  </div>
                )}
                {!this.showList && (
                  <Suspense fallback="">
                    {/* @todo important
                    // move AddressForm as common code in addressFormRender function
                    // and include new changes from Saran team */}
                    <AddressForm
                      addressInfo={
                        this.props?.addressList?.length
                          ? addressToSelectOrPrefill
                          : {}
                      }
                      onSubmit={this.handleAddressSubmit}
                      isAddressNotAdded={this.props?.isAddressNotAdded || false}
                      shippingAddresses={addressList}
                      type="checkout"
                      renderSubmitButton={this.renderAddAddressContinueButton}
                    />
                  </Suspense>
                )}
              </>
            )
          }
          return (
            <Suspense fallback="">
              {this.loadContentFromCS
                ? Object.keys(this.state?.contentData)?.length > 0 &&
                  addressFormRender()
                : addressFormRender()}
            </Suspense>
          )
        }
      }
    }
  }

  render() {
    /**
     * if radio input changes,
     * preference will be for currentlySelectedAddress
     * so don't change the below order in addressToDisplay
     */
    if (this.props.addressList && this.props.addressList.length) {
      return (
        <div data-testid="qa-shipping-container">
          <Col className="p-0">
            <div
              className={`d-flex ${
                this.props.isMultipleAdressActive
                  ? 'justify-content-end'
                  : 'justify-content-start'
              } ${isExpressCheckout() ? '' : 'm-2 pb-3'}`}></div>
          </Col>
          {this.renderShippingAddress(this.AddressForm)}
        </div>
      )
    } else {
      return (
        <div data-testid="qa-shipping-container">
          {/* {cartProductsLength > 1 &&
            isToEnableItemLevelShipping &&
            !isFromSubscription &&
            this.renderChangeMultipleAddressOption()} */}
          {this.renderShippingAddress(this.AddressForm)}
        </div>
      )
    }
  }
}

export { ShippingAddressListWithForm }
export default ShippingAddressListWithForm
