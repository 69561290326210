import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import { pageNames } from 'src/routes/pathParams'
import {
  i18nTranslate,
  IS_BROWSER,
  trackErrorInInstana,
  getLocalStorage,
  getLocaleCodeFromUrl,
  isExpressCheckout,
} from 'src/utils'
import {
  cartContainer,
  customerContainer,
  checkoutContainer,
  sessionContainer,
} from 'src/models'
import {
  toastState,
  klarnaOverlayState,
  overlayState,
} from 'src/views/components'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import { APPConfig } from 'config/appConfig'

class KlarnaContainer extends CommonContainer {
  @observable isKlarnaTransactionStatusApiInProgress = false
  @observable isKlarnaSubmitOrderApiInProgress = false
  @observable isKlarnaBroadcastMessageReceived = false
  @observable isklarnaBroadCastInstanceCreated = false

  @observable klarnaWindowURL = null
  @observable latestKlarnaStatusCode = {}
  @observable isCloseWindowFromCallback = false
  @observable klarnaBrowserPopupBlock = false
  klarnaTransactionStatusRefreshInterval = null
  submitOrderProps = {}
  windowCloseTimer = null
  klarnaStatusRetryCount = 0
  maxRetryForKlarnaStatus = 3
  klarnaBrowserPopupBlockCount = 0
  maxRetryKlarnaBrowserPopupBlock = 1

  handleLoading(isLoading) {
    if (isLoading) {
      overlayState.showLoader()
      document.body.classList.add('no-scroll')
    } else {
      overlayState.hideLoader()
      document.body.classList.remove('no-scroll')
    }
  }

  handleKlarnaLoading(isLoading) {
    if (isLoading) {
      klarnaOverlayState.showKlarnaLoader()
      document.body.classList.add('no-scroll')
    } else {
      klarnaOverlayState.hideKlarnaLoader()
      document.body.classList.remove('no-scroll')
    }
  }

  trackInstanaWithUserId(errorReport = '', errorData = '') {
    const userId = customerContainer?.userId || ''
    trackErrorInInstana({
      errorReport: errorReport + '  User : ' + userId,
      errorData: { data: errorData },
    })
  }

  handleKlarnaPaymentRedirection = async (options = {}) => {
    const { klarnaStatus = '', successCodeFrom = '' } = options

    if (!this.isKlarnaSubmitOrderApiInProgress) {
      // KLARNA Redirection after Payment - Success Scenario
      if (klarnaStatus === 'success') {
        this.isKlarnaSubmitOrderApiInProgress = true
        this.clearKlarnaTransactionStatusTimer()
        this.isklarnaBroadCastInstanceCreated = false
        this.handleLoading(true)
        let cartResponse = (await cartContainer.viewCart()) || {}
        let cartCount = cartResponse?.value?.count || 0
        if (cartCount > 0) {
          this.trackInstanaWithUserId(
            'Klarna - Initiating Submit Order API',
            klarnaStatus
          )
          await this.handleKlarnaSubmitAfterRedirection()
        } else {
          this.trackInstanaWithUserId(
            'Klarna - As CartCount is Zero, Navigating back to HomePage for User',
            cartContainer.cartCount
          )
          this.handleLoading(false)
          this.submitOrderProps.history?.replace(`${pageNames.home}`)
        }
      }

      // KLARNA Redirection after Payment - Failure Scenario
      if (klarnaStatus === 'failure') {
        this.handleKlarnaFailureState()
      }

      // KLARNA Redirection after Payment - Cancel Scenario
      if (klarnaStatus === 'cancel') {
        this.handleKlarnaCancelState()
      }
    }
  }

  handleKlarnaFailureState = () => {
    const klarnaPaymentStatus = 'Klarna Payment Failure for'
    const klarnaFailureMessage = i18nTranslate(
      'checkout.klarnaFailureMessage',
      "We are sorry, your Klarna payment can't successful. Please select another payment option and try again."
    )

    this.handleKlarnaFailureAfterRedirection(
      klarnaPaymentStatus,
      klarnaFailureMessage
    )
  }

  handleKlarnaCancelState = () => {
    const klarnaPaymentStatus = 'Klarna Payment Cancelled for'
    const klarnaFailureMessage = i18nTranslate(
      'checkout.klarnaCancelMessage',
      'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
    )

    this.handleKlarnaFailureAfterRedirection(
      klarnaPaymentStatus,
      klarnaFailureMessage
    )
  }

  clearKlarnaState = () => {
    checkoutContainer.isKlarnaPaymentAdded = false
    //this.isKlarnaSubmitOrderApiInProgress = false
    this.isKlarnaBroadcastMessageReceived = false
    checkoutContainer.isToInitiateKlarnaPayment = false
    this.isklarnaBroadCastInstanceCreated = false
    this.klarnaBrowserPopupBlock = false
    this.isCloseWindowFromCallback = false
    this.clearKlarnaTransactionStatusTimer()
  }

  handleKlarnaSubmitAfterRedirection = async () => {
    this.clearKlarnaTransactionStatusTimer()
    checkoutContainer.isKlarnaPaymentAdded = true
    const submitOrderResponse = await checkoutContainer.submitOrder()
    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      this.trackInstanaWithUserId(
        'Klarna Submit Order API - Success for',
        submitOrderResponse
      )
      this.handleLoading(false)

      toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      this.clearKlarnaState()
      let isNativeApp = checkIsNativeApp() || false
      if (isNativeApp) {
        this.submitOrderProps.history?.push(
          `${pageNames.orderConfirmation}?isNativeApp=true`
        )
      } else {
        this.submitOrderProps.history?.push(`${pageNames.orderConfirmation}`)
      }
      await cartContainer.viewCart()
    } else {
      let errorMessage = ''
      if (submitOrderResponse?.code == 'ECKLPY0001') {
        errorMessage = i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      } else {
        errorMessage =
          submitOrderResponse?.responseMessage ||
          submitOrderResponse?.message ||
          submitOrderResponse?.errorMessage ||
          i18nTranslate(
            'order.generalError',
            'Sorry,we are unable to place order. Please try again.'
          )
      }
      this.isKlarnaSubmitOrderApiInProgress = false
      this.clearKlarnaState()
      toastState.setToastMessage(errorMessage)
      const sessionId = getLocalStorage('sessionId')

      this.trackInstanaWithUserId(`Klarna Session - ${sessionId}`, errorMessage)

      this.trackInstanaWithUserId(
        'Klarna Submit Order API - Failed for',
        errorMessage
      )
      this.handleLoading(false)
      checkoutContainer.activePath = 'payment'
    }
  }

  handleKlarnaFailureAfterRedirection = (
    klarnaPaymentStatus,
    klarnaFailureMessage
  ) => {
    this.isKlarnaSubmitOrderApiInProgress = false
    checkoutContainer.activePath = 'payment'
    this.clearKlarnaTransactionStatusTimer()
    this.trackInstanaWithUserId(klarnaPaymentStatus, klarnaFailureMessage)
    checkoutContainer.isKlarnaPaymentAdded = false
    toastState.setToastMessage(klarnaFailureMessage)
    this.handleKlarnaLoading(false)
  }

  handleKlarnaPaymentWindowCancel = () => {
    klarnaContainer.klarnaWindowURL && this.closeKlarnaWindow()
    this.clearKlarnaTransactionStatusTimer()
    checkoutContainer.activePath = 'payment'
    const klarnaFailureMessage = i18nTranslate(
      'checkout.klarnaCancelMessage',
      'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
    )
    toastState.setToastMessage(klarnaFailureMessage)
    this.trackInstanaWithUserId(
      'Klarna Payment Cancelled ',
      klarnaFailureMessage
    )
    this.handleKlarnaLoading(false)
    this.handleLoading(false)
    this.clearKlarnaState()
    this.isKlarnaSubmitOrderApiInProgress = false
  }

  closeKlarnaWindow = () => {
    this.isCloseWindowFromCallback = false
    if (
      klarnaContainer.klarnaWindowURL &&
      !klarnaContainer.klarnaWindowURL?.closed
    ) {
      this.isCloseWindowFromCallback = true
      klarnaContainer.klarnaWindowURL?.close?.()
    }
  }

  clearKlarnaTransactionStatusTimer = () => {
    if (this.klarnaTransactionStatusRefreshInterval) {
      clearInterval(this.klarnaTransactionStatusRefreshInterval)
      this.klarnaTransactionStatusRefreshInterval = null
    }
  }

  getKlarnaTransactionStatus = async (options = {}) => {
    const { isFromBroadcast = false, isFromModalClose = false } = options

    if (!this.isKlarnaTransactionStatusApiInProgress) {
      this.isKlarnaTransactionStatusApiInProgress = true
      const confirmationCode =
        checkoutContainer.klarnaInitiateResponse?.transaction?.properties?.find(
          property => property?.name === 'confirmationCode'
        )?.value || ''
      const currencyCode = cartContainer?.cartResponse?.currencyCode || 'USD'
      let klarnaTransactionResponse =
        await checkoutContainer.initiateKlarnaTransactionStatusAPI({
          confirmationCode,
          currencyCode,
        })
      if (checkoutContainer.isSuccessResponse(klarnaTransactionResponse)) {
        this.isKlarnaTransactionStatusApiInProgress = false

        this.trackInstanaWithUserId(
          'Klarna - Transaction Status API Response ',
          klarnaTransactionResponse
        )
        this.latestKlarnaStatusCode =
          klarnaTransactionResponse?.code?.toLowerCase() || ''

        if (!isFromBroadcast && !isFromModalClose) {
          if (this.latestKlarnaStatusCode === 'success') {
            this.trackInstanaWithUserId(
              'Klarna - Transaction Status Success',
              klarnaTransactionResponse
            )

            this.handleKlarnaPaymentRedirection({
              klarnaStatus: 'success',
              successCodeFrom: 'api',
            })
            this.closeKlarnaWindow()
          } else if (
            this.latestKlarnaStatusCode === 'fail' ||
            this.latestKlarnaStatusCode === 'cancelled'
          ) {
            this.clearKlarnaTransactionStatusTimer()
            this.trackInstanaWithUserId(
              'Klarna - Transaction Status Fail',
              klarnaTransactionResponse
            )
            checkoutContainer.activePath = 'payment'
            this.closeKlarnaWindow()
          }
        } else {
          this.clearKlarnaTransactionStatusTimer()
        }
        return klarnaTransactionResponse
      } else {
        //Set klarna status failed count to reach maxRetryForKlarnaStatus, once reached cancel the payment
        this.isKlarnaTransactionStatusApiInProgress = false
        await klarnaContainer.retryKlarnaStatusCall(klarnaTransactionResponse)
      }
    }
  }

  handleKlarnaStatusOnNotReceivingBC = async props => {
    const { id = '', klarnaConfirmationCodeFromInitiateApi = '' } = props
    if (!this.isKlarnaBroadcastMessageReceived) {
      if (id == 'success') {
        this.isklarnaBroadCastInstanceCreated = false
        this.clearKlarnaTransactionStatusTimer()
        await cartContainer.viewCart()
        const klarnaConfirmationCodeFromCart =
          cartContainer.cartResponse?.properties?.klarnaConfirmationCode || ''
        const isConfirmationCodeMatches =
          klarnaConfirmationCodeFromCart ===
          klarnaConfirmationCodeFromInitiateApi
        const isCartCountGreaterThanZero = cartContainer.cartCount > 0
        if (isCartCountGreaterThanZero && isConfirmationCodeMatches) {
          // If Status is Success and Cart Count is greater than 0,
          // then Order is not yet placed, So, we will need to make Submit Order API call
          this.isKlarnaSubmitOrderApiInProgress = true
          await this.handleKlarnaSubmitAfterRedirection()
        } else {
          // If Status is Success and Cart Count is 0,
          // then Order is already placed, So, we will need to redirect to Order Confirmation Page
          // todo - get order confirmation id
          this.handleLoading(false)
          this.clearKlarnaState()
          this.submitOrderProps.history?.push(`${pageNames.orderConfirmation}`)
        }
      }
      if (id == 'failed') {
        this.isklarnaBroadCastInstanceCreated = false
        this.clearKlarnaTransactionStatusTimer()
        checkoutContainer.activePath = 'payment'
        this.closeKlarnaWindow()
      }
      if (id == 'cancelled') {
        this.isklarnaBroadCastInstanceCreated = false
        this.clearKlarnaTransactionStatusTimer()
        checkoutContainer.activePath = 'payment'
        this.closeKlarnaWindow()
      }
    }
  }

  getKlarnaTransactionStatusOnEveryInterval = async (props = {}) => {
    if (!this.isKlarnaTransactionStatusApiInProgress) {
      this.isKlarnaTransactionStatusApiInProgress = true
      const confirmationCode =
        checkoutContainer.klarnaInitiateResponse?.transaction?.properties?.find(
          property => property?.name === 'confirmationCode'
        )?.value || ''
      const currencyCode = cartContainer?.cartResponse?.currencyCode || 'USD'
      let klarnaTransactionResponse =
        await checkoutContainer.initiateKlarnaTransactionStatusAPI({
          confirmationCode,
          currencyCode,
        })
      if (checkoutContainer.isSuccessResponse(klarnaTransactionResponse)) {
        this.isKlarnaTransactionStatusApiInProgress = false

        this.trackInstanaWithUserId(
          'Klarna - Transaction Status API Response ',
          klarnaTransactionResponse
        )
        this.latestKlarnaStatusCode =
          klarnaTransactionResponse?.code?.toLowerCase() || ''
        await this.handleKlarnaStatusOnNotReceivingBC({
          id: this.latestKlarnaStatusCode,
          klarnaConfirmationCodeFromInitiateApi: confirmationCode,
        })
      } else {
        this.isKlarnaTransactionStatusApiInProgress = false
        // todo - Failure Case Handler for Transaction Status API triggered on Interval time
      }
    }
  }

  checkForKlarnaTransactionStatus = async () => {
    const intervalTime =
      Number(APPConfig.getAppConfig().klarnaTransactionStatusTimer) || 300
    if (!this.klarnaTransactionStatusRefreshInterval) {
      this.clearKlarnaTransactionStatusTimer()
      this.klarnaTransactionStatusRefreshInterval = setInterval(async () => {
        await this.getKlarnaTransactionStatusOnEveryInterval()
      }, intervalTime)
    }
  }

  clearKlarnaWindowCloseTimer = () => {
    if (this.windowCloseTimer) {
      clearInterval(this.windowCloseTimer)
      this.windowCloseTimer = null
    }
  }

  checkChildWindow = async () => {
    if (klarnaContainer.klarnaWindowURL?.closed) {
      this.clearKlarnaTransactionStatusTimer()
      this.trackInstanaWithUserId(
        'Klarna Payment Child Window Closed in Check Timeout',
        klarnaContainer.klarnaWindowURL?.closed
      )

      if (!this.isKlarnaBroadcastMessageReceived) {
        const klarnaStatusResponse = await this.getKlarnaTransactionStatus({
          isFromModalClose: true,
        })

        if (checkoutContainer.isSuccessResponse(klarnaStatusResponse)) {
          const statusCode = klarnaStatusResponse?.code?.toLowerCase() || ''
          if (statusCode === 'success') {
            this.trackInstanaWithUserId(
              'Klarna - Navigating to Submit Order Flow from CloseWindow',
              klarnaStatusResponse
            )
            let cartResponse = (await cartContainer.viewCart()) || {}
            let cartCount = cartResponse?.value?.count || 0
            if (cartCount > 0) {
              await this.handleKlarnaPaymentRedirection({
                klarnaStatus: statusCode,
                successCodeFrom: 'modalClose',
              })
            }
          }

          this.resetKlarnaPayment()
          this.manualCloseReset(statusCode)
        } else {
          this.resetKlarnaPayment()
        }
      } else {
        this.resetKlarnaPayment()
      }
    }
  }

  resetKlarnaPayment = async () => {
    this.clearKlarnaWindowCloseTimer()
    this.clearKlarnaTransactionStatusTimer()
    this.handleKlarnaLoading(false) //Hiding klarna timer mask after checking with status call
    this.handleLoading(false)
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    window.removeEventListener('unload', this.handleUnloadEvent)
  }

  manualCloseReset = (status = '') => {
    if (status !== 'success') {
      if (!this.isCloseWindowFromCallback) {
        //Force close of klarna window
        const klarnaFailureMessage = i18nTranslate(
          'checkout.klarnaCancelMessage',
          'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
        )
        toastState.setToastMessage(klarnaFailureMessage)
        this.trackInstanaWithUserId(
          'Klarna Modal Force Closed and payment cancelled',
          klarnaContainer.klarnaWindowURL
        )
        this.isKlarnaSubmitOrderApiInProgress = false
        checkoutContainer.activePath = 'payment'
      }
      this.clearKlarnaState()
      this.isKlarnaSubmitOrderApiInProgress = false
    }
  }

  appendKlarnaPaymentWindow = () => {
    const klarnaPaymentURL = checkoutContainer.klarnaInitiateResponse?.url || ''
    try {
      if (klarnaContainer.klarnaWindowURL && klarnaPaymentURL) {
        klarnaContainer.klarnaWindowURL?.document?.write?.(
          `<script>window.location.href='${klarnaPaymentURL}'</script>`
        )
        klarnaContainer.klarnaWindowURL?.document?.close?.()

        this.windowCloseTimer = setInterval(() => {
          if (klarnaContainer.klarnaWindowURL) {
            this.checkChildWindow()
          }
        }, 200)
      }
    } catch (error) {
      this.trackInstanaWithUserId(
        'Klarna - Append Klarna Payment Window Failed',
        error
      )
    }
  }
  /**
   * Handles the 'beforeunload' event, which is triggered when the user is about to leave the page.
   * This function displays a confirmation message to the user, asking them if they are sure they want to leave the page.
   * The confirmation message is returned as the event's `returnValue` property, which is used by the browser to display the message.
   */
  handleKlarnaWindowListeners = () => {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('unload', this.handleUnloadEvent)
  }

  createKlarnaPaymentWindow = () => {
    try {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600`
      if (IS_BROWSER) {
        klarnaContainer.klarnaWindowURL = window.open(
          '',
          'KlarnaPaymentURL',
          params
        )
        if (klarnaContainer.klarnaWindowURL) {
          this.handleKlarnaWindowListeners()
          this.trackInstanaWithUserId(
            'Klarna - Create Klarna Payment Window Success',
            klarnaContainer.klarnaWindowURL
          )

          klarnaContainer.klarnaWindowURL?.document?.write?.(
            '<html><head><title>Klarna Payment Window</title></head><body>Loading...</body></html>'
          )
        } else {
          this.trackInstanaWithUserId(
            'Klarna - Create Klarna Payment Window Failed',
            'null'
          )

          this.handleLoading(false)
          this.klarnaBrowserPopupBlock = true
          this.handleKlarnaWindowOnRetry()
        }
      }
    } catch (e) {
      const isNativeApp = checkIsNativeApp() || false

      this.trackInstanaWithUserId(
        'Klarna - Create Klarna Payment Window Failed',
        {
          isNativeApp: isNativeApp,
          error: e,
        }
      )
    }
  }

  handleBeforeUnload = event => {
    event?.preventDefault()

    this.trackInstanaWithUserId(
      'Klarna - add/remove beforeunload event listener',
      event
    )
    const confirmationMessage = 'Are you sure you want to leave this page?'
    event.returnValue = confirmationMessage

    return confirmationMessage
  }

  handleUnloadEvent = () => {
    this.trackInstanaWithUserId(
      'Klarna - Unload Event - User chose to reload or navigate away',
      klarnaContainer.klarnaWindowURL
    )
    if (
      klarnaContainer.klarnaWindowURL &&
      !klarnaContainer.klarnaWindowURL?.closed
    ) {
      klarnaContainer.klarnaWindowURL?.close?.()
    }
  }

  redirectToOrderConfirmationPage = async () => {
    const locale = getLocaleCodeFromUrl()?.toLowerCase()?.split('_')
    this.trackInstanaWithUserId(
      'Klarna - Redirecting to Order Confirmation Page',
      klarnaContainer.klarnaWindowURL
    )
    if (this.submitOrderProps.history) {
      this.submitOrderProps.history?.push(`${pageNames.orderConfirmation}`)
      await cartContainer.viewCart()
    } else {
      window.location.href = `/${locale?.[1]}/${locale?.[0]}${pageNames.orderConfirmation}`
    }
  }

  handleKlarnaPaymentWindowRedirection = async (props = {}) => {
    this.submitOrderProps = props
    this.appendKlarnaPaymentWindow()

    if (klarnaContainer.klarnaWindowURL) {
      this.klarnaBrowserPopupBlock = false
      this.handleKlarnaLoading(true)

      if (!this.isklarnaBroadCastInstanceCreated) {
        this.isklarnaBroadCastInstanceCreated = true
        //BroadCast Creation
        let klarnaBroadCastInstances = new BroadcastChannel('klarnaPayment')
        this.trackInstanaWithUserId(
          'Klarna - Broadcast Channel Flow Started',
          this.isklarnaBroadCastInstanceCreated
        )
        if (this.isklarnaBroadCastInstanceCreated) {
          this.trackInstanaWithUserId(
            'Klarna - BroadCast Instance Created ',
            this.isklarnaBroadCastInstanceCreated
          )
          //Receiving Broadcast Message
          klarnaBroadCastInstances.onmessage = async event => {
            this.isKlarnaBroadcastMessageReceived = true
            this.clearKlarnaTransactionStatusTimer()
            // todo - Post message to klarna Checkout as 'received' and handle message in KlarnaCheckout
            this.trackInstanaWithUserId(
              'Klarna - Received BroadCast Channel Message ',
              event?.data
            )

            const klarnaStatus = event?.data?.id || ''
            const orderId = event?.data?.orderId || ''
            checkoutContainer.orderId = orderId || ''

            klarnaBroadCastInstances.postMessage({
              id: klarnaStatus,
              status: checkoutContainer.klarnaBroadcastStatus.STATUS_RECEIVED,
            })

            if (
              klarnaStatus ===
              checkoutContainer.klarnaBroadcastStatus.ORDER_SUCCESS
            ) {
              // Redirect to Order Confirmation Page
              this.trackInstanaWithUserId(
                'Klarna - Received BroadCast Channel Message As Order Created',
                event?.data
              )
              this.clearKlarnaTransactionStatusTimer()
              this.isklarnaBroadCastInstanceCreated = false
              this.clearKlarnaState()
              this.redirectToOrderConfirmationPage()
            } else {
              this.trackInstanaWithUserId(
                'Klarna - Received BroadCast Channel Message As Failed',
                klarnaStatus
              )
              this.handleKlarnaPaymentRedirection({
                klarnaStatus: klarnaStatus,
                successCodeFrom: 'broadcast',
              })
            }
            this.closeKlarnaWindow()
            klarnaBroadCastInstances.close()
          }
        }
      }
    } else {
      // this.klarnaBrowserPopupBlock = true
      // this.handleKlarnaWindowOnRetry()
    }

    await this.checkForKlarnaTransactionStatus()
  }

  handleKlarnaWindowOnRetry = async () => {
    this.handleKlarnaLoading()

    if (klarnaContainer.klarnaWindowURL) {
      klarnaContainer.klarnaWindowURL?.close?.()
    }
    let errorMessage = ''
    if (
      this.klarnaBrowserPopupBlockCount < this.maxRetryKlarnaBrowserPopupBlock
    ) {
      errorMessage = i18nTranslate(
        'checkout.klarnaBrowserPopupBlocked',
        'Klarna - Browser Popup Blocked. Please enable'
      )
      toastState.setToastMessage(errorMessage)
      this.klarnaBrowserPopupBlockCount++
    } else if (
      this.klarnaBrowserPopupBlockCount === this.maxRetryKlarnaBrowserPopupBlock
    ) {
      this.handleKlarnaPaymentWindowCancel()
    }
  }

  retryKlarnaStatusCall = async (klarnaTransactionResponse = {}) => {
    if (this.klarnaStatusRetryCount < this.maxRetryForKlarnaStatus) {
      this.trackInstanaWithUserId(
        'Klarna - Retry - Transaction Status API Failed ',
        klarnaTransactionResponse
      )
      this.klarnaStatusRetryCount++

      await klarnaContainer.getKlarnaTransactionStatus()
    } else if (this.klarnaStatusRetryCount === this.maxRetryForKlarnaStatus) {
      this.handleKlarnaFailureAfterRedirection(
        'Klarna - Transaction Status API Failed',
        'Sorry,we are unable to place order. Please try again.'
      )
      this.trackInstanaWithUserId(
        'Klarna - Retry - Max retry count reached ',
        this.klarnaStatusRetryCount
      )
      this.closeKlarnaWindow()
    }
  }

  createOrAppendKlarnaPaymentWindow = () => {
    if (klarnaContainer.klarnaWindowURL) {
      this.appendKlarnaPaymentWindow()
    } else {
      this.createKlarnaPaymentWindow()

      setTimeout(() => {
        if (klarnaContainer.klarnaWindowURL) {
          this.appendKlarnaPaymentWindow()
        }
      }, 300)
    }
  }

  handleKlarnaSubmitOrderV2 = async (options = {}) => {
    const { submitOrderBtnProps = {} } = options
    const isNativeApp = checkIsNativeApp() || false
    //Checking session for registered user

    const sessionResponse =
      customerContainer?.isRegisterUser &&
      (await sessionContainer.refreshSession())
    if (
      customerContainer?.isRegisterUser &&
      !sessionContainer.isSuccessResponse(sessionResponse)
    ) {
      this.trackInstanaWithUserId(
        'Klarna Refresh Session - Failure',
        sessionResponse
      )
      klarnaContainer.closeKlarnaWindow()
      this.handleLoading(false)
      checkoutContainer.isToInitiateKlarnaPayment = false
    }

    this.handleKlarnaNavigation(submitOrderBtnProps)

    // //Checking for browser popup block and retry 1 time if blocked again then delete the payment
    // if (this.klarnaBrowserPopupBlock) {
    //   this.trackInstanaWithUserId(
    //     'Klarna - Browser Popup Blocked',
    //     this.klarnaBrowserPopupBlock
    //   )
    //   this.handleKlarnaLoading(true)
    //   this.createOrAppendKlarnaPaymentWindow()
    // } else {
    //   //If popup is not blocked then create klarna window only for web
    //   //For nativeApps navigate to usual klarna payment flow
    //   if (!isNativeApp) {
    //     klarnaContainer.createKlarnaPaymentWindow()
    //   }
    //   //This function for both native apps and web
    //   this.handleKlarnaNavigation(submitOrderBtnProps)
    // }
  }

  handleKlarnaNavigation = async () => {
    const klarnaInitiateResponse =
      await checkoutContainer.initiateKlarnaPayment(window?.location?.href)
    if (isExpressCheckout()) {
      await cartContainer.viewCart()
    }
    if (
      checkoutContainer.isSuccessResponse(klarnaInitiateResponse) &&
      klarnaInitiateResponse?.result === 'success' &&
      klarnaInitiateResponse?.result != undefined &&
      typeof window !== 'undefined'
    ) {
      this.trackInstanaWithUserId(
        'Klarna Initiate API - Success',
        klarnaInitiateResponse
      )
      this.handleLoading(false)
      const klarnaPaymentURL = klarnaInitiateResponse?.url || ''
      window.location.replace(`${klarnaPaymentURL}`)
    } else {
      this.trackInstanaWithUserId(
        'Klarna Initiate API - Failure',
        klarnaInitiateResponse
      )
      checkoutContainer.isToInitiateKlarnaPayment = false
      if (typeof window !== 'undefined' && IS_BROWSER) {
        window?.location?.reload()
      }
    }
  }
}
const klarnaContainer = new KlarnaContainer()

export { KlarnaContainer, klarnaContainer }
export default klarnaContainer
