import { observable } from 'mobx'

import debounce from 'lodash/debounce'
import TagManager from 'react-gtm-module'
import {
  getCheckoutProduct,
  i18nTranslate,
  trackUTTConversion,
  trackCheckoutGiftReceipt,
  getLocaleCodeFromUrl,
  isB2BAccount,
  isB2BUser,
  getBrowserIpAddress,
  trackErrorInInstana,
  getShoppingContext,
  clearShoppingContext,
  getBrowserType,
  getGuestSignupOrigin,
  isGuestCheckoutEnabledForWeb,
  checkMysiteOrPersonalOffer,
  getUTMInfo,
  getUTTInfo,
  application,
  getCheckoutGTMPageData,
  isExpressCheckout,
} from 'src/utils'
import { CommonContainer } from 'src/models/Common'
import {
  cartContainer,
  customerContainer,
  tokenExContainer,
  savedCardsContainer,
  loyaltyContainer,
  addressContainer,
  accountsAddressContainer,
  accountsContainer,
} from 'src/models'
import { toastState } from 'src/views/components'
import { storeContainer } from 'src/models/Store'
import {
  getAppConfig,
  getSessionStorage,
  APPConfig,
  getLocalStorage,
  setSessionStorage,
  deleteFromSessionStorage,
  convertToBoolean,
} from 'config/appConfig'
import { IS_BROWSER } from 'src/utils/application'
import isEmpty from 'lodash/isEmpty'
import { pageNames } from 'src/routes/pathParams'
import { alertMessageKeys } from 'src/views/components/CartBlock/fixture'
import { isToHaveOptionalPhoneSchema } from 'src/views/addresses/addressUtils'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'

/**
 * shippingAddress object contains the shipping address fields for checkout.
 * Includes address lines, city, state, country, phone number, etc.
 */
const shippingAddress = {
  addressLine1: '1 front street',
  addressLine2: '',
  canReceiveSMS: false,
  city: 'San Francisco',
  continue: '',
  country: 'US',
  email: '',
  firstName: 'name',
  lastName: 'as',
  middleName: '',
  phone: '9579659952',
  state: 'CA',
  zip: '10001',
}

/**
 * CheckoutContainer class extends CommonContainer.
 * This is likely the main container class for managing checkout state and logic.
 */
class CheckoutContainer extends CommonContainer {
  @observable orderId = ''
  @observable defaultFlag = false
  @observable alipayResponse = {}
  @observable availableAccountCredits = ''
  @observable isPaymentCallsInProgress = false
  @observable giftPayLoad = false
  @observable giftMessage = ''
  @observable dropShippingPayLoad = false
  @observable paypalCheckout = false
  @observable applePayCheckout = false
  @observable gPayCheckout = false
  @observable addressLists = null
  @observable alipayInCart = false
  @observable activeSubmitOrderButton = true
  @observable activePath = ''
  @observable selectedMarket = storeContainer.defaultShippingRegion
  @observable paymentBillingSameAsShippingFlag = false
  @observable isKlarnaPaymentAdded = false
  @observable isToInitiateKlarnaPayment = false
  @observable addPaymentToProfile = customerContainer.isRegisterUser
    ? true
    : false
  @observable isPaymentApiInProgress = false
  @observable isCouponPaid = cartContainer?.cartResponse?.promotionDetails
    ?.codes
    ? true
    : false
  @observable isLoyaltyPaid =
    cartContainer?.cartResponse?.payments?.find(
      item => item?.type?.toLocaleLowerCase() == 'loyalty'
    ) || cartContainer?.cartResponse?.loyaltyDetails?.points
      ? true
      : false
  @observable isStoreCreditsPaid = cartContainer?.cartResponse?.payments?.find(
    item => item?.type == 'STORECREDIT'
  )
    ? true
    : false
  @observable isToDisableSubmitOrderButton = false
  @observable isToDisablePaymentContinueBtn = false
  @observable globalPaymentResponse = {}
  @observable isGlobalPaymentCallInProgress = false
  @observable isToInitiateGlobalPayment = false
  @observable enableAutoSubmit = false
  @observable autoPaymentSuccess = false
  @observable klarnaInitiateResponse = {}
  @observable appliedCouponCodes =
    cartContainer?.cartResponse?.promotionDetails?.codes || []
  @observable expressPaymentAdded = false
  @observable selectedPaymentType = ''
  @observable showExpressCheckoutDrawer = false
  @observable showExpressCheckoutModalBody = false
  @observable renderExpressPDPCart = false

  /**
   * Handles calling viewCart after a successful API response.
   *
   * Checks if the provided response is a successful response using
   * isSuccessResponse, then calls cartContainer.viewCart while passing
   * the loyaltyPointShow parameter through.
   */
  handleViewCart = async (response, loyaltyPointShow) => {
    if (this.isSuccessResponse(response)) {
      await cartContainer.viewCart(loyaltyPointShow)
    }
  }

  /**
   * Debounces calling the viewCart method on the cartContainer
   * to avoid too frequent calls.
   *
   * Uses lodash debounce to rate limit calls to viewCart.
   */
  debounceViewCart = debounce(cartContainer.viewCart, 900)

  /**
   * Checks cart response and disables payment continue
   * button if total unpaid amount is 0.
   */
  checkAndDisablePaymentContinueBtn = () => {
    this.isToDisablePaymentContinueBtn =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid !== 0
        ? false
        : true
  }
  /**
   * Updates the shipping method for the cart or a specific cart item.
   *
   * @param {string} methodName - The ID of the shipping method to select.
   * @param {string} [cartItemId] - Optional cart item ID to update shipping for.
   * @returns {Promise} The API response.
   */
  updateShippingMethod = async (methodName, cartItemId) => {
    const pathParams = cartItemId ? `items/${cartItemId}` : ''
    const loadParams = {
      endPointName: 'updateBagAttributes',
      pathParams: pathParams,
      postData: {
        deliveryDetails: {
          methods: [
            {
              id: methodName,
              isTaxIncluded: true,
              isSelected: true,
            },
          ],
        },
      },
    }
    const response = await this.fetchResponse(loadParams)
    const isExpressGpay =
      convertToBoolean(APPConfig.getAppConfig?.()?.enableExpressGooglePay) ||
      isExpressCheckout() ||
      false
    isExpressGpay
      ? await cartContainer.viewCartForTax()
      : await cartContainer.viewCart()
    return response
  }

  /**
   * Fetches pickup locations for a given country code.
   *
   * @param {string} countryCode - The country code to fetch pickup locations for.
   * @returns {Promise} Promise resolving to the API response containing pickup locations.
   */
  getPickUpLocations = async countryCode => {
    const loadParams = {
      endPointName: 'getPickUpLocations',
      pathParams: countryCode,
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Sets the communication preference for zero checkout.
   *
   * Gets the address from the cart response delivery details.
   * Calls setCommunicationPreference() with the address as input.
   *
   * @returns {Promise} The result of calling setCommunicationPreference().
   */
  setCommunicationPreferenceForZeroCheckout = async () => {
    const communicationPreferenceData =
      cartContainer?.cartResponse?.deliveryDetails?.address || {}
    return await this.setCommunicationPreference(communicationPreferenceData)
  }
  /**
   * Checks if this is a zero checkout order.
   *
   * Returns true if zero checkout is enabled, no coupons or loyalty credits have been applied,
   * and the unpaid order total is 0.
   */
  isZeroCheckout = () => {
    return (
      storeContainer.getEnableZeroCheckout() &&
      !this.isCouponPaid &&
      !this.isLoyaltyPaid &&
      cartContainer?.cartResponse?.paymentValue?.bagValue === 0
    )
  }
  /**
   * Checks if the order is fully paid by a coupon.
   *
   * Returns true if zero checkout is enabled, a coupon has been applied,
   * the unpaid order total is 0, and no loyalty credits or store credits have been applied.
   */
  isCouponFullPayment = () => {
    return (
      storeContainer.getEnableZeroCheckout() &&
      this.isCouponPaid &&
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid === 0 &&
      !this.isLoyaltyPaid &&
      !this.isStoreCreditsPaid
    )
  }
  /**
   * Checks if the order is fully paid by loyalty credits or a coupon.
   *
   * Returns true if zero checkout is enabled, loyalty credits or a coupon has been applied,
   * the unpaid order total is 0, and no store credits have been applied.
   */
  isLoyaltyOrCouponFullPayment = () => {
    return (
      storeContainer.getEnableZeroCheckout() &&
      (this.isCouponPaid || this.isLoyaltyPaid) &&
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid === 0 &&
      !this.isStoreCreditsPaid
    )
  }
  /**
   * Checks if the order is fully paid by loyalty credits, a coupon, or store credits.
   *
   * Returns true if zero checkout is enabled, loyalty credits, a coupon, or store credits have been applied,
   * the unpaid order total is 0.
   */
  isLoyaltyOrCouponOrStoreCreditsFullPayment = () => {
    return (
      storeContainer.getEnableZeroCheckout() &&
      (this.isCouponPaid || this.isLoyaltyPaid || this.isStoreCreditsPaid) &&
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid === 0
    )
  }
  /**
   * Checks if the order total value is 0 when zero checkout is disabled.
   *
   * Returns true if zero checkout is disabled and the unpaid order total is 0.
   * Returns false if zero checkout is enabled.
   */
  isZeroTotValWithDisableZeroCheckout = () => {
    return storeContainer.getEnableZeroCheckout()
      ? false
      : cartContainer?.cartResponse?.paymentValue?.bagValue === 0
  }
  /**
   * Sets the communication preference for the checkout.
   *
   * Updates the checkout with the provided communication preference form data.
   * Fetches profile data to populate some of the preference fields if not provided.
   * Validates required fields like email and phone.
   * Calls API to update the checkout bag attributes.
   * Handles success and error response from API update.
   */
  setCommunicationPreference = async formData => {
    // if (!formData) {
    //   formData = shippingAddress
    // }
    const profileData = customerContainer.profileResponse || {}

    const lastName = isB2BAccount() === true ? '' : formData?.lastName
    let contactMethodText = ''

    formData?.email === null
      ? (contactMethodText = 'TEXT')
      : (contactMethodText = 'EMAIL')
    const loadParams = {
      endPointName: 'updateBagAttributes',
      postData: {
        communicationPreference: {
          //CX15-7890 - as per BA comments we set communicationPreference data from profiledata
          firstName: profileData?.firstName || formData?.firstName || '',
          lastName: profileData?.lastName || formData?.lastName || '',
          email: profileData?.email || formData?.email || '',
          phone: profileData?.phoneNumber || formData?.phone || '',
          zipCode: formData?.zip || '',
          preferredContactMethod: contactMethodText,
        },
      },
    }

    const errorMessage = {
      phone: i18nTranslate(
        'addressBook.invalidPhone',
        'Please enter contact number'
      ),
      email: i18nTranslate('validation.emailErrorText', 'Please enter email'),
    }

    const errorResponseMessage = {
      code: '',
      message: '',
      status: 'failure',
    }
    let isValidData = true
    let isOptionalPhone = isToHaveOptionalPhoneSchema('checkout') || false
    if (
      !loadParams?.postData?.communicationPreference?.phone &&
      !isOptionalPhone
    ) {
      isValidData = false
      errorResponseMessage.message = errorMessage.phone
    } else if (!loadParams?.postData?.communicationPreference?.email) {
      errorResponseMessage.message = errorMessage.phone
      isValidData = false
    }

    if (!isValidData) {
      return Promise.resolve(errorResponseMessage)
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      const isExpressGpay =
        convertToBoolean(APPConfig.getAppConfig?.()?.enableExpressGooglePay) ||
        isExpressCheckout() ||
        false
      isExpressGpay
        ? await cartContainer.viewCartForTax()
        : await cartContainer.viewCart()
    } else {
      trackErrorInInstana({
        errorReport: 'Fn setCommunicationPreference API',
        errorData: response,
      })
    }
    return response
  }
  /**
   * Sets the shipping address for the cart or a specific cart item.
   *
   * @param {Object} formData - The address form data object.
   * @param {string} [cartItemId] - The ID of the cart item to set address for.
   * @param {boolean} [isItemLevelShipping=false] - Whether this is item-level shipping.
   * @returns {Promise} The API response.
   */
  setShippingAddress = async (
    formData,
    cartItemId,
    isItemLevelShipping = false
  ) => {
    const enabledGuestForWeb = isGuestCheckoutEnabledForWeb()
    if (!formData) {
      formData = shippingAddress
    }
    const {
      addressUserName = '',
      firstName = '',
      deliveryInstruction = '',
      shippingInstructions = '',
      ...remainingProps
    } = formData

    const name =
      addressUserName !== '' && addressUserName !== undefined
        ? addressUserName
        : firstName
    const pathParams = cartItemId ? `items/${cartItemId}` : ''
    const shippingAddressPostData = {
      deliveryDetails: {
        deliveryType: 'PHYSICAL',
        address: {
          firstName: name,
          deliveryInstruction:
            deliveryInstruction || shippingInstructions || '',
          ...remainingProps,
          canReceiveSMS: false,
          profileAddressId:
            formData.profileAddressId || formData.addressId || null,
          isDefault: formData?.setDefaultAddress || false,
          validation: [
            {
              type: 'PHYSICAL',
              validated: true,
              overridden: true,
            },
          ],
          options: {
            addAddressToProfile:
              customerContainer.isRegisterUser && !formData.addressId
                ? true
                : false,
          },
        },
      },
    }

    const loadParams = {
      endPointName: 'updateBagAttributes',
      postData: shippingAddressPostData,
      pathParams: pathParams,
    }
    const response = await this.fetchResponse(loadParams)
    const enableNewGuestModal =
      convertToBoolean(APPConfig.getAppConfig()?.enableNewGuestModal) || false
    if (enabledGuestForWeb && !checkMysiteOrPersonalOffer()) {
      await this.updateCartProperties({ cartItemId })
      if (!customerContainer.isRegisterUser) {
        await this.updateGuestCustomerConsents(formData)
      }
    } else if (
      checkMysiteOrPersonalOffer() &&
      enableNewGuestModal &&
      !customerContainer.isRegisterUser
    ) {
      const { email = '', firstName = '', lastName = '' } = formData || {}
      const signupOrigin = getGuestSignupOrigin()

      const postData = {
        firstName,
        lastName,
        email: email?.toLowerCase(),
        customProperties: {
          personType: 'PRIMARY',
          signupOrigin,
        },
      }
      await cartContainer?.updateGuestCustomer(postData)
    }
    if (isItemLevelShipping) {
      this.debounceViewCart()
    } else {
      await cartContainer.viewCart()
    }
    if (
      this.isSuccessResponse(response) &&
      !customerContainer.isRegisterUser &&
      !isExpressCheckout()
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'guest_checkout_consent',
          vip_status: formData?.signupBrandRepresentative
            ? 'opt-in'
            : 'opt-out',
          email_status: formData?.emailOptIn ? 'opt-in' : 'opt-out',
          sms_status: formData?.smsOptIn ? 'opt-in' : 'opt-out',
        },
      })
    }
    return response
  }

  updateCartProperties = async options => {
    const { cartItemId } = options || {}
    const pathParams = cartItemId ? `items/${cartItemId}` : ''
    const utmInfo = getUTMInfo() || {}

    const { enableEQFastFollow = false } = APPConfig?.getAppConfig() || false
    const uttInfo = convertToBoolean(enableEQFastFollow)
      ? getUTTInfo() || {}
      : {}

    const sponsorId = accountsContainer?.getUserTypeSponsorId()
    const guestCheckoutPostData = {
      properties: {
        source: getGuestSignupOrigin(),
        sponsorId: sponsorId,
        ...utmInfo,
        ...uttInfo,
      },
    }

    const loadParams = {
      endPointName: 'updateBagAttributes',
      pathParams: pathParams,
      postData: guestCheckoutPostData,
    }

    const response = await this.fetchResponse(loadParams)
  }

  updateGuestCustomerConsents = async (addressData = {}) => {
    const { consents = [] } = await customerContainer.signupConsents()
    const enableNewGuestModal =
      convertToBoolean(APPConfig.getAppConfig()?.enableNewGuestModal) || false
    let consentTitle = []
    let activeConsent = []
    if (Array.isArray(consents) && consents.length) {
      const vipCheckboxStatus = getSessionStorage('vipCheckboxStatus') || false
      consentTitle.push(
        vipCheckboxStatus ? 'privacy_noprivacy~0' : 'privacy_contactinfo~1'
      )

      const { enableEQFastFollow = false } = APPConfig?.getAppConfig() || false
      const checkEQFastFollow = convertToBoolean(enableEQFastFollow)

      if (checkEQFastFollow && !customerContainer.isRegisterUser) {
        const emailOptInStatus = getSessionStorage('emailOptInStatus') || false
        consentTitle.push(
          emailOptInStatus
            ? 'promotional_optIn_email'
            : 'promotional_optOut_email'
        )
        const smsOptInStatus = getSessionStorage('smsOptInStatus') || false
        consentTitle.push(
          smsOptInStatus ? 'promotional_optIn_SMS' : 'promotional_optOut_SMS'
        )
      }

      activeConsent = consents?.filter(consent =>
        consentTitle.includes(consent.title)
      )
    }

    const ipAddress = await getBrowserIpAddress()
    const browserType = getBrowserType() || {}
    const {
      email = '',
      firstName = '',
      lastName = '',
    } = enableNewGuestModal ? addressData : customerContainer?.profileResponse

    let consentsArray = []
    activeConsent?.map(consent => {
      const { id = '', title = '' } = consent || {}
      consentsArray.push({
        id: customerContainer.profileResponse?.id || '',
        consentId: id,
        userId: customerContainer.profileResponse?.id || '',
        browser: browserType?.name,
        device: application.deviceType,
        geoLocation:
          storeContainer.currentRegion ||
          getLocalStorage('currentRegion') ||
          '',
        ipAddress: ipAddress,
        createdTimestamp: new Date(),
        updatedTimestamp: new Date(),
        title: title,
      })
    })

    //const sponsorId = accountsContainer?.getUserTypeSponsorId()
    const consentsPostData = {
      email: email?.toLowerCase(),
      firstName: firstName || '',
      lastName: lastName || '',
      consents: consentsArray, //Commented SponsorId code for PUR-1124
      // customProperties: {
      //   sponsorId: sponsorId,
      // },
    }
    if (enableNewGuestModal) {
      const signupOrigin = getGuestSignupOrigin()
      consentsPostData.customProperties = {
        personType: 'PRIMARY',
        signupOrigin,
      }
    }
    await cartContainer?.updateGuestCustomer(consentsPostData)
  }

  /**
   * Sets the pickup location as address for delivery details
   *
   * @param {Object} address - Address from location api
   */
  setPickupLocationAsDefault = async (address = {}) => {
    const {
      address1 = '',
      address2 = '',
      address3 = '',
      city = '',
      state = '',
      postalCode = '',
      country = '',
    } = address
    const { firstName = '', lastName = '' } = customerContainer?.profileResponse
    const defaultAddressPostData = {
      deliveryDetails: {
        deliveryType: 'PHYSICAL',
        address: {
          firstName: firstName,
          lastName: lastName,
          addressLine1: address1,
          addressLine2: address2,
          addressLine3: address3,
          city: city,
          state: state,
          country: country,
          email: customerContainer?.profileResponse?.email || '',
          phone: customerContainer?.profileResponse?.phoneNumber || '',
          zip: postalCode,
          deliveryInstruction: '',
          canReceiveSMS: false,
          profileAddressId: null,
          isDefault: true,
          validation: [
            {
              type: 'PHYSICAL',
              validated: true,
              overridden: true,
            },
          ],
          options: {
            addAddressToProfile: true,
          },
        },
      },
    }
    const loadParams = {
      endPointName: 'updateBagAttributes',
      postData: defaultAddressPostData,
    }
    const response = await this.fetchResponse(loadParams)
    await cartContainer.viewCart()
  }
  /**
   * Checks if there is a default payment in the customer's saved payments.
   * Sets this.defaultFlag to true if no default payment is found.
   */
  getIsDefault = () => {
    this.defaultFlag = !customerContainer?.paymentResponse?.payments?.find(
      (cards, index) => cards.isDefault
    )
  }
  /**
   * Retrieves a PayPal token by calling the getPayPalToken endpoint.
   *
   * Creates a loadParams object with the getPayPalToken endpoint name.
   * Calls the fetchResponse method with loadParams.
   * Returns the response from fetchResponse.
   */
  getPayPalToken = async () => {
    const loadParams = {
      endPointName: 'getPayPalToken',
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Retrieves a Payment token by calling the getBrainTreePaymentToken endpoint.
   *
   * Creates a loadParams object with the getBrainTreePaymentToken endpoint name and
   * a query parameter specifying the payment type.
   * Calls the fetchResponse method with loadParams.
   * Logs the response and returns it.
   */
  getBrainTreePaymentToken = async (paymentType = '') => {
    const loadParams = {
      endPointName: 'getBrainTreePaymentToken',
      queryParams: {
        paymentType,
      },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Validates the original billing address passed in reqParams, calls originalAddressApiCall
   * to add it, and if successful calls addPaymentsToCart.
   *
   * @param {Object} reqParams - Request parameters object containing billingAddress
   * @returns {Promise} - Promise resolving to response from addPaymentsToCart or address API
   */
  validateOriginalAddress = async reqParams => {
    let addressAddResponse = {}
    let response = await addressContainer?.originalAddressApiCall(
      reqParams?.billingAddress
    )
    if (this.isSuccessResponse(response)) {
      addressAddResponse = this.addPaymentsToCart(reqParams)
      return addressAddResponse
    } else {
      if (response.message === 'input.validation.failed') {
        response.message = alertMessageKeys(
          'Please correct the address to proceed.'
        )
        toastState.setToastMessage(response.message, false)
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'address.addAddressFailure',
            'Sorry, we are unable to add your address. Please try again.'
          ),
          false
        )
      }
      return response
    }
  }
  /**
   * Adds a payment to the cart by calling the addPaymentToCart API endpoint.
   *
   * Constructs the payment data and options to send to the API. Gets cart and user info from containers.
   * Handles saving payment info to user's profile if requested.
   * Sends tag manager event on success.
   * Handles error cases and validation failures.
   */
  addPaymentsToCart = async paymentData => {
    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || ''
    )
    if (customerContainer.isRegisterUser) {
      await this.getIsDefault()
    }
    const cardDetails = {}
    const { isFromSavedAddress } = paymentData

    if (isFromSavedAddress) {
      const { isValid } = savedCardsContainer?.tokenCvvValidData
      if (!isValid) {
        checkoutContainer.isPaymentApiInProgress = false
        return false
      }
    }

    if (paymentData.isPONumber) {
      cardDetails.properties = { purchaseOrder: paymentData.PONumber }
    } else {
      cardDetails.accountInfo = paymentData.accountInfo
      if (paymentData.isFromSavedAddress || paymentData.isRewardPoints) {
        cardDetails.paymentItems = [{ paymentRefId: paymentData?.id }]
        cardDetails.accountInfo = {
          cvv: paymentData.cardCVV,
        }
      }
    }

    const cardType = paymentData.cardType
      ? paymentData.cardType
      : paymentData.type
      ? paymentData.type
      : 'CREDITCARD'
    const valueType =
      cardType.toLowerCase() === 'loyalty' ? 'NONCASH' : 'CURRENCY'
    const { firstName, lastName, addressUserName, ...remainingProps } =
      paymentData.billingAddress || {}
    const addPaymentToProfile =
      paymentData.billingAddress?.option?.addPaymentToProfile || false
    const userFirstName = addressUserName || firstName
    const userLastName = lastName || userFirstName
    const paymentDataProperties = paymentData.isFromSavedAddress
      ? { ...savedCardsContainer.tokenExCvvData }
      : { ...tokenExContainer.tokenExFormData }
    const loadParams = {
      endPointName: 'addPaymentToCart',
      postData: [
        {
          billingAddress: {
            firstName: userFirstName,
            lastName: userLastName,
            ...remainingProps,
          },
          ...cardDetails,
          type: cardType,
          valueType,
          value: bagValue,
          isDefault: this.defaultFlag
            ? this.defaultFlag
            : paymentData?.isDefault || false,
          amount: bagValue,
          option: {
            addPaymentToProfile: addPaymentToProfile,
            useDefaultPayment: false,
            setAsDefaultPaymentInProfile: false,
          },
          properties: paymentDataProperties,
        },
      ],
    }
    delete loadParams.postData[0].properties.cardType
    const response = await this.fetchResponse(loadParams)

    /**
     * @info
     * GTM Datalayer -  Add payment during checkout
     */
    const { accountInfo, isDefault } = loadParams?.postData?.[0] || []
    TagManager.dataLayer({
      dataLayer: {
        event: 'paymentAddedComplete',
        ...getCheckoutGTMPageData({
          pageTitle: 'Payment Details',
        }),
        firstName: accountInfo?.name || '',
        lastName: accountInfo?.lastName || '',
        cardType: accountInfo?.type || '',
        isDefault: isDefault || '',
        platform: 'equinox',
      },
    })

    if (response?.message?.toLowerCase() == 'validation error') {
      trackErrorInInstana({
        errorReport: 'Payment validation error',
        errorData: {
          payload: loadParams,
          apiResponse: response,
        },
      })
    }

    // await cartContainer.viewCart()
    if (!this.isSuccessResponse(response)) {
      await this.deletePayment()
    }
    return response
  }

  /**
   * Adds a store credit payment to the cart.
   *
   * @param {number} storeCreditValue - The amount of store credit to apply as payment.
   * @returns {Promise} Response from adding payment to cart API call.
   */
  addStoreCreditPayment = async storeCreditValue => {
    const loadParams = {
      endPointName: 'addPaymentToCart',
      postData: [
        {
          type: 'STORECREDIT',
          valueType: 'STORECREDIT',
          value: storeCreditValue,
          amount: storeCreditValue,
          option: {
            addPaymentToProfile: true,
            useDefaultPayment: false,
            setAsDefaultPaymentInProfile: false,
          },
        },
      ],
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Deletes a payment from the cart by ID.
   *
   * @param {string} paymentId - The ID of the payment to delete.
   * @returns {Promise} The response from the API call.
   */
  deleteCartPaymentWithID = async paymentId => {
    const loadParams = {
      endPointName: 'deletePaymentsFromCart',
      pathParams: paymentId,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Deletes any existing store credit payment from the cart.
   *
   * Gets the current cart payments and finds any with the 'STORECREDIT' type.
   * If one exists, calls deleteCartPaymentWithID() to remove it.
   *
   * Shows success/failure toast messages based on response from delete call.
   *
   * Returns the API response from deleting the payment (or true if none existed).
   */
  deleteAllStoreCreditPayments = async () => {
    const existingPayments = cartContainer?.cartResponse?.payments || []
    const storeCreditPayment = existingPayments?.find(
      payment => payment?.type === 'STORECREDIT'
    )

    if (storeCreditPayment && storeCreditPayment.id) {
      const response = await checkoutContainer.deleteCartPaymentWithID(
        storeCreditPayment.id
      )
      if (checkoutContainer.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate(
            'cart.storeCreditPurchaseSuccess',
            'Payment has been removed from store credit.'
          )
        )
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'cart.storeCreditPurchaseFailure',
            'Unable update payment preference, Please try again.'
          )
        )
      }
      return response
    }
    return true
  }

  /**
   * Deletes all existing credit card payments from the cart.
   *
   * Gets the current cart payments and loops through them, finding any with
   * 'CREDITCARD' or 'DEBITCARD' type. Calls deleteCartPaymentWithID() on each to remove it.
   *
   * After removing, calls viewCart() to refresh the cart data.
   *
   * Returns nothing.
   */
  deleteAllCreditCardPayments = async () => {
    const existingPayments = cartContainer?.cartResponse?.payments || []
    let isDeleteCallMade = false
    for (const payment of existingPayments) {
      if (payment?.type === 'CREDITCARD' || payment?.type === 'DEBITCARD') {
        const response = await checkoutContainer.deleteCartPaymentWithID(
          payment.id
        )
        isDeleteCallMade = true
      }
    }
    if (isDeleteCallMade) {
      await cartContainer.viewCart()
    }
  }

  /**
   * Checks the available store credit points for the current cart.
   *
   * Gets the cart payments and finds any 'STORECREDIT' payment.
   * Calculates the total unpaid amount.
   * Gets the current loyalty points.
   * Sets availableAccountCredits to the STORECREDIT amount, if it exists.
   * Otherwise sets to min of total unpaid amount or current points.
   */
  checkAvailableStoreCreditPoints = () => {
    const payments = cartContainer?.cartResponse?.payments || []
    let storeCreditPayment = payments?.find(option => {
      return option?.type === 'STORECREDIT'
    })
    const totalAmountUnpaid =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0.0
    let currentPoints = loyaltyContainer.storeCreditPoints || 0
    this.availableAccountCredits = storeCreditPayment
      ? storeCreditPayment?.amount || 0
      : currentPoints > totalAmountUnpaid
      ? totalAmountUnpaid
      : currentPoints
  }

  /**
   * Loads the Braintree client library script if not already loaded.
   *
   * Checks if document contains element with ID 'braintreeClient'.
   * If not, creates a new <script> element, sets ID and src,
   * and appends to document body.
   */
  loadBraintreeClientScript = () => {
    if (IS_BROWSER && !document.getElementById('braintreeClient')) {
      const brainTreeClientScript =
        APPConfig.getAppConfig()?.brainTreeClientScript || ''
      const script = document.createElement('script')
      script.id = 'braintreeClient'
      script.src = brainTreeClientScript
      document.body.appendChild(script)
    }
  }

  /**
   * Loads the Braintree checkout library script if not already loaded.
   *
   * Checks if the browser document contains an element with ID 'braintreeCheckout'.
   * If not, it creates a new <script> element, sets the ID and src attributes,
   * and appends it to the document body.
   */
  loadBraintreeCheckoutScript = () => {
    if (IS_BROWSER && !document.getElementById('braintreeCheckout')) {
      const script = document.createElement('script')
      script.id = 'braintreeCheckout'
      script.src =
        'https://js.braintreegateway.com/web/3.101.3/js/paypal-checkout.min.js'
      document.body.appendChild(script)
    }
  }

  /**
   * Loads the Braintree Venmo client library script if not already loaded.
   *
   * Checks if the browser document contains an element with ID 'braintreeVenmoClient'.
   * If not, it creates a new <script> element, sets the ID and src attributes,
   * and appends it to the document body.
   */
  loadBraintreeVenmoClientScript = () => {
    if (IS_BROWSER && !document.getElementById('braintreeVenmoClient')) {
      const braintreeClientScript =
        APPConfig.getAppConfig().brainTreeClientScript
      const script = document.createElement('script')
      script.id = 'braintreeVenmoClient'
      script.src = braintreeClientScript
      document.body.appendChild(script)
    } else {
      console.log('** Venmo Client Script not loaded')
    }
  }

  /**
   * Loads the Braintree Venmo data collector library script if not already loaded.
   *
   * Checks if the browser document contains an element with ID 'braintreeVenmoDataCollector'.
   * If not, it creates a new <script> element, sets the ID and src attributes,
   * and appends it to the document body.
   */
  loadBraintreeVenmoDataCollectorScript = () => {
    if (IS_BROWSER && !document.getElementById('braintreeVenmoDataCollector')) {
      const venmoDataCollectorScript =
        APPConfig.getAppConfig().venmoDataCollectorScript
      const script = document.createElement('script')
      script.id = 'braintreeVenmoDataCollector'
      script.src = venmoDataCollectorScript
      document.body.appendChild(script)
    } else {
      console.log('** Venmo Data Collector Script not loaded')
    }
  }

  /**
   * Loads the Braintree Venmo client library script if not already loaded.
   *
   * Checks if the browser document contains an element with ID 'braintreeVenmo'.
   * If not, it creates a new <script> element, sets the ID and src attributes,
   * and appends it to the document body.
   */
  loadBraintreeVenmoScript = () => {
    if (IS_BROWSER && !document.getElementById('braintreeVenmo')) {
      const venmoClientScript = APPConfig.getAppConfig().venmoClientScript
      const script = document.createElement('script')
      script.id = 'braintreeVenmo'
      script.src = venmoClientScript
      document.body.appendChild(script)
    } else {
      console.log('** Venmo Script not loaded')
    }
  }

  loadApplePayScripts = () => {
    if (IS_BROWSER && !document.getElementById('applepayInitJs')) {
      const applepayScript =
        APPConfig.getAppConfig?.()?.applepayInitScript || ''
      const script = document.createElement('script')
      script.id = 'applepayInitJs'
      script.src = applepayScript
      document.body.appendChild(script)
    } else {
      console.log('** Apple Pay Script is not loaded')
    }
  }

  /**
   * handleAddressPayPalCartCheckout
   *
   * Async function that handles setting the shipping address for PayPal checkout.
   *
   * Checks if this is a B2B user, and if so, gets the address list from the accountsAddressContainer.
   * Otherwise gets the address list from the customer's profile.
   *
   * Filters the addresses to only include the default shipping address in the store's default country.
   *
   * Sets the default shipping address via the checkoutContainer.
   *
   * Checks the response for any out of stock or backordered items, and if found,
   * displays a toast message and redirects to the cart page after 1 second.
   *
   * Returns the response from setting the shipping address.
   */
  handleAddressExpressCheckout = async () => {
    if (isB2BAccount() && isB2BUser()) {
      const accountAdressResponse = accountsAddressContainer?.addressData || []

      this.addressLists = accountAdressResponse
    } else {
      const customerAddressResponse = await addressContainer.getProfileAddress()
      this.addressLists =
        customerAddressResponse && customerAddressResponse.length
          ? customerAddressResponse
          : []
    }
    const defaultCountry =
      storeContainer.defaultShippingRegion === 'Canada'
        ? 'CA'
        : storeContainer.defaultShippingRegion

    const filteredAddress = this.addressLists
      ? this.addressLists.filter(
          data => data?.country === defaultCountry && data?.type === 'shipping'
        )
      : this.addressLists
    const defaultAddress = filteredAddress.find(
      accountAddress => accountAddress.isDefault
    )
    if (!isEmpty(defaultAddress)) {
      let response = await checkoutContainer.setShippingAddress(defaultAddress)
      response['items']?.length > 0 &&
        response['items'].forEach(item => {
          item &&
            item['skus'] &&
            item['skus'].map(bundleitem => {
              let inventoryProperties = bundleitem.inventoryProperties
              if (
                (inventoryProperties?.['available'] == false &&
                  inventoryProperties?.['backOrdered'] == true) ||
                (inventoryProperties?.['available'] == false &&
                  inventoryProperties?.['backOrdered'] == false) ||
                (inventoryProperties?.['available'] == false &&
                  inventoryProperties?.['backOrdered'] == null)
              ) {
                toastState.setToastMessage(
                  i18nTranslate(
                    'checkout.backOrder',
                    'Item got backordered or OOS'
                  ),
                  false
                )
                setTimeout(() => {
                  let originUrl =
                    window?.location?.href?.split(pageNames.checkout) ||
                    window?.location?.href?.split(pageNames.expressCheckout) ||
                    []
                  window.location.replace(
                    `${originUrl?.[0]}${pageNames?.viewCart}`
                  )
                }, 1000)
              }
            })
        })
      return response
    } else return
  }

  handleExpressGpayPostData = async paymentType => {
    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || ''
    )

    await checkoutContainer.setCommunicationPreference(
      cartContainer?.cartResponse?.deliveryDetails?.address || {}
    )
    const postData = [
      {
        name: paymentType.toLowerCase(),
        status: 'active',
        type: paymentType,
        amount: bagValue,
        isValid: true,
      },
    ]
    const response = await checkoutContainer.addAlternatePayment(postData)
    await cartContainer.viewCartForTax()
    return response
  }

  /**
   * Handles the checkout process for a PayPal payment.
   * Deletes any existing payments from the cart, calculates the total unpaid amount,
   * sets the shipping address, creates the PayPal payment payload,
   * calls the API to add the PayPal payment to the cart,
   * and returns the API response.
   */
  handleExpressCheckoutPostData = async paymentType => {
    if (cartContainer?.cartResponse?.payments !== undefined) {
      await this.deletePayment()
    }
    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || ''
    )

    await checkoutContainer.setCommunicationPreference(
      cartContainer?.cartResponse?.deliveryDetails?.address || {}
    )
    const postData = [
      {
        name: paymentType.toLowerCase(),
        status: 'active',
        type: paymentType,
        amount: bagValue,
        isValid: true,
      },
    ]
    const response = await checkoutContainer.addAlternatePayment(postData)
    // TODO EX : Need to check below api required?
    await cartContainer.viewCart()
    return response
  }

  /**
   * Enables or disables PayPal checkout based on the provided flag.
   *
   * @param {boolean} flag - Whether to enable PayPal checkout.
   */
  paypalCheckoutEnable = flag => {
    this.paypalCheckout = flag
  }

  applePayCheckoutEnable = flag => {
    this.applePayCheckout = flag
  }

  gPayCheckoutEnable = flag => {
    this.gPayCheckout = flag
  }

  /**
   * Adds an alternate payment method to the cart.
   *
   * @param {Object} paymentDetail - The payment details object to add.
   * @returns {Promise} The API response from adding the payment.
   */
  addAlternatePayment = async paymentDetail => {
    const loadParams = {
      endPointName: 'addPaymentToCart',
      postData: paymentDetail,
    }

    const response = await this.fetchResponse(loadParams)

    const { accountInfo, isDefault, type } = response?.[0] || []

    if (this.isSuccessResponse(response)) {
      /**
       * @info
       * GTM Datalayer -  Alternate payment success
       */

      TagManager.dataLayer({
        dataLayer: {
          event: 'paymentAddedComplete',
          ...getCheckoutGTMPageData({
            pageTitle: 'Payment Details',
          }),
          firstName: accountInfo?.name || '',
          lastName: accountInfo?.lastName || '',
          cardType: accountInfo?.type || '',
          isDefault: isDefault || '',
          platform: 'equinox',
        },
      })
      this.lastPaymentResponse = response
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'payment.addPaymentFailure',
          'Sorry, we are unable to add your payment information. Please try again.'
        ),
        false
      )
      /**
       * @info
       * GTM Datalayer -  Alternate payment failure
       */
      TagManager.dataLayer({
        dataLayer: {
          event: 'paymentError',
          ...getCheckoutGTMPageData({
            pageTitle: 'Payment Details',
          }),
          cardType: type || '',
          platform: 'equinox',
        },
      })
      if (!this.isSuccessResponse(response)) {
        await this.deletePayment()
        checkoutContainer.activePath = 'shippingMethods'
      }
    }
    return response
  }

  /**
   * @description addAlipayInitiate function initializes Alipay payment by calling the addAlipayToCart endpoint.
   * It constructs the callback url by getting the cartType and checking if its a native app.
   * It makes the API call with the constructed callback urls.
   * On failure it deletes the payment and updates the checkout.
   */
  addAlipayInitiate = async domainName => {
    await cartContainer.viewCart
    const url = this.constructUrlForNativeApp(domainName) || domainName || ''
    const urlWithCartType =
      this.constructUrlWithCartType(url) || domainName || ''
    const loadParams = {
      endPointName: 'addAlipayToCart',
      postData: {
        callbackUrlFail: urlWithCartType,
        callbackUrlSuccess: urlWithCartType,
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (!this.isSuccessResponse(response)) {
      await this.deletePayment()
    }
    this.alipayResponse = { ...response }
  }

  /**
   * Constructs the cart URL with the cart type appended as a query parameter.
   *
   * @param {string} domain - The base domain URL
   * @returns {string} The domain URL with the cart type query parameter appended
   */
  constructUrlWithCartType = (domain = '') => {
    const cartType = this.getCartType()
    const url = new URL(domain)
    url?.searchParams?.append('type', cartType)
    return url?.href || domain
  }

  /**
   * Constructs a URL by appending the product ID as a query parameter.
   *
   * @param {Object} options - An object containing the base URL.
   * @param {string} options.url - The base URL to construct the new URL from.
   * @returns {string} The URL with the product ID query parameter appended.
   */
  constructUrlWithExpressDetails = options => {
    const { url } = options || {}
    const cartType = this.getCartType()
    const updatedUrl = new URL(url)
    const lastPath = this.getLastPathFromUrl()
    const expressCheckout = pageNames.expressCheckout?.includes(lastPath)
      ? pageNames.expressCheckout?.replace('/', '')
      : ''
    if (expressCheckout) {
      updatedUrl?.searchParams?.append('page', lastPath)
    } else {
      updatedUrl?.searchParams?.append('productId', lastPath)
    }
    return updatedUrl?.href || url
  }

  /**
   * Extracts the last path name from URL
   *
   * @param {string} url - The URL to extract the product ID or page name.
   * @returns {string} The page name or product ID extracted from the URL.
   */
  getLastPathFromUrl = url => {
    const urlObj = new URL(url || location.href)
    const pathSegments = urlObj.pathname.split('/')
    const lastSegment = pathSegments[pathSegments.length - 1]
    return lastSegment
  }

  /**
   * Constructs the URL for native app by appending isNativeApp query parameter
   * if it doesn't already exist and the app is detected as a native app.
   *
   * @param {string} domain - The base domain URL
   * @returns {string} The domain URL with isNativeApp query parameter appended if needed
   */
  constructUrlForNativeApp = (domain = '') => {
    const url = new URL(domain)
    const isUrlHasNativeAppQuery =
      url?.searchParams?.get('isNativeApp') || false
    const isNativeApp = checkIsNativeApp() || false

    if (!isUrlHasNativeAppQuery && isNativeApp) {
      url?.searchParams?.append('isNativeApp', true)
      return url?.href || ''
    }
    return domain
  }

  /**
   * Constructs a URL by appending a key/value pair as a query parameter.
   *
   * @param {string} domain - The base URL
   * @param {string} key - The query parameter key
   * @param {string} value - The query parameter value
   * @returns {string} The URL with the query parameter appended
   */
  constructUrlWithStatus = (domain = '', key = '', value = '') => {
    const url = new URL(domain)
    url?.searchParams?.append(key, value)
    return url?.href || domain
  }

  /**
   * @description getCartType function is used to find the CartType from User GET API Response
   * @note For Klarna Payment, If cartType is 'USER', then ShoppingContext is deleted from localStorage
   * to avoid cartType mismatch issue, while Submitting Order.
   * @date 10/31/2023 - 1:23:58 PM
   */
  /**
   * Gets the cart type based on the cart response.
   * If cart type is 'USER', clears any existing shopping context in local storage
   * to avoid cart type mismatch issues when submitting order.
   * Logs errors if unable to clear shopping context.
   * Returns numeric cart type code.
   */
  getCartType = () => {
    let cartType = cartContainer?.cartResponse?.cartType || 'USER'
    const userId = customerContainer?.userId || ''
    if (cartType === 'USER') {
      let shoppingContext = getLocalStorage('shoppingContext') || {}
      if (Object.keys(shoppingContext).length > 0) {
        try {
          clearShoppingContext()
          trackErrorInInstana({
            errorReport: `Deleted the Shopping Context from localStorage for USER cartType - UserId: ${userId}`,
            errorData: { cartType: cartType },
          })
        } catch (e) {
          trackErrorInInstana({
            errorReport: `Unable to delete the Shopping Context from localStorage for USER cartType - UserId: ${userId}`,
            errorData: { cartType: cartType },
          })
        }
      } else {
        trackErrorInInstana({
          errorReport: `ShoppingContext Node is NOT there in LocalStorage for USER cartType - UserId: ${userId}`,
          errorData: { cartType: cartType },
        })
      }
    }

    return this.getCartTypeOrValue({
      isToReturnCode: true,
      cartType: cartType || 'USER',
    })
  }
  /**
   * Gets the numeric cart type code or string cart type.
   *
   * @param {Object} options - Options object
   * @param {boolean} [options.isToReturnCode=false] - Whether to return numeric code or string type
   * @param {number} [options.typeCode] - Numeric cart type code
   * @param {string} [options.cartType] - String cart type
   * @returns {number|string} Numeric cart type code if isToReturnCode is true, otherwise returns string cart type
   */
  getCartTypeOrValue = options => {
    const { isToReturnCode = false, typeCode, cartType } = options || {}
    const cartTypes = { USER: 0, POCART: 1, MSCART: 2, EXPRESS: 3 }
    let type = ''
    if (isToReturnCode) {
      type = cartTypes[cartType]
    } else {
      for (let key in cartTypes) {
        if (typeCode == cartTypes[key]) {
          type = key
        }
      }
    }
    return type
  }

  /**
   * Initiates Klarna payment by constructing callback URLs with cart type,
   * calling addKlarnaToCart endpoint, and handling errors by displaying
   * toast message and deleting payment.
   *
   * @param {string} domainName - Base URL for Klarna redirect
   * @returns {Object} Response from addKlarnaToCart endpoint
   */
  initiateKlarnaPayment = async domainOrigin => {
    // await cartContainer.viewCart
    // @note To avoid Submit Order API CartType mismatch issue, cartType is appended to the redirection URL
    let domainName = domainOrigin
    let locale = getLocaleCodeFromUrl() || ''
    locale = locale?.toLowerCase()?.split('_')
    domainName = `${window.location.origin}/${locale?.[1]}/${locale?.[0]}${pageNames.klarnaCheckout}`

    const url = this.constructUrlForNativeApp(domainName) || domainName || ''
    let urlWithCartTypeAndProduct =
      this.constructUrlWithCartType(url) || domainName || ''

    if (isExpressCheckout()) {
      urlWithCartTypeAndProduct = this.constructUrlWithExpressDetails({
        url: urlWithCartTypeAndProduct,
      })
    }

    const failureDomainName = this.constructUrlWithStatus(
      urlWithCartTypeAndProduct,
      'klarna_status',
      'fail'
    )
    const successDomainName = this.constructUrlWithStatus(
      urlWithCartTypeAndProduct,
      'klarna_status',
      'success'
    )
    const cancelDomainName = this.constructUrlWithStatus(
      urlWithCartTypeAndProduct,
      'klarna_status',
      'cancel'
    )

    const loadParams = {
      endPointName: 'addKlarnaToCart',
      postData: {
        callbackUrlFail: failureDomainName,
        callbackUrlSuccess: successDomainName,
        callbackUrlCancel: cancelDomainName,
      },
    }

    const response = (await this.fetchResponse(loadParams)) || {}
    if (!this.isSuccessResponse(response)) {
      const errorCode = response?.code || ''
      const phoneNumberMissingErrorMessage = i18nTranslate(
        'order.phoneNumberMissingError',
        'Unable to process your order. Please provide your phone number and try again'
      )
      const errorMessage =
        errorCode === 'ECKL0003'
          ? phoneNumberMissingErrorMessage
          : i18nTranslate(
              'checkout.paymentGeneralError',
              'Sorry, we were unable to process your payment. Please try again.'
            )
      toastState.setToastMessage(errorMessage)
      await this.deletePayment()
    }
    this.klarnaInitiateResponse = response
    return response || {}
  }

  initiateKlarnaTransactionStatusAPI = async props => {
    const { confirmationCode = '', currencyCode = 'USD' } = props
    const loadParams = {
      endPointName: 'klarnaTransactionStatus',
      postData: {
        authorizationCode: confirmationCode,
        currencyCode,
      },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Deletes the payment from the cart.
   *
   * @param {boolean} loyaltyPointShow - Whether to show loyalty points after deleting payment.
   * @returns {Promise} Response from deleting payment API call.
   */
  deletePayment = async loyaltyPointShow => {
    const loadParams = {
      endPointName: 'deletePaymentsFromCart',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isLoyaltyPaid = false
    }
    await this.handleViewCart(response, loyaltyPointShow)
    checkoutContainer.activeSubmitOrderButton = true
    return response
  }

  /**
   * Deletes a payment with the given ID from the cart.
   *
   * @param {string} paymentId - The ID of the payment to delete.
   * @param {boolean} isToHideCartCall - Whether to hide the cart call after deleting.
   * @returns {Promise} The response from the delete payment API call.
   */
  deletePaymentById = async (paymentId, isToHideCartCall = false) => {
    const loadParams = {
      endPointName: 'deletePaymentByIdFromCart',
      pathParams: paymentId,
    }
    const response = await this.fetchResponse(loadParams)
    if (!isToHideCartCall) {
      await this.handleViewCart(response)
    }
    checkoutContainer.activeSubmitOrderButton = true
    return response
  }
  /**
   * Gets the payment type based on the current domain.
   *
   * Checks if the current domain includes 'klarna_status' or 'ssl_result'
   * to determine if the payment type is 'KLARNA' or 'ALIPAY' respectively.
   *
   * Returns an empty string if no payment type is matched.
   */
  getPaymentType = () => {
    try {
      if (IS_BROWSER) {
        const domain = window?.location?.href || ''
        if (domain?.includes('klarna_status')) {
          return 'KLARNA'
        }
        if (domain?.includes('ssl_result')) {
          return 'ALIPAY'
        }
        return ''
      }
    } catch (error) {
      return ''
    }
  }

  /**
   * Deletes multiple payments from the cart, except Klarna and Alipay payments.
   *
   * Gets the applied payments on the cart. Checks the payment type based on the domain.
   * If the payment type is not Klarna or Alipay, loops through the applied payments
   * and deletes any that match the predefined list of payment types to delete.
   * Finally refetches the cart after deleting the payments.
   */
  deleteMultiplePaymentById = async () => {
    let appliedPayments = cartContainer?.cartResponse?.payments || []
    const paymentType = this.getPaymentType()

    if (
      appliedPayments?.length !== 0 &&
      paymentType !== 'KLARNA' &&
      paymentType !== 'ALIPAY'
    ) {
      let paymentsToBeDeleted = [
        'STORECREDIT',
        'LOYALTY',
        'CREDITCARD',
        'DEBITCARD',
        'ALIPAY',
        'VENMO',
        'KLARNA',
        'GOOGLEPAY',
        'APPLEPAY',
      ]

      await Promise.all(
        appliedPayments?.map(async payment => {
          if (paymentsToBeDeleted?.includes(payment?.type)) {
            await this.deletePaymentById(payment.id, true)
          }
        })
      )
      await cartContainer.viewCart()
    }
  }

  /**
   * @description reviewOrder calls the reviewOrder API endpoint to review the order details before submitting the order.
   * It adds headers to show wholesale pricing if the user is a wholesale customer.
   * It also deletes any failed payments before returning the response.
   */
  reviewOrder = async () => {
    //adding showWholeSalePricing property in reviewOrder request header
    let shoppingContext
    let wholesale
    let retailSponsorId
    if (IS_BROWSER && typeof window !== 'undefined') {
      // shoppingContext = getLocalStorage('shoppingContext') || {}
      shoppingContext = getShoppingContext()
      let sponsor = getSessionStorage('sponsor') || ''
      const sponsorId = sponsor || ''
      if (Object.keys(shoppingContext).length > 0) {
        try {
          const contextValue = shoppingContext?.context
          if (
            contextValue === 'personal_offer' ||
            contextValue === 'storefront'
          ) {
            const isRetailCustomer =
              customerContainer?.profileResponse?.accounts?.accountType ===
              'Retail Customer'
            if (isRetailCustomer) {
              retailSponsorId = sponsorId
            }
          }
        } catch (e) {
          console.error(
            'JSON.parse may be an error > checkoutContainer.reviewOrder() ',
            e
          )
        }
      }
    }
    let loadParams = {
      endPointName: 'reviewOrder',
      headers: retailSponsorId && {
        'x-nuskin-sponsorid': retailSponsorId,
      },
    }
    if (wholesale === 'true') {
      if (loadParams?.headers) {
        loadParams.headers = {
          ...loadParams?.headers,
          showWholesalePricing: wholesale,
        }
      } else {
        loadParams = {
          ...loadParams,
          headers: { showWholesalePricing: 'true' },
        }
      }
    }
    const response = await this.fetchResponse(loadParams)
    if (!this.isSuccessResponse(response)) {
      const loadParams = {
        endPointName: 'deletePaymentsFromCart',
      }
      await this.fetchResponse(loadParams)
    }
    return response
  }

  /**
   * @description getCartTypeFromURL is used to get the CartType from the RedirectionURL for Klarna Payment flow
   * @date 10/31/2023 - 1:29:08 PM
   */
  getCartTypeFromURL = () => {
    let cartType = ''
    try {
      if (IS_BROWSER) {
        const domain = window?.location?.href
        const hasCartTypeInURL =
          domain?.includes('type') &&
          (domain?.includes('klarna_status') || domain?.includes('ssl_result'))

        if (hasCartTypeInURL) {
          const currentUrl = new URL(location.href)
          const type = currentUrl?.searchParams?.get('type')
          cartType = this.getCartTypeOrValue({
            typeCode: type || 0,
          })
        }
      }
    } catch (e) {
      trackErrorInInstana({
        errorReport: 'Unable to get CartType from URL',
        errorData: e,
      })
    }
    return cartType
  }

  /**
   * Submits an order with the given payload.
   * @param {Object} payload - The payload for the order.
   * @returns {Promise} - A promise that resolves to the response of the order submission.
   */
  submitOrder = async payload => {
    //getting shopping context value from localStorage for mysite
    let source = ''
    let category = ''
    let wholesale
    let retailSponsorId = ''
    let channel = ''
    let sessionIdPOCart = ''
    let pitchIdPOCart = ''
    let sapIdPOCart = ''

    //Get Choosen Payment type
    const currentPayment =
      cartContainer?.cartResponse?.payments?.[0]?.type || ''

    // let shoppingContext = getLocalStorage('shoppingContext') || {}
    let shoppingContext = getShoppingContext()
    const sponsor = getSessionStorage('sponsor') || ''
    let contextText = shoppingContext?.context || ''
    const userId = customerContainer?.userId || ''
    const browserType = getBrowserType()
    trackErrorInInstana({
      errorReport: `User and Browser Details - While Submitting Order - UserId: ${userId} - Payment Type : ${currentPayment}`,
      errorData: {
        browserName: browserType?.name || '',
        browserVersion: browserType?.version || '',
        cartTypeFromUserAPI: cartContainer?.cartResponse?.cartType || '',
      },
    })

    if (IS_BROWSER && typeof window !== 'undefined') {
      const sponsorId = sponsor || ''
      try {
        if (Object.keys(shoppingContext).length > 0) {
          try {
            if (!sponsorId) {
              trackErrorInInstana({
                errorReport: `Empty Sponsor Id: ${userId}`,
                errorData: {
                  browserName: browserType?.name || '',
                  browserVersion: browserType?.version || '',
                  sponsorId: sponsorId,
                  shoppingContext: shoppingContext,
                },
              })
            }
          } catch (e) {}
          if (contextText === 'personal_offer') {
            let grpOffer = sessionStorage.getItem('personalOffer') || {}
            if (grpOffer) {
              try {
                grpOffer = JSON.parse(grpOffer)
              } catch (e) {
                grpOffer = {}
                console.error('Personal Offer JSON Parse Error ', e)
                trackErrorInInstana({
                  errorReport: 'Personal Offer JSON Parse Error',
                  errorData: { error: e },
                })
              }
            }

            //CX16-11054 - PO cart header changes starts
            let sessionIdObj = getLocalStorage('personalOffer_v2') || ''
            sessionIdPOCart = sessionIdObj?.sessionId || ''
            pitchIdPOCart = grpOffer?.pitchID || ''
            sapIdPOCart = grpOffer?.sapId || ''
            //CX16-11054 - Ends
            let isGrpOffer =
              (grpOffer?.isGroup && grpOffer?.isGroupOffer) || false
            if (isGrpOffer) {
              category = 'GroupOffer'
              source = 'Storefront'
            } else {
              category = 'PersonalOffer'
              source = 'Storefront'
            }
          } else if (contextText === 'storefront') {
            source = 'Mysite'
            category = 'Standard'
          } else if (contextText === 'MOBILE_AFFILIATE') {
            source = 'Stela'
            category = 'Standard'
            channel = 'Mobile'
          } else if (contextText === 'MOBILE_CONSUMER') {
            source = 'Vera'
            category = 'Standard'
            channel = 'Mobile'
          } else if (contextText === 'skin_consult') {
            source = 'Vera'
            category = 'SkinConsult'
            channel = 'Mobile'
          }

          if (
            source?.toLowerCase() === 'mysite' ||
            source?.toLowerCase() === 'storefront'
          ) {
            const isRetailCustomer =
              customerContainer?.profileResponse?.accounts?.accountType ===
              'Retail Customer'
            const isGuestUser = !customerContainer?.isRegisterUser
            if (isRetailCustomer || isGuestUser) {
              retailSponsorId = sponsorId
            }
          }
        }
        // else {
        //   category = 'standard'
        //   source = 'storefront'
        // }
      } catch (e) {
        console.log('Error found at cart type in header request >>> ', e)
        trackErrorInInstana({
          errorReport: 'Fn Submit order',
          errorData: e,
        })
      }
    }
    let contextHeaders = {}

    if (
      (contextText && contextText == 'MOBILE_CONSUMER') ||
      contextText === 'MOBILE_AFFILIATE' ||
      contextText === 'skin_consult'
    ) {
      contextHeaders = {
        'x-nuskin-source': source || '',
        'x-nuskin-category': category || '',
        'x-nuskin-channel': channel || '',
      }
    } else if (
      contextText &&
      (contextText == 'personal_offer' || contextText == 'storefront')
    ) {
      contextHeaders = {
        'x-nuskin-source': source || '',
        'x-nuskin-category': category || '',
      }

      if (pitchIdPOCart) {
        contextHeaders['x-nuskin-pitchId'] = pitchIdPOCart
      }

      if (sessionIdPOCart) {
        contextHeaders['x-nuskin-sessionId'] = sessionIdPOCart
      }
    }

    const enabledGuestForWeb = isGuestCheckoutEnabledForWeb()
    if (enabledGuestForWeb && !checkMysiteOrPersonalOffer()) {
      retailSponsorId = accountsContainer?.getUserTypeSponsorId()
      contextHeaders = {
        'x-nuskin-source': 'Storefront',
      }
    }

    const cartType = this.getCartTypeFromURL() || ''

    let loadParams = {
      endPointName: 'submitOrder',
      headers: contextHeaders,
    }
    if (cartType) {
      // @note For Klarna Payment, to avoid the cartType mismatch issue,
      // once redirected from paymentSite, CartType is detected from the RedirectionURL and appended to the SubmitOrder API

      trackErrorInInstana({
        errorReport: `Submit Order API - CartType from Redirection URL - UserId: ${userId} - Payment Type: ${currentPayment}`,
        errorData: {
          cartTypeInURL: cartType,
        },
      })
      loadParams.cartType = cartType
    }
    let shoppingContextFromLocalStorage =
      getLocalStorage('shoppingContext') || {}
    if (
      cartType === 'USER' &&
      Object.keys(shoppingContextFromLocalStorage)?.length > 0
    ) {
      // @note If CartType is 'USER', then we should not have ShoppingContext in localStorage
      // added log to track the mismatch scenario
      trackErrorInInstana({
        errorReport: `Cart Type mismatches with Local Storage ShoppingContext - UserId: ${userId} - Payment Type: ${currentPayment}`,
        errorData: {
          cartTypeInURL: cartType,
          hasShoppingContextInStorage:
            shoppingContextFromLocalStorage?.context || {},
        },
      })
      clearShoppingContext()
    }

    if (sapIdPOCart || retailSponsorId) {
      loadParams.headers = {
        ...loadParams?.headers,
        'x-nuskin-sponsorid': sapIdPOCart || retailSponsorId,
      }
    }
    if (wholesale === 'true') {
      loadParams.headers = {
        ...loadParams?.headers,
        showWholesalePricing: 'true',
      }
    }

    const existingPayments = cartContainer?.cartResponse?.payments || []
    const isCreditOrDebitCardPayment =
      existingPayments?.some(payment => {
        const type = payment?.type?.toLowerCase()
        return type === 'creditcard' || type === 'debitcard'
      }) || false
    const isVenmoPayment =
      existingPayments?.some(payment => {
        const type = payment?.type?.toLowerCase()
        return type === 'venmo'
      }) || false
    const isGooglePayPayment =
      existingPayments?.some(payment => {
        const type = payment?.type?.toLowerCase()
        return type === 'googlepay'
      }) || false
    const isApplePayPayment =
      existingPayments?.some(payment => {
        const type = payment?.type?.toLowerCase()
        return type === 'applepay'
      }) || false

    if (typeof payload?.properties !== 'undefined') {
      if (isVenmoPayment || isApplePayPayment || isGooglePayPayment) {
        loadParams.postData = payload || {}
      } else {
        loadParams.postData = {
          properties: {
            nonceToken: payload?.properties?.nonceToken || '',
          },
        }
      }
    } else {
      if (payload && payload !== -1) {
        loadParams.postData = { payload }
      }
    }

    if (this.giftPayLoad) {
      if (loadParams?.postData?.properties) {
        loadParams.postData.properties.enableGiftReceipt = true
        loadParams.postData.properties.customGiftMessage = this.giftMessage
      } else if (loadParams?.postData) {
        loadParams.postData.properties = {
          enableGiftReceipt: true,
          customGiftMessage: this.giftMessage,
        }
      } else {
        loadParams.postData = {
          properties: {
            enableGiftReceipt: true,
            customGiftMessage: this.giftMessage,
          },
        }
      }
    }
    const { dropShippingEnabled = 'false' } =
      cartContainer?.cartResponse?.properties || {}

    if (this.dropShippingPayLoad && dropShippingEnabled === 'true') {
      if (loadParams?.postData?.properties) {
        loadParams.postData.properties.dropShipping = true
      } else if (loadParams?.postData) {
        loadParams.postData.properties = {
          dropShipping: true,
        }
      } else {
        loadParams.postData = {
          properties: {
            dropShipping: true,
          },
        }
      }
    }

    let response = ''
    //Passing device details to order only for GPAY, APPLEPAY and Card Payments - Ref : CX17-11666
    const isToPatchDeviceData =
      isGooglePayPayment || isApplePayPayment || isCreditOrDebitCardPayment
    if (IS_BROWSER && window?._bcn != undefined && isToPatchDeviceData) {
      const ipAddress = await getBrowserIpAddress()
      const deviceToken = window?._bcn?.getToken()
      const deviceId = window?._bcn?.dvc?.getTID()
      if (loadParams?.postData?.properties) {
        loadParams.postData.properties.deviceToken = deviceToken
        loadParams.postData.properties.deviceId = deviceId
        loadParams.postData.properties.ipAddress = ipAddress
      } else if (loadParams?.postData) {
        loadParams.postData.properties = {
          deviceToken: deviceToken,
          deviceId: deviceId,
          ipAddress: ipAddress,
        }
      } else {
        loadParams.postData = {
          properties: {
            deviceToken: deviceToken,
            deviceId: deviceId,
            ipAddress: ipAddress,
          },
        }
      }

      response = await this.fetchResponse(loadParams)
    } else {
      response = await this.fetchResponse(loadParams)
    }
    if (this.isSuccessResponse(response)) {
      if (isExpressCheckout()) {
        this.showExpressCheckoutDrawer = false
        this.showExpressCheckoutModalBody = false
        this.renderExpressPDPCart = false
        this.expressPaymentAdded = false
      }
      // await cartContainer.viewCart()
      this.orderId = response && response.orderId
      setSessionStorage('orderID', this.orderId)
      deleteFromSessionStorage('utmInfo')
      deleteFromSessionStorage('vipCheckboxStatus')
      deleteFromSessionStorage('smsOptInStatus')
      deleteFromSessionStorage('emailOptInStatus')
      const cartData = cartContainer?.cartResponse || {}
      const customerEmail = cartData?.deliveryDetails?.address?.email || ''
      const customerType = customerContainer.isRegisterUser
        ? customerContainer?.profileResponse?.accounts?.accountType
        : 'Guest User'
      const isFromUTTEvent = true
      const { enableEQFastFollow = false } = APPConfig?.getAppConfig() || false
      const checkEQFastFollow = convertToBoolean(enableEQFastFollow)
        ? !checkMysiteOrPersonalOffer()
        : true
      if (checkEQFastFollow) {
        trackUTTConversion({
          orderId: this.orderId,
          customProfileId:
            customerContainer?.profileResponse?.accounts?.referenceAttribute ||
            '',
          customerId: cartData?.userId || '',
          customerEmail: customerEmail || '',
          customerStatus: customerType,
          currencyCode: cartData?.currencyCode || '',
          orderPromoCode: cartData?.promotionDetails?.codes?.[0]?.code || '',
          orderDiscount:
            cartData?.currencySign + cartData?.value?.cartDiscount || '',
          items: getCheckoutProduct(cartData?.items, '', isFromUTTEvent),
        })
      }
      if (this.giftMessage) {
        trackCheckoutGiftReceipt({
          eventType: 'select_gift',
          message: this.giftMessage,
        })
      }
      if (!customerContainer.isRegisterUser) {
        await customerContainer.logout(true)
        await customerContainer.getProfile()
      }
    } else {
      const errorMessage =
        response?.errorMessage || response?.message || response?.status || ''
      trackErrorInInstana({
        errorReport: `Submit order API Failed for USER - ${userId} - Payment Type - ${currentPayment}`,
        errorData: { errorMessage, response: response },
      })
      // Toast message is handled in Submit order components
      // toastState.setToastMessage(errorMessage, false)
    }

    trackErrorInInstana({
      errorReport: `Submit order tracking - ${this.orderId} - Payment Type: ${currentPayment}`,
      errorData: loadParams,
    })

    trackErrorInInstana({
      errorReport: `Submit order context - ${contextText} - Payment Type: ${currentPayment}`,
      errorData: loadParams,
    })

    return response
  }

  //Loyality Payment POST Call
  /**
   * Adds loyalty points payment to cart.
   *
   * @param {number} paymentData - Loyalty points to pay with
   * @returns {Promise} Response from API call
   */
  addLoyaltyToCart = async paymentData => {
    let payment = parseFloat(paymentData)
    //const cardType ='LOYALTY'
    //const valueType =cardType.toLowerCase() === 'loyalty' ? 'CASH' : 'CURRENCY'
    const loadParams = {
      endPointName: 'addPaymentToCart',
      postData: [
        {
          type: 'LOYALTY',
          valueType: 'POINTS',
          value: payment,
          amount: payment,
          option: {
            addPaymentToProfile: false,
            useDefaultPayment: false,
            setAsDefaultPaymentInProfile: false,
          },
        },
      ],
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isLoyaltyPaid = true
    }
    this.handleViewCart(response)
    this.paymentRefId = response
    return response
  }

  /**
   * Adds a promotion/coupon code to the cart.
   *
   * @param {string} couponCode - The coupon code to add
   * @returns {Promise} The response from the API call
   */
  addPromo = async couponCode => {
    const loadParams = {
      endPointName: 'addPromo',
      postData: [
        {
          code: couponCode,
        },
      ],
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isCouponPaid = true
    }
    await this.handleViewCart(response)
    this.appliedCouponCodes =
      cartContainer?.cartResponse?.promotionDetails?.codes?.map(
        promo => promo?.code
      ) || []
    return response
  }
  /**
   * Deletes a promotion/coupon code from the cart.
   *
   * @param {string} promoCode - The coupon code to delete
   * @returns {Promise} The response from the API call
   */
  deletePromo = async promoCode => {
    const loadParams = {
      endPointName: 'deletePromo',
      pathParams: `${promoCode}`,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isCouponPaid = false
    }
    await this.handleViewCart(response)
    this.appliedCouponCodes =
      cartContainer?.cartResponse?.promotionDetails?.codes?.map(
        promo => promo?.code
      ) || []
    return response
  }
  /**
   * Deletes the loyalty discount reward from the cart.
   *
   * Makes an API call to delete any applied loyalty rewards.
   * Updates cart and loyalty paid state on success.
   *
   * @returns {Promise<Object>} The API response object.
   */
  deleteDiscountReward = async () => {
    const loadParams = {
      endPointName: 'deleteLoyaltyRewardsFromCart',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isLoyaltyPaid = false
    }
    this.handleViewCart(response)
    return response
  }
  //Cart Patch call if discountModelRewardTypes available
  /**
   * Adds a loyalty discount reward to the cart.
   *
   * Makes an API call to apply any available loyalty discount rewards.
   * Updates cart and loyalty paid state on success.
   *
   * @param {Object} discountData - Object containing loyalty point info
   * @returns {Promise} The API response
   */
  addDiscountReward = async discountData => {
    let payment = parseFloat(discountData?.loyaltyPoint || 0)

    const loadParams = {
      endPointName: 'updateBagAttributes',
      postData: {
        loyaltyDetails: [
          {
            value: payment,
            points: payment,
            type: 'LOYALTY',
            valueType: 'POINTS',
          },
        ],
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isLoyaltyPaid = true
    }
    this.handleViewCart(response)
    return response
  }
  /**
   * Updates the cost center ID in the cart.
   *
   * Makes an API call to update the cost center ID in the cart.
   * Updates cart response with new cost center ID on success.
   * Shows error toast message on failure.
   *
   * @param {string} costCenterId - The cost center ID to update in the cart
   * @returns {Promise} The API response
   */
  updateCostCenterToCart = async costCenterId => {
    const loadParams = {
      endPointName: 'updateBagAttributes',
      postData: { costCenterId },
    }
    const response = await this.fetchResponse(loadParams)
    if (checkoutContainer.isSuccessResponse(response)) {
      cartContainer.cartResponse.costCenterId = costCenterId
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.costCenterUpdateError',
          'Unable to update the cost center'
        ),
        false
      )
    }
    return response
  }
  /**
   * Validates the cost center for the current cart.
   *
   * Constructs API params to validate if the cost center can be used for the cart total.
   * Calls API endpoint to validate cost center.
   *
   * @returns {Promise} The API response from the validation call.
   */
  validateCostCenter = async () => {
    const bagValue = cartContainer?.cartResponse?.paymentValue?.bagValue || ''
    const costCenterId = cartContainer?.cartResponse?.costCenterId || ''

    const loadParams = {
      endPointName: 'validateCostCenter',
      pathParams: `${customerContainer.accountId}/buyers/costcentres/${costCenterId}/validateOrderAmount`,
      queryParam: { amount: bagValue },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Gets all cost centers for the logged in customer.
   *
   * If the customer is registered, gets the account ID and calls the API endpoint to get all cost centers for that account.
   *
   * Returns the API response with the cost centers.
   * If no customer is logged in, returns empty string.
   */
  getAllCostCenter = async () => {
    if (customerContainer.isRegisterUser) {
      const accountId = await this.getAccountId()
      const loadParams = {
        endPointName: 'getAllCostCenter',
        pathParams: `${accountId}/buyers/costcentres`,
      }
      const response = await this.fetchResponse(loadParams)
      return response
    }
    return ''
  }
  /**
   * Fetches shipping method disclaimer content from Contentful.
   *
   * Configures the API request to get entries from the
   * shippingMethodDisclaimers content type. Enables preview mode
   * if configured in the app config.
   *
   * Saves the response to the shippingMethodDisclaimers
   * property on the CheckoutContainer instance.
   */
  getShippingMethodDisclaimers = async () => {
    const { enableContentfulPreview } = getAppConfig()
    const loadParams = {
      endPointName: 'getContentfulFromEntryId',
      queryParams: {
        limit: 100,
        contentType: 'shippingMethodDisclaimers',
        isPreview: enableContentfulPreview,
      },
    }
    this.shippingMethodDisclaimers = await this.fetchResponse(loadParams)
  }

  getGlobalPaymentOptions = async () => {
    this.isGlobalPaymentCallInProgress = true
    const loadParams = {
      endPointName: 'getGlobalPaymentOptions',
    }
    const response = await this.fetchResponse(loadParams)
    trackErrorInInstana({
      errorReport: 'Global Payment - GET API Response',
      errorData: response,
    })
    this.globalPaymentResponse = response
    this.isGlobalPaymentCallInProgress = false
    return response
  }

  initiateGlobalPayment = async (domainName = '') => {
    const loadParams = {
      endPointName: 'submitOrderForGlobalPayment',
      postData: {
        successCallbackUrl: this.constructUrlWithStatus(
          domainName,
          'gps_status',
          'success'
        ),
        failedCallbackUrl: this.constructUrlWithStatus(
          domainName,
          'gps_status',
          'fail'
        ),
        pendingCallbackUrl: this.constructUrlWithStatus(
          domainName,
          'gps_status',
          'pending'
        ),
      },
    }
    const response = (await this.fetchResponse(loadParams)) || {}
    trackErrorInInstana({
      errorReport: 'Global Payment - Submit API Response',
      errorData: response,
    })
    if (!this.isSuccessResponse(response)) {
      const errorMessage =
        response?.message ||
        i18nTranslate(
          'order.generalError',
          'Sorry,we are unable to place order. Please try again.'
        )
      toastState.setToastMessage(errorMessage)
      await this.deletePayment()
    }
    return response || {}
  }

  /**
   * Loads the Google Pay client script if it is not already loaded and the Google Pay payment is enabled.
   */
  loadGooglePayClientScript = () => {
    try {
      if (IS_BROWSER) {
        const isGooglePayPaymentEnabled =
          APPConfig.getAppConfig()?.enableGooglePayPayment === 'true'
        const isScriptNotAlreadyLoaded = !document.getElementById(
          'googlePayPaymentScript'
        )
        if (isScriptNotAlreadyLoaded && isGooglePayPaymentEnabled) {
          const googlePayClientScript =
            APPConfig.getAppConfig()?.googlePayClientScript || ''
          const script = document.createElement('script')
          script.id = 'googlePayPaymentScript'
          script.src = googlePayClientScript
          document.body.appendChild(script)
        } else {
          console.log('Google Pay Script already loaded or payment disabled')
        }
      }
    } catch (e) {
      console.log('Error loading Google Pay client script', e)
    }
  }

  /**
   * Loads the Google Pay BrainTree script if it is enabled in the app configuration and not already loaded.
   */
  loadGooglePayBrainTreeScript = () => {
    try {
      if (IS_BROWSER) {
        const isGooglePayPaymentEnabled =
          APPConfig.getAppConfig()?.enableGooglePayPayment === 'true'
        const isScriptNotAlreadyLoaded = !document.getElementById(
          'googlePayBrainTreeScript'
        )
        if (isGooglePayPaymentEnabled && isScriptNotAlreadyLoaded) {
          const googlePayBrainTreeScript =
            APPConfig.getAppConfig()?.googlePayBrainTreeScript || ''
          const script = document.createElement('script')
          script.id = 'googlePayBrainTreeScript'
          script.src = googlePayBrainTreeScript
          document.body.appendChild(script)
        } else {
          console.log(
            'Brain Tree Google Pay Client Script already loaded or Payment disabled'
          )
        }
      }
    } catch (e) {
      console.log('Error loading Brain Tree Google Pay client script', e)
    }
  }

  hideCouponCodeForPOAndMysite = () => {
    let hideCouponCode = false
    try {
      if (IS_BROWSER) {
        const shoppingContext = getShoppingContext()
        if (Object.keys(shoppingContext)?.length > 0) {
          const contextValue = shoppingContext?.context || ''
          /**
           * @description Hide coupon code in Po site if promotion is added to cart [CX16-10005]
           * @date 9/29/2023 - 2:27:06 PM
           */
          if (contextValue === 'personal_offer') {
            // const personalOfferCode = getSessionStorage('personalOffer') || {}
            const hideCouponCodeInPOCheckout = !(
              APPConfig?.getAppConfig()?.enableCouponCodeForPoSite == 'true'
            )
            const promotionCheck = convertToBoolean(
              cartContainer.cartResponse?.properties
                ?.isExcludedPromoCodeAvailInCart || false
            )

            // const promotions =
            //   (cartContainer.cartResponse?.promotionDetails &&
            //     cartContainer.cartResponse?.promotionDetails?.codes) ||
            //   []
            // const promotionCheck =
            //   promotions?.some(
            //     promotion =>
            //       promotion?.code === personalOfferCode?.discount?.code
            //   ) || false

            if (hideCouponCodeInPOCheckout) {
              hideCouponCode = true
            } else {
              hideCouponCode = promotionCheck
            }
          }
          /**
           * @description Hide Coupon code in Mysite if the user type doesn't match any value in enableCouponCodeForMySite of appConfig[CX16-9759]
           * @date 9/29/2023 - 2:29:55 PM
           */
          if (contextValue === 'storefront') {
            const enableCouponCodeForMySite =
              APPConfig?.getAppConfig()?.enableCouponCodeForMySite || []
            const accType =
              customerContainer?.profileResponse?.accounts?.accountType || ''
            if (!customerContainer.isRegisterUser) {
              if (!enableCouponCodeForMySite?.includes('Guest')) {
                hideCouponCode = true
              }
            } else if (customerContainer.isRegisterUser) {
              if (!enableCouponCodeForMySite?.includes(accType)) {
                hideCouponCode = true
              }
            }
          }
        } else {
          hideCouponCode = false
        }
      }
    } catch (e) {
      console.error('JSON.parse may be an error CheckoutView2', e)
    }
    return hideCouponCode
  }
}

const checkoutContainer = new CheckoutContainer()

export { CheckoutContainer, checkoutContainer }
export default checkoutContainer
