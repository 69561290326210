import React from 'react'
import { i18nTranslate } from 'src/utils'
import isArray from 'lodash/isArray'
import {
  APPConfig,
  getLocalStorage,
  MYSITE_URL,
  getSessionStorage,
  deleteFromLocalStorage,
  setSessionStorage,
  setCookie,
  getCookie,
  deleteFromSessionStorage,
} from 'config/appConfig'
import { localeList } from 'src/App/localeList'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { application } from './application'
import { pageNames } from 'src/routes/pathParams'
import { storeContainer } from 'src/models/Store'

const IS_BROWSER = typeof window === 'object'

/**
 * Converts a value to a boolean.
 *
 * @param {*} booleanValue - The value to convert.
 * @returns {boolean} The converted boolean value.
 */
function convertToBoolean(booleanValue) {
  try {
    if (booleanValue) {
      return !!JSON.parse(String(booleanValue).toLowerCase())
    }
  } catch (e) {
    return false
  }
  return false
}

/**
 * Observes an element using IntersectionObserver API.
 * Sets up observer with given options and callback.
 * Starts observing the element matching the selector.
 */
function observeElement(element, callback) {
  let observer = []
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.01,
  }
  const startObserve = () => {
    observer = new IntersectionObserver(callback, options)
    const target = document.querySelector(element)
    observer.observe(target)
  }

  startObserve()
}

/**
 * Gets the currency code for the given locale.
 *
 * Checks localStorage for a saved currency code.
 * Falls back to looking up the locale in the locale list to get the currency code.
 * Finally defaults to 'USD' if no currency code is found.
 */
function getCurrency(locale) {
  // const selectedLocale = storeContainer.selectedLocaleList
  //const localeArray = selectedLocale.length ? selectedLocale : localeList()
  let currency = getLocalStorage('currency')
  if (currency) {
    return currency
  }

  /**
   * @todo
   * !!! local list should be dynamic
   * !!! NOT from fixtures
   */
  const localeArray = localeList()
  const currentLocale = localeArray.find(
    localeValue => localeValue.value === locale
  )

  // For LATAM Markets - Currency Code is taken from Active Store API Response - defaultCurrency Node
  let currencyCodeFromStoreResponse =
    storeContainer?.activeStoreResponse?.defaultCurrency || ''

  if (getLocalStorage('currency')) {
    return getLocalStorage('currency')
  } else if (currentLocale && currentLocale.currency) {
    return currentLocale.currency
  } else if (currencyCodeFromStoreResponse) {
    return currencyCodeFromStoreResponse
  }
  return 'USD'
}

/**
 * Formats a price value with a currency code and locale.
 *
 * Takes in a price and currency code.
 * Gets the locale from the default app locale.
 * Formats the price into a localized currency string using Intl.NumberFormat.
 * Falls back to a default currency code if formatting fails.
 */
function currencyFormat(props) {
  const { currency, price } = props
  const { defaultLocale = '' } = APPConfig.getAppConfig() || {}
  let locale = defaultLocale.replace('_', '-')?.replace(/[^a-zA-Z0-9-]/, '')
  if (locale.includes('-US')) {
    locale = 'en-US'
    // CX121-2874
  } else if (locale.includes('-CA')) {
    locale = 'en-CA'
    //CX121-4864
  }
  const currencyCode = currency ? currency : getCurrency(locale)

  /**
   * @todo
   * !!! code shorting required
   * !!! as its closely duplicate code
   */
  try {
    const finalValue = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    }).format(price)
    return finalValue
  } catch (e) {
    console.warn('wrong currency code')
    const staticCurrency = getCurrency(locale)
    const finalValue = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: staticCurrency,
    }).format(price)
    return finalValue
  }
}

/**
 * Returns the name of the attribute element with the given propName
 * from the given propList array.
 *
 * @param {Object[]} propList - The array of attribute elements
 * @param {string} propName - The name of the attribute to find
 * @returns {string} The name of the matching attribute element
 */
function getProductPDPTitle(propList, propName) {
  return (
    propList?.find(element => element?.attributeId?.toLowerCase() == propName)
      ?.name || ''
  )
}

/**
 * Checks if a video URL is a YouTube video URL.
 *
 * Takes in a video URL string.
 * Uses a regular expression to match known YouTube URL patterns.
 * Extracts the video ID if a match is found.
 * Returns true if the URL matches a YouTube pattern, false otherwise.
 */
function isYouTubeVideo(videoUrl = '') {
  if (videoUrl != '') {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = videoUrl && videoUrl.match(regExp)
    let videoId = match?.[2].length === 11 ? match[2] : null
    if (videoUrl && videoUrl.match(regExp)) {
      return true
    }
  }
  return false
}

/**
 * Renders appropriate media component based on the mediaProps passed in.
 *
 * Checks if url is a YouTube video, and renders iframe if so.
 * Checks if url matches video regex, and renders video tag if so.
 * Checks if url matches image regex, and renders img tag if so.
 * Provides default values for mediaProps.
 * Returns empty fragment if no match.
 */
function getMedia(mediaProps) {
  const {
    url = '',
    alt = '',
    index = 0,
    isToShowControls = true,
    thumbnail = '',
  } = mediaProps
  const videoUrlRegEx = /^https?:\/\/.*\/.*\.(mp4|webm|ogg)\??.*$/gim
  const imageUrlRegEx = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i
  if (isYouTubeVideo(url)) {
    return (
      <iframe
        tabIndex={index}
        width="100%"
        className="media"
        src={`https://www.youtube.com/embed/${videoId}?controls=${
          isToShowControls ? '0' : '1'
        }`}
      />
    )
  } else if (url.match(videoUrlRegEx)) {
    return (
      <video
        tabIndex={index}
        className="sustainability-video"
        width="100%"
        controls={isToShowControls}
        poster={thumbnail}>
        <source src={url} />
      </video>
    )
  } else if (url.match(imageUrlRegEx)) {
    return (
      <img
        tabIndex={index}
        width="100%"
        controls={isToShowControls}
        src={url}
        alt={alt || ''}
      />
    )
  }
  return <></>
}

/**
 * Returns localized strings for SKU attributes.
 *
 * Uses i18n library to look up translations for common SKU attribute names like 'Size',
 * 'Color', etc. Provides fallback default strings if translation is missing.
 *
 * Intended for use in rendering SKU selector components to show localized labels.
 */
function getLocaleString() {
  return {
    size: i18nTranslate('sku.size', 'Size'),
    color: i18nTranslate('sku.color', 'Color'),
    fit: i18nTranslate('sku.fit', 'Fit'),
    fittype: i18nTranslate('sku.fit', 'Fit'),
    skintype: i18nTranslate('sku.skintype', 'Select Skin Type'),
    variantLabel: i18nTranslate('sku.variantLabel', 'Variant'),
  }
}

/**
 * Validates if the given string is a valid URL.
 *
 * @param {string} string - The string to validate.
 * @returns {boolean} True if the string is a valid URL, false otherwise.
 */
function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Returns responsive value based on application viewport size.
 *
 * Checks `application.isDesktopOrLarger`, `application.isTabletOrLarger`
 * to determine which value to return for desktop, tablet or mobile.
 *
 * @param {*} desktop - Value for desktop viewport.
 * @param {*} tablet - Value for tablet viewport.
 * @param {*} mobile - Value for mobile viewport.
 * @returns {*} Responsive value for current viewport.
 */
function setResponsiveValues(desktop, tablet, mobile) {
  return application.isDesktopOrLarger
    ? desktop
    : application.isTabletOrLarger
    ? tablet
    : mobile
}

/**
 * Tries to parse a JSON string and return the parsed value.
 * If parsing fails, returns the provided default value if defined,
 * otherwise returns the original data.
 *
 * @param {string} data - The JSON string to parse
 * @param {*} [defaultValue] - The default value to return on failure
 * @returns {*} The parsed JSON value or the default value
 */
function tryJSONParse(data, defaultValue) {
  try {
    const value = JSON.parse(data)
    return value
  } catch (e) {
    return defaultValue !== undefined ? defaultValue : data
  }
}

/**
 * Tries to parse a value as an integer, returning a default value on failure.
 *
 * @param {*} data - The data to parse
 * @param {*} defaultValue - The value to return on failure
 * @returns The parsed integer, or the default value on failure
 */
function safeParseInt(data, defaultValue) {
  try {
    const value = parseInt(data)
    return !isNaN(value)
      ? value
      : defaultValue !== undefined
      ? defaultValue
      : data
  } catch (e) {
    return defaultValue !== undefined ? defaultValue : data
  }
}

/**
 * Tries to decode a URI encoded string, catching and ignoring any errors.
 *
 * @param {string} text - The URI encoded string to decode
 * @returns {string} The decoded string, or the original string if decoding failed
 */
function tryDecode(text) {
  try {
    const value = decodeURI(text)
    return value
  } catch (e) {
    return text
  }
}

/**
 * Encodes the given search term by URI encoding it and escaping any % and . characters.
 * This allows the term to be safely used in a URL search query.
 * @note
 * React router search encode related containing characters % and .
 *
 * @param {string} search - The search term to encode
 * @returns {string} The encoded search term
 */
function encodeSearchTerm(search) {
  return encodeURI(search.replace(/%/g, '%25').replace(/\./g, '%252E'))
}

/**
 * Decodes a search term that was encoded for use in a URL search query.
 *
 * Replaces any %2E sequences with . characters.
 * @note
 * React router search encode related containing characters % and .
 *
 * @param {string} search - The encoded search term
 * @returns {string} The decoded search term
 */
function decodeSearchTerm(search) {
  return tryDecode(search).replace(/%2E/g, '.')
}

/**
 * Gets the locale path parameter from the default locale in the active app config.
 * Splits the locale on '_', reverses it, joins with '/' and converts to lowercase.
 * @note
 * Extracting locale from config and returning it in reversed path params format. .
 *
 * @returns {string} The locale path parameter
 */
function getLocalePathParam() {
  const locale = APPConfig.getActiveAppConfig().defaultLocale
  return locale?.split('_')?.reverse()?.join('/')?.toLowerCase()
}

/**
 * Replaces the locale placeholder in a URL with the actual locale code.
 *
 * Checks if the given URL contains the '<locale>' placeholder.
 * If so, determines the current locale code and replaces the placeholder with it.
 *
 * Falls back to 'en_US' if no locale can be determined.
 * Special cases 'zh_CN' to 'zh_CA'.
 *
 * @param {Object} options - The options object
 * @param {string} options.url - The URL to replace the locale in
 * @returns {string} The URL with the locale placeholder replaced
 */
function replaceLocaleInURL(options) {
  let { url } = options
  const localeTemplate = '<locale>'
  if (url?.includes(localeTemplate)) {
    let currentLocale = getLocalePathParam()
    let locale
    currentLocale = currentLocale && currentLocale?.split('/')
    if (isArray(currentLocale) && currentLocale?.length > 0) {
      locale = `${currentLocale?.[1]}_${currentLocale?.[0]?.toUpperCase()}`
    } else {
      // fallback to en_US for safety
      locale = 'en_US'
    }
    if (locale === 'zh_CN') {
      locale = 'zh_CA'
    }
    return url?.replace(localeTemplate, locale)
  }
  return url
}

// Replace this function with server config file
/**
 * Checks if a given link matches any of the internal page URLs.
 *
 * @param {Object} options - Options object
 * @param {string} options.link - Link to check
 * @param {string} options.pageUrl - Current page URL
 * @returns {Object}
 * @returns {boolean} return.isInternalPage - Whether link is an internal page
 * @returns {string} return.urlWithoutLocale - Link with locale removed
 */
function isPageFound(options) {
  const appConfigFromJSON = APPConfig.getConfigFromJSON()
  const { link = '', pageUrl = '' } = options || {}

  const currentPageUrl = (IS_BROWSER ? location.href : pageUrl) || ''

  const curLocaleFromUrl = getLocaleCodeFromUrl({
    url: currentPageUrl,
    at: 'pathParam',
    defaultLocale: appConfigFromJSON?.defaultLocale,
    isReverseType: true,
  })
  const localeInUrl = curLocaleFromUrl
    ?.split('_')
    ?.reverse()
    ?.join('/')
    ?.toLowerCase()

  let urlWithoutLocale = link?.replace(`/${localeInUrl}`, '')

  urlWithoutLocale = urlWithoutLocale ? urlWithoutLocale : '/'

  const pageUrls = Object.values(pageNames)
  const isInternalPage = pageUrls.some(pageUrl => {
    if (pageUrl == urlWithoutLocale) {
      return true
    }
    if (pageUrl != '/') {
      return urlWithoutLocale.includes(pageUrl)
    }
  })

  return {
    isInternalPage,
    urlWithoutLocale,
  }
}

/**
 * Checks if a given link matches any internal page URL and returns
 * formatted link details.
 *
 * Removes locale from link if present. Checks if resulting link matches
 * any internal page URL without locale.
 *
 * Returns object with formatted link and boolean indicating if link is
 * internal or third party.
 */
function renderLinkCheck(href = '', originalUrl) {
  let storeFrontRoutes = Object?.values(pageNames) || []
  const index = storeFrontRoutes?.indexOf('/')
  storeFrontRoutes?.splice(index, 1)
  const activeEnvironment = APPConfig?.getLocalEnv() || ''

  const curLocale = APPConfig?.getDefaultProps?.()?.defaultLocale || 'en_US'
  const [language, activeCountry] = curLocale.includes('_')
    ? curLocale.split('_')
    : curLocale.split('-')

  // Removed this since we are not going use *.skavcommerce domain anymore
  // const baseurl = 'https://www.nuskin.com'
  let hreftext = ''

  let pageDetails
  try {
    pageDetails = isPageFound({ link: href, pageUrl: originalUrl })
  } catch (e) {
    console.log('isInternalPage ', e)
  }

  let isThirdPartyPage = true
  if (pageDetails?.isInternalPage) {
    hreftext = pageDetails?.urlWithoutLocale || ''
    isThirdPartyPage = false
  } else {
    hreftext = href
  }

  return { link: hreftext, isThirdPartyPage }
}

/**
 * Gets the URL for the MySite shopping cart page.
 *
 * Checks if the user is in a MySite or Personal Offer context via `checkMysiteOrPersonalOffer()`.
 *
 * For MySite, gets the MySite domain from sessionStorage and builds the cart URL using the MYSITE_REDIRECT config value.
 *
 * For Personal Offer, gets the user's landingPageURL from sessionStorage.
 *
 * Returns the cart URL string for either MySite or Personal Offer based on context.
 */
function getMysiteCartUrl() {
  // const shoppingContext = getLocalStorage('shoppingContext') || {}
  const contextValue = checkMysiteOrPersonalOffer()
  if (IS_BROWSER) {
    // const activeLocale = getLocalStorage('locale')
    const activeLocale = getLocaleCodeFromUrl({
      defaultLocale: 'en_US',
      isReverseType: true,
    })
    if (contextValue == 'personal_offer') {
      let userDetails = sessionStorage.getItem('personalOffer') || {}
      if (userDetails) {
        userDetails = JSON.parse(userDetails)
      }
      return userDetails?.landingPageURL || ''
    }
    if (contextValue == 'storefront') {
      let mySiteDomain
      let urlDetails = sessionStorage.getItem('storefront') || {}
      if (urlDetails) {
        urlDetails = JSON.parse(urlDetails)
      }
      if (Object.keys(urlDetails?.storefront).length > 0) {
        mySiteDomain = Object.keys(urlDetails?.storefront)
        mySiteDomain = mySiteDomain?.[0] || ''
      }
      const MYSITE_LOGO_REDIRECT =
        APPConfig?.getAppConfig()?.MYSITE_REDIRECT || ''
      return MYSITE_LOGO_REDIRECT.replace('<%domain%>', mySiteDomain)
    }
  }
}

/**
 * Returns the MySite URL with the active locale replaced.
 *
 * Checks if a custom client domain is configured and uses that if available,
 * otherwise falls back to the default MYSITE_URL.
 *
 * Replaces the <%LOCALE%> placeholder in the URL with the locale code
 * from the current URL.
 *
 * Used to generate MySite URLs dynamically with the locale needed for the current user.
 */
function getMySiteUrl() {
  if (IS_BROWSER) {
    // const activeLocale = getLocalStorage('locale')
    const activeLocale = getLocaleCodeFromUrl({
      defaultLocale: 'en_US',
      isReverseType: true,
    })
    const { clientDomainName = '' } = APPConfig?.getAppConfig() || {}
    const updatedMySiteUrl =
      clientDomainName !== ''
        ? `https://${clientDomainName}` + MYSITE_URL
        : MYSITE_URL
    return updatedMySiteUrl?.replace('<%LOCALE%>', activeLocale) || ''
  }
}

/**
 * Checks if a URL is absolute based on the presence of a protocol or leading slashes.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} True if the URL is absolute, false otherwise.
 */
function isUrlAbsolute(url) {
  return url.indexOf('://') > 0 || url.indexOf('//') === 0
}

/**
 * Retrieves the IP address of the browser.
 *
 * Tries to make a request to get the IP address based on the current domain.
 * Falls back to non-CORS request if SSR is disabled.
 *
 * Returns the IP address string if successful, empty string if not.
 */
async function getBrowserIpAddress() {
  // In local we are disabling CORS so enabling proxy only for PDN
  let ipAddress = ''
  try {
    ipAddress = await fetch('https://api.ipify.org/?format=json')
    ipAddress = await ipAddress.json()
  } catch (err) {
    console.error('getBrowserIpAddress in commonUtils --->', err)
  }
  return ipAddress?.ip || ''
}

//adding showWholeSalePricing property in cart , order review , order confirmation request header for mysite
/**
 * Retrieves the wholesale pricing flag for the current shopping context.
 *
 * Checks the shoppingContext parameter to see if it is 'storefront' or 'personal_offer'.
 *
 * For 'storefront', gets the storefront data from sessionStorage, extracts the current locale,
 * and looks up the wholesale flag from the storefront config for that locale.
 *
 * For 'personal_offer', simply returns the showWholeSalePricing override directly.
 *
 * Returns the wholesale pricing flag value if found, empty string if not.
 */
function getMySitePriceFlag(shoppingContext) {
  try {
    let contextValue = shoppingContext?.context
    let wholesale
    if (contextValue === 'storefront') {
      const storefront = getSessionStorage('storefront') || {}
      const curLocale = getLocaleCodeFromUrl() || {}
      const [language, country] = curLocale.includes('_')
        ? curLocale.split('_')
        : curLocale.split('-')
      wholesale =
        JSON.stringify(
          storefront?.['storefront']?.['mytestsqa']?.[`${country}`]?.['lang']?.[
            `${language}`
          ]?.['wholesale']
        ) || ''
    }

    if (contextValue === 'personal_offer') {
      wholesale = shoppingContext?.overrides?.showWholeSalePricing
    }
    return wholesale
  } catch (error) {
    console.log(
      'error while getting wholesale price config - Mysite & Personal Offer'
    )
  }
}

/**
 * Checks if a button should be enabled based on a list of available channels
 * and a specific channel value.
 *
 * @param {string[]} availableChannelsList - Array of available channel values
 * @param {string} value - The channel value to check
 * @returns {boolean} True if button should be enabled, false otherwise
 */
function checkEnableButton(availableChannelsList, value) {
  if (availableChannelsList != '' && availableChannelsList?.includes(value)) {
    return true
  } else if (
    availableChannelsList != '' &&
    !availableChannelsList?.includes(value)
  ) {
    return false
  } else if (availableChannelsList == '') {
    return true
  }
}

/**
 * Gets the two-letter country code from the provided countryCode option.
 *
 * If countryCode is 'canada' or 'ca', returns 'CA'.
 * Otherwise returns the countryCode unchanged.
 *
 * May need additional cases for converting country names to codes in the future.
 */
function getCountryCode(options) {
  const { countryCode } = options
  if (
    countryCode?.toLowerCase() == 'canada' ||
    countryCode?.toLowerCase() == 'ca'
  ) {
    return 'CA'
  }
  // may need for US to USA in future or any other country
  return countryCode
}

/**
 * Gets the image URL from the given product properties.
 *
 * Checks primaryimage and imageURL first.
 * If not found, tries to parse image URL from productImages array.
 *
 * @param {Object} properties - Product properties object
 * @returns {string} Image URL string
 */
function getImageFromProperty(properties = {}) {
  let image = properties?.primaryimage || properties?.imageURL || ''
  if (!image) {
    try {
      image = JSON.parse(properties?.productImages || '{}')?.[0]?.url || ''
    } catch (e) {
      console.log('image parse error')
    }
  }
  return image
}

/**
 * To identify the current URL shares with my site or personal offer
 * @date 2023/8/17 - 14:24:35
 *
 * @returns boolean - true if the URL matches the current URL, false if not matches
 */
function checkIsMySiteUrl() {
  if (IS_BROWSER) {
    const isMySiteUrls = [
      pageNames.loginCallback,
      pageNames.viewCart,
      pageNames.signUp,
      pageNames.checkout,
      pageNames.orderConfirmation,
      pageNames.myaccountsubscriptions,
      pageNames.expressCheckout,
      pageNames.klarnaCheckout,
    ]

    const isMySiteUrl = isMySiteUrls.some(url => {
      if (location?.href?.includes(url)) {
        return true
      }
    })
    return isMySiteUrl
  }
}

/**
 * This function is to get current site is storefront or personal offer or my site
 *
 * @date 2023/8/17 - 13:57:08
 */
function checkMysiteOrPersonalOffer() {
  let contextValue = ''
  try {
    if (IS_BROWSER) {
      let isMySiteUrl = checkIsMySiteUrl()
      const shoppingContext = getShoppingContext()
      // const shoppingContext = getLocalStorage('shoppingContext') || {}
      if (Object.keys(shoppingContext).length > 0 && isMySiteUrl) {
        contextValue = shoppingContext?.context || ''
      }
    }
    return contextValue
  } catch (e) {
    console.error('Fn error in checkMysiteOrPersonalOffer', e)
  }
}

//
/**
 * Shopping context value is used read from window object instead of local storage
 * @date 2023/8/17 - 16:44:19
 * @returns { object }
 */
function getShoppingContext() {
  let shoppingContext = {}
  if (IS_BROWSER) {
    const isNativeMobileApp =
      window?.isNativeApp == true || window?.isVeraApp == true

    const searchParams = location?.search || ''
    let isNativeAppQueryParamString = new URLSearchParams(searchParams)?.get(
      'isNativeApp'
    )

    if (isNativeMobileApp) {
      shoppingContext = getLocalStorage('shoppingContext') || {}
    } else {
      shoppingContext = window?.shoppingContextJson || {}
    }
  }
  // const shoppingContext = getLocalStorage('shoppingContext') || {}
  return shoppingContext
}

/**
 * Clears the shopping context from local storage and window object.
 *
 * @date 2023/8/17
 */
function clearShoppingContext() {
  try {
    if (IS_BROWSER) {
      deleteFromLocalStorage('shoppingContext')
      window.shoppingContextJson = {}
    }
  } catch (e) {
    console.log(
      'Unable to delete ShoppingContext from LocalStorage and window object',
      e
    )
  }
}

/**
 * This function is to set the value in a variable to identify storefront or personal offer or my site
 * Jira - https://nuskin.atlassian.net/browse/CX16-9756
 *
 * @date 2023/8/17 - 13:57:08
 *
 */

function setMysiteOrPersonalOffer() {
  if (IS_BROWSER) {
    let isMySiteUrl = checkIsMySiteUrl()
    const storefrontJson = getSessionStorage('storefront')
    if (isMySiteUrl && storefrontJson) {
      const shoppingContext = getLocalStorage('shoppingContext') || {}
      window.shoppingContextJson = shoppingContext
    }
  }
}

/**
 * Splits a full name string into first and last names.
 *
 * Trims the input string, then splits on the last space.
 * Returns an object with `firstName` and `lastName` properties.
 * Returns empty strings if input is invalid.
 */
function getNamefromFirstname(name) {
  try {
    name = name?.trim()
    return {
      firstName:
        name?.lastIndexOf(' ') > 0
          ? name?.slice(0, name?.lastIndexOf(' '))
          : name,
      lastName: name?.slice(name.lastIndexOf(' ') + 1),
    }
  } catch (e) {
    // have proper console with the message
    return {
      firstName: '',
      lastName: '',
    }
  }
}

/**
 * Gets an image URL from a Bynder asset.
 *
 * @param {Object} options - Options object
 * @param {Object} options.bynderImage - Bynder asset object
 * @param {string} [options.defaultType='Small'] - Default image type/size to return
 * @returns {string} Image URL
 */
function getBynderImage(options) {
  const {
    bynderImage = {},
    defaultType = 'transformBaseUrl',
    imageQueryParam,
  } = options || {}
  // const imageType = isWebImageType ? 'transformBaseUrl' : defaultType
  const backupImageType = 'Small'
  let defaultImage =
    bynderImage?.derivatives?.[defaultType] ||
    bynderImage?.derivatives?.[backupImageType]
  let finalImageUrl = ''

  finalImageUrl =
    defaultImage ||
    bynderImage?.derivatives?.webImage ||
    bynderImage?.derivatives?.Large ||
    bynderImage?.files?.webImage?.url

  if (imageQueryParam && finalImageUrl) {
    finalImageUrl = finalImageUrl + imageQueryParam
  }

  return finalImageUrl
}

/**
 * Gets a footer image URL from a Bynder asset.
 *
 * @param {Object} options - Options object
 * @param {Object} options.bynderImage - Bynder asset object
 * @param {string} [options.defaultType='Vector to PNG'] - Default image type/size to return
 * @returns {string} Image URL
 */
function getFooterBynderImage(options) {
  const { bynderImage = {}, defaultType = 'Vector to PNG' } = options || {}
  const defaultImage = bynderImage?.derivatives?.[defaultType]
  return (
    defaultImage ||
    bynderImage?.derivatives?.webImage ||
    bynderImage?.derivatives?.Large ||
    bynderImage?.files?.webImage?.url
  )
}
/**
 * Gets the alt text for a Bynder image from its metadata.
 *
 * @param {Object} options - Options object
 * @param {Object} options.meta - Bynder image metadata
 * @returns {string} Image alt text
 */

function getBynderAltText(options) {
  const { meta = {} } = options || {}
  return meta?.SEO_Tags || ''
}

/**
 * Gets the source of the alt text for a Bynder image from its metadata.
 *
 * @param {Object} options - Options object
 * @param {Object} options.meta - Bynder image metadata
 * @returns {string} Source of image alt text ('bynder' if from Bynder metadata, '' if not)
 */
function getBynderAltTextSrc(options) {
  const { meta = {} } = options || {}
  if (meta || meta?.SEO_Tags) {
    return 'bynder'
  } else {
    return ''
  }
}

/**
 * Tracks an error in Instana.
 *
 * @param {Object} options - Options object
 * @param {string} options.errorReport - The error report name/key
 * @param {Object} options.errorData - Any additional error data to log
 */
function trackErrorInInstana(options) {
  const { errorReport, errorData } = options || {}
  console.warn(errorReport, errorData)
  if (IS_BROWSER && window?.ineum != 'undefined') {
    ineum('reportEvent', `${errorReport}`, {
      meta: errorData,
    })
  }
}

/**
 * Filters out emails that match configured white label domains.
 *
 * @param {Object} options - Options object
 * @param {string} options.email - Email to filter
 * @returns {string} Email without white label domain if filtered
 */
function filterWhiteLabelEmails(options) {
  const { email = '' } = options || {}
  // Multiple emails can be configured using comma separated values
  const { blockedEmailList = '' } = APPConfig.getAppConfig()
  let fitleredEmail = ''
  if (email) {
    const blockListedEmails = blockedEmailList?.split(',')
    const isWhiteLabeledEmail = blockListedEmails.find(emailSuffix =>
      email?.includes(emailSuffix)
    )
    if (!isWhiteLabeledEmail) {
      fitleredEmail = email
    }
  }
  return fitleredEmail
}

/**
 * Converts a standard time string to 12-hour format with AM/PM.
 *
 * @param {string} standardTime - The time in 24-hour format, e.g. "13:45"
 * @returns {string} The time in 12-hour format with AM/PM, e.g. "1:45 PM"
 */
function convertTime(standardTime) {
  try {
    let time = standardTime
      ?.toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [standardTime]
    if (time != null && time?.length > 1) {
      time = time?.slice(1)
      time[5] = +time?.[0] < 12 ? ' AM' : ' PM'
      time[0] = +time?.[0] % 12 || 12
    }
    return time.join('')
  } catch (e) {
    console.log('error while converting standardHours in shipping method')
    return standardTime
  }
}

/**
 * Capitalize first letter of given string.
 * @date 10/11/2023 - 10:03:40 PM
 *
 * @param {string} type
 * @returns {string}
 */
function capitalizeFirstLetter(type) {
  return type?.charAt?.(0)?.toUpperCase() + type?.slice?.(1)?.toLowerCase()
}

/**
 * @description Checks if the page needs to be redirected based on the redirectUrls config
 * and returns redirect url
 *
 * @param {Object} options - The options object
 * @param {string} options.locale - The locale it needs to be redirected to
 * @param {string} options.pageUrl - The current page URL
 * @param {Array<string>} options.req - Express request
 * @returns {string} returns url if it matches the JSON
 */
function getRedirectUrl(options) {
  const { req, pageUrl, locale = '', host } = options || {}
  const appConfig = APPConfig?.getAppConfig()
  const pageLocale = locale || getLocaleCodeFromUrl()
  const localeInUrl = pageLocale?.toLowerCase()?.split('_')
  const currentUrlLocale = `${localeInUrl[1]}/${localeInUrl[0]}`
  let pathName = pageUrl?.replace(
    currentUrlLocale + (IS_BROWSER ? '' : '/'),
    ''
  )
  pathName = pathName?.split('?')?.[0]

  const urlList = Object.keys(appConfig?.redirectUrls || {})
  const redirectUrl = urlList.find(url => {
    return pathName?.toLowerCase() == url?.toLowerCase()
  })

  if (redirectUrl) {
    if (IS_BROWSER) {
      return appConfig?.redirectUrls[redirectUrl]
    }
    const domain = `${req.protocol}://${host}`
    return `${domain}/${currentUrlLocale}${appConfig?.redirectUrls[redirectUrl]}`
  }
}

/**
 * Calculates the total number of pages for pagination based on the total count,
 * page size, and other pagination info.
 *
 * @param {Object} pageableInfo - The pagination info object
 * @param {number} pageableInfo.totalCount - The total number of items
 * @param {number} pageableInfo.page - The current page number
 * @param {number} pageableInfo.size - The page size
 * @param {number} pageableInfo.count - Additional count, default 1
 * @returns {number} The total number of pages
 */
function getTotalPagesForPagination(pageableInfo = {}) {
  const { totalCount = 0, page = 1, size = 0, count = 1 } = pageableInfo || {}
  let totalPages = 0
  try {
    let remainder = totalCount % size
    let remainderCount = 0
    if (remainder != 0 && remainder < size / 2) remainderCount = 1
    let quotient = totalCount / size
    let quotientCount = Math.round(quotient)

    totalPages = remainderCount + quotientCount
  } catch (e) {
    totalPages = 0
  }
  return totalPages
}

/**
 * @description modifyAndGetCustomerType function returns the customer type
 * @date 12/8/2023
 *
 * @param {*} customertypes
 * @returns {*}
 */
function modifyAndGetCustomerType(customertypes = '') {
  let customerTypesData = customertypes?.toLowerCase() || ''
  customerTypesData = customerTypesData?.replace(
    'preferred',
    'preferredcustomer/member'
  )
  customerTypesData = customerTypesData?.replace('retail', 'retailcustomer')
  return customerTypesData
}

/**
 * @description modifyAndGetAccountType function returns account type
 * @date 12/8/2023
 *
 * @param {*} accType
 * @returns {*}
 */
function modifyAndGetAccountType(accType = '') {
  let accTypeData = accType?.replace(/ +/g, '').toLowerCase() || ''
  accTypeData = accTypeData?.includes('brandaffiliate')
    ? 'brandaffiliate'
    : accTypeData
  return accTypeData
}

/**
 * @description checkCustomerTypeEligibility function checks if the customer type
 * is eligible for the account type
 * @param {string} customerTypeResult - The customer type
 * @param {string} accoutTypeResult - The account type
 * @returns {boolean} Returns true if customer type is eligible for account type
 */
function checkCustomerTypeEligibility(
  customerTypeResult = '',
  accoutTypeResult = ''
) {
  return (
    customerTypeResult?.includes(accoutTypeResult) ||
    customerTypeResult === '' ||
    accoutTypeResult?.includes('brandaffiliate') ||
    false
  )
}

/**
 * @description getInventoryMessage function returns an inventory message
 * to display in UI , based on the given product details
 * @date 12/7/2023
 * @param {*} prodInfo
 * @returns {string} inventoryMessage
 */
function getInventoryMessage(itemInfo = {}, accountType = '') {
  const { specialProducts = [], otherProperties = {} } = itemInfo || {}
  const prodInfo = otherProperties || {}
  const isStockError =
    prodInfo?.inventory?.toLowerCase() === 'out of stock' || false // out of stock
  let customerTypeResult =
    modifyAndGetCustomerType(prodInfo?.properties?.customerTypes) || ''
  let isUserType =
    customerTypeResult?.includes(accountType) ||
    customerTypeResult === '' ||
    accountType?.includes('brandaffiliate') ||
    false //user type based restriction
  const availableChannels =
    prodInfo?.availableChannels || prodInfo?.properties?.availableChannels || []
  const isEnableBuyItAgain =
    checkEnableButton(availableChannels, 'web') || false //checking available channels
  const dangerousGoods =
    (prodInfo?.dangerousGoods && prodInfo?.dangerousGoods === 'true') ||
    (prodInfo?.properties?.dangerousGoods &&
      prodInfo?.properties?.dangerousGoods === 'true') ||
    false

  const productStatusCase = () => {
    const productStatus = isBundleHasDiscontinuedSubItem(specialProducts)
      ? 'discontinued'
      : prodInfo?.productStatus || prodInfo?.properties?.productStatus || ''

    switch (productStatus.toLowerCase()) {
      case 'replacement':
        return i18nTranslate(
          'bundle.replacement',
          'This is a replacement product and not available for purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
      case 'preview product':
      case 'preview':
        return i18nTranslate(
          'bundle.preview',
          'Preview product and will be launched soon',
          {
            nameSpace: 'ssr-resource',
          }
        )
      case 'stopped':
        return i18nTranslate(
          'bundle.stopped',
          'This product is temporarily stopped',
          {
            nameSpace: 'ssr-resource',
          }
        )
      case 'discontinued':
        return i18nTranslate(
          'bundle.discontinuedV2Message',
          'Discontinued - This product or promotion has been discontinued',
          {
            nameSpace: 'ssr-resource',
          }
        )
    }
  }
  switch (true) {
    case isStockError:
      return i18nTranslate('cart.outofstock', 'OUT OF STOCK') || ''
    case !isUserType:
      return i18nTranslate(
        'product.userType',
        'This user is not eligible to purchase this product'
      )
    case !isEnableBuyItAgain:
      return i18nTranslate(
        'bundle.webChannel',
        'This product is no-longer available to purchase through WEB'
      )
    case dangerousGoods:
      return i18nTranslate(
        'pdp.dangerousGoodsError',
        'Dangerous Goods cannot be added to Cart or Subscribed',
        {
          nameSpace: 'ssr-resource',
        }
      )
    default:
      return productStatusCase()
  }
}

/**
 * Checks if a bundle has any discontinued sub-items.
 * @param {Object[]} specialProducts - An array of special product objects.
 * @param {string} specialProducts[].otherproperties.productStatus - The product status of the special product.
 * @returns {boolean} - True if the bundle has any discontinued sub-items, false otherwise.
 */
function isBundleHasDiscontinuedSubItem(specialProducts = []) {
  return (
    (Array.isArray(specialProducts) &&
      specialProducts.length > 0 &&
      specialProducts?.some(
        specialProduct =>
          specialProduct?.otherproperties?.productStatus?.toLowerCase() ===
          'discontinued'
      )) ||
    false
  )
}

/**
 * Returns the status of the fraud detection callback.
 * Checks if the browser window has a property indicating
 * the fraud detection script has loaded, and returns
 * that value. Falls back to false if not present.
 */
function getFraudDetectionCbkStatus() {
  if (IS_BROWSER) {
    return window?.isFraudDetectionScriptLoaded ?? false
  }
  return false
}

/**
 * Sets the status of the fraud detection callback in the browser window.
 * This allows other code to check if the fraud detection script has loaded.
 * @param {boolean|string} status - The status to set. If a string, must be 'true'.
 */
function setFraudDetectionCbkStatus(status) {
  if (IS_BROWSER) {
    window.isFraudDetectionScriptLoaded = status == 'true' || status == true
  }
}

/**
 * @description getBundleReorderInfo : to get if bundle products are eligible
 * @date 12/11/2023 - 8:20:11 PM
 *
 * @param {*} reorderItems
 * @returns {*}
 */
function getBundleReorderInfo(reorderItems = []) {
  const bundleFilterInfo =
    reorderItems?.filter(
      element => element?.item?.itemInfo?.specialProducts?.length > 0
    ) || []
  const bundleInfo =
    bundleFilterInfo?.map(value => {
      return value?.item?.itemInfo?.specialProducts
    }) || []
  const checkForBundleReorder = bundleInfo?.map(value => {
    return (
      value?.map(val => {
        return val?.inventoryProperties?.available
      }) || []
    )
  })

  let bundleCheckArray = []
  checkForBundleReorder?.forEach(element => {
    bundleCheckArray?.push(element?.some(val => val === false))
  })

  const checkIfBundleIsEligible =
    bundleCheckArray?.some(element => element === false) || false

  return {
    checkIfBundleIsEligible: checkIfBundleIsEligible,
    checkForBundleReorder: checkForBundleReorder,
  }
}

/**
 * @description checkProductBackOrdered function checks whether the given set of products is backordered or not
 * @date 12/21/2023 - 4:41:02 PM
 *
 * @param {{}} [orderItems={}]
 * @returns {{}}
 */
function checkProductBackOrdered(orderItems = {}) {
  const { specialProducts = {}, sku = {} } = orderItems?.item?.itemInfo || {}
  const singleProductBackOrderedStatus =
    sku?.inventoryProperties?.backOrdered || false
  const bundleBackOrdersDate = []
  let isBundleBackOrdered = false
  const bundleBackOrdered =
    (specialProducts?.length > 0 &&
      specialProducts?.forEach(value => {
        isBundleBackOrdered = value?.inventoryProperties?.backOrdered || false
        if (isBundleBackOrdered) {
          bundleBackOrdersDate?.push(
            value?.inventoryProperties?.expectedBackOrderAvailabilityDate || ''
          )
        }
      })) ||
    []
  const comparebundleBackOrdersDate = bundleBackOrdersDate?.sort(
    (a, b) => b - a
  )
  let getBundleBackOrderedInventory = {}
  if (bundleBackOrdersDate?.length > 0) {
    getBundleBackOrderedInventory =
      specialProducts?.filter(
        value =>
          value?.inventoryProperties?.expectedBackOrderAvailabilityDate ===
          comparebundleBackOrdersDate?.[0]
      ) || {}
  }

  return {
    bundleInfo: specialProducts,
    isBundleBackOrdered: isBundleBackOrdered,
    singleProductBackOrderedStatus: singleProductBackOrderedStatus,
    getBundleBackOrderedInventory: getBundleBackOrderedInventory,
  }
}

function getOrderHistoryBackOrderedDate(orderItem = {}) {
  const {
    specialProducts = {},
    otherProperties = {},
    productType = '',
  } = orderItem?.item?.itemInfo || {}

  let singleProductBackOrderedStatus = false
  let isBundleBackOrdered = false
  let getBundleBackOrderedInventory = {}
  let backOrderDateNew = ''
  if (productType.toLowerCase() === 'default') {
    backOrderDateNew = otherProperties?.backOrderDate || ''
  } else {
    const bundleBackOrdersDate = []
    const isBackOrdered = item =>
      item?.otherproperties?.backOrderDate !== '' ? true : false
    isBundleBackOrdered = specialProducts?.some(isBackOrdered) || false

    const bundleBackOrdered =
      (specialProducts?.length > 0 &&
        specialProducts?.forEach(value => {
          let isItemBackOrdered =
            value?.otherproperties?.backOrderDate !== '' || false
          if (isItemBackOrdered) {
            bundleBackOrdersDate?.push(
              value?.otherproperties?.backOrderDate || ''
            )
          }
        })) ||
      []
    const comparebundleBackOrdersDate = bundleBackOrdersDate?.sort(
      (a, b) => b - a
    ) //sorting backorder dates as descending when all special products are backorered

    if (bundleBackOrdersDate?.length > 0) {
      getBundleBackOrderedInventory =
        specialProducts?.filter(
          value =>
            value?.otherproperties?.backOrderDate ===
            comparebundleBackOrdersDate?.[0]
        ) || {}
    }
  }

  return {
    bundleInfo: specialProducts,
    isBundleBackOrdered: isBundleBackOrdered,
    singleProductBackOrderedStatus: backOrderDateNew !== '' ? true : false,
    getBundleBackOrderedInventory: getBundleBackOrderedInventory,
    backOrderDate: backOrderDateNew,
  }
}

/**
 * @description isGPSEnabled function checked APPConfig flag enableGlobalPaymentSystem and returns true/false
 * @returns {boolean}
 */
function isGPSEnabled() {
  return APPConfig?.getAppConfig()?.enableGlobalPaymentSystem == 'true'
}

/**
 * @description isGPSDefaultPayment function checked APPConfig flag enableDefaultPayment and returns true if GPS is default, else false
 * @returns {boolean}
 */
function isGPSDefaultPayment() {
  return APPConfig?.getAppConfig()?.enableDefaultPayment?.toLowerCase() == 'gps'
}

/**
 * @description getParsedPhoneNumber function parses a phone number string into a formatted national number and country code using the libphonenumber-js library
 * @param {string} phoneNumber - The phone number to parse
 * @returns {Object} - An object containing the formatted national number and country code
 *    phoneNumberCode - The formatted national phone number
 *    phoneCountry - The country code
 */
async function getParsedPhoneNumber(phoneNumber) {
  try {
    const { parsePhoneNumber } = await import('libphonenumber-js')
    const phoneNumberCode =
      parsePhoneNumber(phoneNumber)?.formatNational() || phoneNumber
    const phoneCountry = parsePhoneNumber(phoneNumber)?.country

    return { phoneNumberCode: phoneNumberCode, phoneCountry: phoneCountry }
  } catch (error) {
    return { phoneNumberCode: '', phoneCountry: '' }
  }
}

/**
 * Display Order Return Based on the flag supported Locales we configured in defaultConfig
 * For Ex: if we want to display in US and not for CA, means orderReturnSupportedLocales will be ["US"]
 * @date 2/2/2024 - 12:39:26 PM
 *
 * @returns {true|false} - returns true if current locale is matched with orderReturnSupportedLocales config and
 * return button will be displayed
 * else if it returns false, return button will not be displayed
 */
function displayOrderReturnForSupportedLocales() {
  const selectedLocaleCode =
    getLocaleCodeFromUrl({
      defaultLocale: APPConfig?.getActiveAppConfig()?.defaultLocale,
    })?.split('_')?.[1] || 'US'
  const orderReturnSupportedLocales = APPConfig?.getAppConfig()
    ?.orderReturnSupportedLocales || ['US']
  const isToDisplayReturnButton =
    orderReturnSupportedLocales?.includes(selectedLocaleCode)
  return isToDisplayReturnButton
}

function emailValidation(emailValue) {
  const diacriticRegex =
    /[À-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]/
  const greekRegex = /[α-ωΑ-Ω]/
  const chineseRegex = /[一-龥]/
  const japaneseRegex = /[ぁ-ゔァ-ヴー]/
  const cyrillicRegex = /[А-Яа-яЁё]/
  const devanagariRegex = /[अ-ह]/
  const email = String(emailValue).toLowerCase()
  if (
    diacriticRegex.test(email) ||
    greekRegex.test(email) ||
    chineseRegex.test(email) ||
    japaneseRegex.test(email) ||
    cyrillicRegex.test(email) ||
    devanagariRegex.test(email)
  ) {
    return false
  } else {
    return true
  }
}

/**
 * @description Checks if guest checkout is enabled for the web based on the configuration.
 * @returns {boolean} - True if guest checkout is enabled, false otherwise.
 */
function isGuestCheckoutEnabledForWeb() {
  return convertToBoolean(APPConfig?.getAppConfig()?.enableGuestCheckoutForWeb)
}

/**
 * @description getLiveEventStatus function checks defaultConfig for enableLiveEvent flag and returns true/false
 * @returns {boolean}
 */

function getLiveEventStatus() {
  return APPConfig?.getAppConfig()?.enableLiveEvent || 'false'
}

/**
 * @description getEventNotificationTimeline function return Event Notification Timeline Array from config
 */

function getEventNotificationTimeline() {
  return APPConfig?.getAppConfig()?.eventAlertTimeline || []
}

/**
 * @description getShouldEnableProfileEditInEvent function checks defaultConfig for enableProfileEditInEvent flag and returns true/false
 * @returns {boolean}
 */
function getShouldEnableProfileEditInEvent() {
  return APPConfig?.getAppConfig()?.enableProfileEditInEvent || 'false'
}

/**
 * @description isNonEventOrEventWithSub returns true by default if non event site
 * return value from APPConfig for enableSubscriptionInEvent for event site
 * @returns {boolean}
 */
function isNonEventOrEventWithSub() {
  return (
    !convertToBoolean(getLiveEventStatus()) ||
    convertToBoolean(
      APPConfig?.getAppConfig()?.enableSubscriptionInEvent || 'false'
    )
  )
}

/**
 * @description enablePhoneVerification function checks defaultConfig for enablePhoneVerification flag and returns true/false
 * @returns {boolean}
 */

function enablePhoneVerification() {
  return convertToBoolean(APPConfig?.getAppConfig()?.enablePhoneVerification)
}

function enableJITSU() {
  return convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)
}

function enableFeatureFlagtoApi() {
  return convertToBoolean(APPConfig?.getAppConfig()?.isFeatureFlagtoApi)
}

function getInputFormJSON(locale) {
  const specialCharacter = /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/

  const getRegionInputJSON = {
    es_MX: {
      name: {
        regEx: specialCharacter,
        maxLength: 40,
      },
      email: {
        maxLength: 256,
      },
      maskValue: [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    es_CL: {
      name: {
        regEx: specialCharacter,
        maxLength: 40,
      },
      email: {
        maxLength: 256,
      },
      maskValue: [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    es_PE: {
      name: {
        regEx: specialCharacter,
        maxLength: 40,
      },
      email: {
        maxLength: 256,
      },
      maskValue: [
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    es_AR: {
      name: {
        regEx: specialCharacter,
        maxLength: 40,
      },
      email: {
        maxLength: 256,
      },
      maskValue: [
        '(',
        /[1-9]/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    es_CO: {
      name: {
        regEx: specialCharacter,
        maxLength: 40,
      },
      email: {
        maxLength: 256,
      },
      maskValue: [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
  }

  return getRegionInputJSON[locale] || {}
}
function getCountryConfig() {
  const enableLiveEvent = getLiveEventStatus()
  let countryConfig = storeContainer.countryConfig
  if (enableLiveEvent == 'true') {
    let updatedConfig = structuredClone(storeContainer.countryConfig)
    for (const countryKey in updatedConfig) {
      if (countryKey.includes('EVENT_')) {
        const eventCountry = countryKey?.replace('EVENT_', '')
        updatedConfig[eventCountry] = updatedConfig[countryKey]
      }
    }
    countryConfig = updatedConfig
  }
  return countryConfig
}

function jitsuSignUpRedirect(options) {
  const { isToReturnUrl = false, isFromGuestCheckout = false } = options || {}
  let currentLocale = getLocaleCodeFromUrl()?.split('_')
  currentLocale =
    '/' +
    currentLocale[1]?.toLowerCase() +
    '/' +
    currentLocale[0]?.toLowerCase()

  const jitsuUrl =
    `https://${document.domain}${currentLocale}/site/signup` +
    (isFromGuestCheckout ? '?origin=checkout' : '')
  if (isToReturnUrl) {
    return jitsuUrl
  }
  window.location.href = jitsuUrl
}

/**
 * Checks the information for an array of SKUs.
 *
 * @param {Object[]} [skus=[]] - An array of SKU objects.
 * @returns {Object[]} - An array of SKU details, including whether the SKU is a normal SKU or a bundle SKU, and an array of SKUs that are not the main SKU.
 */
function checkForSkusArrayInfo(skus = []) {
  const specialProductTypes = [
    'bundle',
    'collection',
    'fixedbundle',
    'skukit',
    'fixedpricebundle',
    'kit',
  ]

  const normalProductSkuType = ['default']

  let skusExceptMainSku = []
  let skuDetails = skus?.map((sku, key) => {
    const { type = '' } = sku || {}
    const skuType = type?.toLowerCase()?.replace('_', '') || ''
    let isNormalSku = normalProductSkuType.includes(skuType)

    let skuInfo = {}
    let isBundleSku = specialProductTypes.includes(skuType)
    if (!isBundleSku && !isNormalSku) {
      skusExceptMainSku.push(sku)
    }

    if (isNormalSku || isBundleSku) {
      return { isNormalSku, isBundleSku, skuInfo: sku, skusExceptMainSku }
    }
  })

  return skuDetails
}

function getCookiesFeatureOverride() {
  const allcookies = typeof window === 'object' ? document?.cookie : ''
  const cookiearray = allcookies.split(';')
  const cookieJSON = {}

  // Now take key value pair out of this array
  for (var i = 0; i < cookiearray.length; i++) {
    const name = cookiearray[i].split('=')[0].trim()
    const value = cookiearray[i].split('=')[1]
    cookieJSON[name] = value
  }
  let getFeatureOverride = ''
  APPConfig?.getAppConfig()?.configPassToAPI?.map(item => {
    if (cookieJSON.hasOwnProperty(item)) {
      if (cookieJSON[item] === true || cookieJSON[item] === 'true') {
        getFeatureOverride =
          getFeatureOverride + (getFeatureOverride === '' ? item : ',' + item)
      } else {
        getFeatureOverride =
          getFeatureOverride +
          (getFeatureOverride === ''
            ? item.replace('enable', 'disable')
            : ',' + item.replace('enable', 'disable'))
      }
    }
  })
  return getFeatureOverride
}

function setStoragesForAccountConversion() {
  if (IS_BROWSER) {
    const pathName = window?.location?.pathname
    const isAccountProfile =
      pathName?.includes(pageNames.b2bAccountTypeConvert) ||
      pathName?.includes(pageNames.b2bAccountProfile)

    if (isAccountProfile) {
      const searchParams = new URLSearchParams(window?.location?.search)
      const type = searchParams?.get('type')?.toLowerCase() || ''
      const sponsor = searchParams?.get('sponsor') || ''

      if (sponsor) {
        if (type === 'member') {
          setSessionStorage('mem', 'ok')
        }
        if (type == 'retail') {
          setSessionStorage('switchsponsor', 'ok')
        }
        setSessionStorage('conv-sponsor', sponsor)
      }
    }
  }
}

/**
 * Retrieves information about a given sponsor ID.
 *
 * @param {string} sponsorId - The ID of the sponsor to retrieve information for.
 * @returns {object} An object containing information about the sponsor, including whether it is an in-house sponsor or a RetFloat sponsor.
 */
function getSponsorInfo(sponsorId) {
  const sponsorInfo = {
    isInHouseSponsor: false,
    isRetFloat: false,
  }
  const houseSponsor = APPConfig?.getAppConfig()?.inHouseSponsor || {}
  const sponsorValues = Object.values(houseSponsor)
  if (sponsorValues.includes(sponsorId)) {
    sponsorInfo.isInHouseSponsor = true
  }

  if (sponsorId === 'RETFLOAT') {
    sponsorInfo.isRetFloat = true
  }

  return sponsorInfo
}

/**
 * Sets the UTM information in the session storage.
 *
 * This function retrieves the UTM parameters from the current URL and stores them in the session storage under the key 'utmInfo'.
 * The UTM parameters are stored as an object, where the keys are the parameter names and the values are the corresponding parameter values.
 *
 */
function setUTMInfo() {
  try {
    const utmInfo = {}
    const { UTTConfig, enableEQFastFollow = false } = APPConfig?.getAppConfig()
    const { UTTParams = [], cookieExpiryInSeconds = 2592000 } = UTTConfig

    if (IS_BROWSER) {
      const isFromUTT = UTTParams.some(UTTParam =>
        location.search.includes(UTTParam)
      )
      const isFromUTM = location.search?.includes('utm_')

      const queryParams = new URLSearchParams(location.search)
      queryParams.forEach((value, key) => {
        utmInfo[key] = convertToBoolean(enableEQFastFollow)
          ? stripOffExtraDoubleQuotes(value)
          : value
      })

      if (isFromUTT) {
        setCookie({
          cookieName: 'utmInfo',
          cookieValue: JSON.stringify(utmInfo),
          expirySeconds: cookieExpiryInSeconds,
          isSessionCookie: cookieExpiryInSeconds == 0,
        })
        deleteFromSessionStorage('utmInfo')
      } else if (isFromUTM) {
        setSessionStorage('utmInfo', utmInfo)
      }
    }
  } catch (e) {
    trackErrorInInstana({ errorReport: 'Error in setUTMInfo', errorData: e })
  }
}

/**
 * Retrieves the UTM information stored in the session storage.
 *
 * This function retrieves the UTM parameters that were previously stored in the session storage using the `setUTMInfo()` function.
 *
 * @returns {object} An object containing the UTM parameters,
 * where the keys are the parameter names and the values are the corresponding parameter values.
 */
function getUTMInfo() {
  try {
    const UTTCookieValues = getCookie('utmInfo')
    if (UTTCookieValues) {
      const cookieValues = JSON.parse(UTTCookieValues)
      if (cookieValues) {
        // Loop through object and strip quotes from each value
        Object.keys(cookieValues).forEach(key => {
          cookieValues[key] = stripOffExtraDoubleQuotes(cookieValues[key])
        })
      }
      return cookieValues
    } else {
      const utmInfo = getSessionStorage('utmInfo')
      return utmInfo
    }
  } catch (e) {
    trackErrorInInstana({ errorReport: 'Error in getUTMInfo', errorData: e })
  }
}

/**
 * Retrieves the UTT information stored in the session storage.
 *
 * This function retrieves the UTT parameters that were previously stored in the session storage using the `getUTTInfo()` function.
 *
 * @returns {object} An object containing the UTT parameters,
 * where the keys are the parameter names and the values are the corresponding parameter values.
 */
function getUTTInfo() {
  try {
    const UTTCookieValues = getCookie('utmInfo')
    if (UTTCookieValues) {
      const uttInfo = {}
      const uttCookieJSON = JSON.parse(UTTCookieValues) || {}
      const { UTTConfig = {} } = APPConfig?.getAppConfig()
      const { UTTParamsMapping = {}, cookieExpiryInSeconds = 2592000 } =
        UTTConfig
      const UTTParamsMappingKeys = Object.keys(UTTParamsMapping) || []

      const uttCookieJSONKeys = Object.keys(uttCookieJSON)
      uttCookieJSONKeys.forEach(key => {
        UTTParamsMappingKeys.forEach(val => {
          if (UTTParamsMapping[val] === key) {
            uttInfo[val] = uttCookieJSON[key]
          }
        })
      })

      return uttInfo
    }

    return {}
  } catch (e) {
    trackErrorInInstana({ errorReport: 'Error in getUTTInfo', errorData: e })
  }
}

/**
 * Retrieves queryParam with strip off the extra ““ when we capture the utm / utt data from the url before passing this information
 */
function stripOffExtraDoubleQuotes(value) {
  try {
    return value?.replace(/^\"|\"$/g, '')
  } catch (e) {
    trackErrorInInstana({
      errorReport: 'Error in strip off the extra Double Quotes',
      errorData: e,
    })
  }
}

/**
 * Retrieves the sponsor ID based on the UTM source or a default value.
 *
 * This function checks the UTM information stored in the session storage to determine the sponsor ID.
 * If a UTM source is present, it looks up the corresponding sponsor ID in the `inHouseSponsor` configuration.
 * If no UTM source is found, it returns the default sponsor ID of 'RETFLOAT'.
 *
 * @returns {string} The sponsor ID based on the UTM source or the default value.
 */
function getGuestSponsorId() {
  const utmInfo = getUTMInfo()
  const { inHouseSponsor } = APPConfig?.getAppConfig()
  let sponsorId = ''

  if (utmInfo?.utm_medium) {
    sponsorId =
      inHouseSponsor[utmInfo?.utm_medium] || inHouseSponsor?.utmDefault
  } else {
    sponsorId = inHouseSponsor?.defaultSponsor
  }
  return sponsorId
}

/**
 * Retrieves the signup origin based on the user's shopping context and UTM information.
 *
 * This function determines the signup origin for a guest user based on the following logic:
 * - If the user is on the Mysite storefront, the signup origin is 'Mysite'.
 * - If the user is on a personal offer page, the signup origin is 'PersonalOffer'.
 * - If the user has a UTM source, the signup origin is the UTM source.
 * - If none of the above conditions are met, the signup origin is 'Web'.
 *
 * @returns {string} The signup origin for the guest user.
 */
function getGuestSignupOrigin() {
  let signupOrigin = 'Web'
  let shoppingContext = ''
  const isGuestCheckoutEnabled = isGuestCheckoutEnabledForWeb()

  if (IS_BROWSER) {
    shoppingContext = checkMysiteOrPersonalOffer() || ''
    if (shoppingContext === 'storefront') {
      signupOrigin = 'Mysite'
    } else if (shoppingContext === 'personal_offer') {
      signupOrigin = 'PersonalOffer'
    }
  }
  // Commented for recent request  from PUR-1124
  // if (isGuestCheckoutEnabled && isFromAccounts) {
  //   const utmInfo = getUTMInfo()
  //   const utmSource = utmInfo?.utm_medium
  //   if (utmSource) {
  //     signupOrigin = utmSource
  //   }
  // }
  return signupOrigin
}

function isExpressCheckout(useExpressCart = false) {
  if (IS_BROWSER) {
    const { enableExpressCheckout } = APPConfig?.getAppConfig()
    if (
      getLocalStorage('isExpressPDPLogin') &&
      convertToBoolean(enableExpressCheckout)
    ) {
      return true
    }
    if (useExpressCart) {
      return (
        convertToBoolean(enableExpressCheckout) &&
        location.href?.includes(pageNames.expressPDP)
      )
    }
    if (
      !useExpressCart &&
      location.href?.includes(pageNames.expressPDP) &&
      convertToBoolean(enableExpressCheckout)
    ) {
      return true
    }
    return (
      convertToBoolean(enableExpressCheckout) &&
      location.href?.includes(pageNames.expressCheckout)
    )
  }
  return false
}

/**
 * Generates Google Tag Manager (GTM) page data for checkout pages.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.pageTitle=''] - The title of the page.
 * @param {boolean} [props.skipTitle=false] - Flag to skip setting the page title.
 * @returns {Object} The GTM page data object containing `pagePath` and `pageTitle`.
 */
function getCheckoutGTMPageData(props) {
  const gtmPageData = {}
  if (IS_BROWSER) {
    const possibleCheckoutPages = [
      { pathName: pageNames.checkout, pageTitle: 'Checkout' },
      { pathName: pageNames.expressCheckout, pageTitle: 'Express Checkout' },
      { pathName: pageNames.expressPDP, pageTitle: 'Express PDP' },
    ]
    const currentPage = possibleCheckoutPages.find(page =>
      location?.pathname?.includes(page.pathName)
    )
    gtmPageData['pagePath'] = currentPage?.pathName || pageNames.checkout
    if (!props?.skipTitle)
      gtmPageData['pageTitle'] =
        props?.pageTitle || currentPage?.pageTitle || 'Checkout'
    return gtmPageData
  }
  return {
    pagePath: pageNames.checkout,
    pageTitle: props?.pageTitle || 'Checkout',
  }
}

async function encodeSHA1(data) {
  try {
    const encoder = new TextEncoder()
    const dataBuffer = encoder.encode(data)
    const hashBuffer = await crypto.subtle.digest('SHA-1', dataBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('')
    return hashHex
  } catch (e) {
    console.log('error while encoding sha1', e)
    return data
  }
}

export {
  currencyFormat,
  convertToBoolean,
  observeElement,
  getCurrency,
  getLocaleString,
  isValidUrl,
  setResponsiveValues,
  tryJSONParse,
  tryDecode,
  encodeSearchTerm,
  decodeSearchTerm,
  safeParseInt,
  getLocalePathParam,
  renderLinkCheck,
  getMySiteUrl,
  checkEnableButton,
  isUrlAbsolute,
  getBrowserIpAddress,
  replaceLocaleInURL,
  getCountryCode,
  getImageFromProperty,
  isPageFound,
  getMysiteCartUrl,
  getMySitePriceFlag,
  checkMysiteOrPersonalOffer,
  getNamefromFirstname,
  getBynderImage,
  getBynderAltText,
  getProductPDPTitle,
  getMedia,
  trackErrorInInstana,
  filterWhiteLabelEmails,
  getFooterBynderImage,
  getBynderAltTextSrc,
  convertTime,
  setMysiteOrPersonalOffer,
  getShoppingContext,
  clearShoppingContext,
  checkCustomerTypeEligibility,
  capitalizeFirstLetter,
  getRedirectUrl,
  getTotalPagesForPagination,
  getInventoryMessage,
  isBundleHasDiscontinuedSubItem,
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  getBundleReorderInfo,
  getFraudDetectionCbkStatus,
  setFraudDetectionCbkStatus,
  checkProductBackOrdered,
  getOrderHistoryBackOrderedDate,
  isGPSEnabled,
  isGPSDefaultPayment,
  getParsedPhoneNumber,
  displayOrderReturnForSupportedLocales,
  emailValidation,
  getLiveEventStatus,
  enablePhoneVerification,
  enableFeatureFlagtoApi,
  getInputFormJSON,
  getCountryConfig,
  getShouldEnableProfileEditInEvent,
  isNonEventOrEventWithSub,
  jitsuSignUpRedirect,
  checkForSkusArrayInfo,
  getCookiesFeatureOverride,
  getEventNotificationTimeline,
  enableJITSU,
  setStoragesForAccountConversion,
  isGuestCheckoutEnabledForWeb,
  getSponsorInfo,
  setUTMInfo,
  getUTMInfo,
  getUTTInfo,
  stripOffExtraDoubleQuotes,
  getGuestSponsorId,
  getGuestSignupOrigin,
  encodeSHA1,
  isExpressCheckout,
  getCheckoutGTMPageData,
}
