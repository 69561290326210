import 'isomorphic-fetch'
import querystring from 'querystring'
import {
  // isToLoadFromFixture,
  enableProxy,
  isToEnableSSR,
  API_ABORT_DELAY,
  getActiveAppConfig,
  getLocalStorage,
  setLocalStorage,
  deleteFromLocalStorage,
  setCookie,
  getCookie,
  deleteCookie,
  isB2BAccount,
  isB2BAdmin,
  isB2BUser,
  isB2B2C,
  isB2B2CAdmin,
  enableB2B2CUserInviteCreate,
  isenableUserInvite,
  APPConfig,
} from 'config/appConfig'
import { getEndPoints } from 'src/apiEndPoints'
import { storeContainer } from 'src/models/Store'
import { application, IS_BROWSER } from './application'
// import { CS_ID, CS_TOKEN } from 'config/appConfig'
import {
  checkMysiteOrPersonalOffer,
  replaceLocaleInURL,
  trackErrorInInstana,
  enableFeatureFlagtoApi,
  getCookiesFeatureOverride,
  isExpressCheckout,
} from 'src/utils/commonUtils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'

/**
 * Checks if a response from the API indicates a successful request.
 *
 * @param {Object} response - The response object from the API request.
 * @returns {boolean} True if the response status is >= 200 and < 300, false otherwise.
 */
function isSuccessStatus(response) {
  if (response) {
    const status = response?.status
    return status && status >= 200 && status < 300
  }
}
/**
 * Generates the API configuration object from the provided apiConfig.
 *
 * @param {Object} apiConfig - The API config object containing url, method and endPointName
 * @returns {Object} The API configuration object with apiUrl and method populated
 */

function generateAPIConfig(apiConfig) {
  const END_POINTS = getEndPoints()
  const { url, method, endPointName } = apiConfig

  return {
    apiUrl: url || END_POINTS[endPointName].url,
    method: method || END_POINTS[endPointName].method,
  }
}

/**
 * Checks if session headers should be ignored for the given endpoint.
 *
 * For B2B accounts, session headers are always ignored.
 * For B2C accounts, session headers are ignored for certain endpoints like
 * topCategory, getCatalog, getProducts, search.
 *
 * @param {string} endPointName - The name of the endpoint being called
 * @returns {boolean} True if session headers should be ignored, false otherwise
 */
function ignoreSessionHeaders(endPointName) {
  if (isB2BAccount()) {
    return true
  }
  return endPointName == 'topCategory' ||
    endPointName == 'getCatalog' ||
    endPointName == 'getProducts' ||
    endPointName == 'search'
    ? false
    : true
}

/**
 * Merges common headers and headers from cookies into a single headers object.
 *
 * For the specified endpoints, it will read the cookie values for the headersToPassFromCookieToAPI
 * and add them to the headers object. Otherwise the sessionId header will be ignored.
 *
 * @param {Object} headers - The request headers object
 * @param {string} endPointName - The name of the endpoint being called
 * @param {Object} appConfig - The app config containing commonHeaders and headersToPassFromCookieToAPI
 * @returns {Object} The merged headers object
 */
function getCommonHeaders(headers, endPointName, appConfig) {
  const { commonHeaders = {}, headersToPassFromCookieToAPI = [] } = appConfig

  headersToPassFromCookieToAPI.forEach(value => {
    const cookieValue = getCookie(value)
    if (cookieValue && ignoreSessionHeaders(endPointName)) {
      headers[value] = cookieValue
    } else {
      console.log('sessionId ignored for >>> ', endPointName)
    }
  })

  return {
    ...commonHeaders,
    ...headers,
  }
}

/**
 * Tracks API errors through Instana by emitting a custom event.
 *
 * @param {Object} options - The options object
 * @param {Object} options.apiConfig - The API config object
 * @param {number} options.statusCode - The HTTP status code
 * @param {string} options.error - The error message
 * @param {Object} options.headers - The HTTP headers
 * @param {string} options.responseMessage - The response message
 * @param {string} options.apiUrl - The API URL
 */
function trackApiErrorThroughInstana(options) {
  const {
    apiConfig,
    statusCode,
    error = '',
    headers,
    responseMessage = '',
    apiUrl: url = '',
  } = options || {}
  const { endPointName = '', queryParams = {} } = apiConfig
  let customEventPrefix = 'Custom_Event_'
  let errMessage = customEventPrefix + endPointName
  let isToReportError = true
  // We need access to read the response headers
  // const spanId = headers?.get?.('x-b3-spanid') || ''
  // const traceId = headers?.get?.('x-b3-traceid') || ''

  if (typeof ineum === 'function') {
    // commenting it to identify all the errors via Instana custom event
    // if (statusCode == '400' || statusCode == '401' || statusCode == '404') {
    //   isToReportError = false
    // }

    if (isToReportError) {
      const errorReport =
        `API error from ${IS_BROWSER ? 'BROWSER' : 'SERVER'} ` + endPointName
      const errorData = {
        url,
        statusCode,
        fetchError: error,
        responseMessage,
        ...apiConfig,
      }
      console.warn(errorReport, errorData)
      ineum('reportEvent', errorReport, {
        meta: errorData,
      })
    }
  }
}

/**
 * Increments the count of active fetches.
 */
const incrementFetchCount = () => {
  application.activeFetches += 1
}

/**
 * Decrements the count of active fetches.
 */
const decrementFetchCount = () => {
  if (application.activeFetches > 0) {
    application.activeFetches -= 1
  }
}

/**
 * Checks if there are any ongoing fetches.
 * @returns {boolean} True if there are ongoing fetches, false otherwise.
 */
const hasOngoingFetches = () => {
  return application.activeFetches > 0
}

/**
 * Fetches API response based on given API config.
 *
 * @param {Object} apiConfig - API config object containing request details like endpoint name, headers, query params etc.
 */
async function fetchResponseFromAPI(apiConfig) {
  const {
    pathParams,
    queryParams = {},
    // isToStoreInLocal,
    endPointName,
    postData,
    ignoreCommonHeader = false,
    contextName,
    contextUrlPathFromSSR = '',
    contextQueryParamsFromSSR = '',
    isToSendLocaleInQueryParam = true,
    mode,
    isToSendStoreId = true,
    deleteContentType = false,
    credentials = 'include',
    isToDeleteHeader = false,
    isToIncludeCommomHeaders = true,
    addPathParams,
    curLocaleFromUrl,
    sessionCookies,
    cartType = '',
    keepAlive = false,
  } = apiConfig

  console.log(`endPointName >>> `, endPointName)

  let { headers = {} } = apiConfig

  application.IsAppLoading = !application.isCallInProgress

  if (IS_BROWSER && window.isNativeApp === true) {
    let skSessionId = getLocalStorage('x-sk-session-id')
    let accessToken = getLocalStorage('accessToken')
    if (skSessionId) {
      headers = {
        ...headers,
        'x-sk-session-id': skSessionId,
      }
    } else if (accessToken) {
      headers = { ...headers, Authorization: accessToken }
    }
  } else if (!IS_BROWSER && sessionCookies) {
    headers = { ...headers, cookie: sessionCookies }
  }

  // const curAppConfig = getAppConfig()
  // console.log('curAppConfig ', curAppConfig)
  if (!IS_BROWSER && contextName && !curLocaleFromUrl) {
    const logMessage = `Fn fetchResponseFromAPI Current locale is missing in loadParams for SSR, endPointName: ${endPointName}, curLocaleFromUrl: ${curLocaleFromUrl}`
    console.warn(logMessage)
  }

  const curAppConfig = getActiveAppConfig(curLocaleFromUrl)
  const { method, apiUrl } = generateAPIConfig(apiConfig)

  if (
    (!method || !apiUrl) &&
    endPointName !== 'studioData' &&
    endPointName !== 'footerData' &&
    endPointName !== 'liveEventStudioData'
  ) {
    console.warn('Fetch method or API URL is empty')
    // For studioData, URL is constructed in apiUtils -> fetchResponseFromAPI function
    return {}
  }

  const combinedHeaders = isToIncludeCommomHeaders
    ? getCommonHeaders(headers, endPointName, curAppConfig)
    : {}

  // Need to send cookie over-ride only for orchestration services
  if (
    !combinedHeaders['x-sk-featureflagoverride'] &&
    enableFeatureFlagtoApi() &&
    getCookiesFeatureOverride() !== '' &&
    apiUrl?.includes('/orchestrationservices/')
  ) {
    combinedHeaders['x-sk-featureflagoverride'] = getCookiesFeatureOverride()
  }

  if (!combinedHeaders['Content-Type']) {
    combinedHeaders['Content-Type'] = 'application/json'
  }

  if (deleteContentType === true) {
    delete combinedHeaders['Content-Type']
  }

  if (isToSendLocaleInQueryParam === true) {
    // queryParams.locale = combinedHeaders.locale

    const defaultLocale = getLocaleCodeFromUrl({
      defaultLocale: 'en_US',
      isReverseType: true,
    })

    queryParams.locale = (
      queryParams.locale ||
      curLocaleFromUrl ||
      storeContainer.activeLocale ||
      defaultLocale
    )?.replace('-', '_')

    delete combinedHeaders.locale
  }

  /**
   * @todo
   * !!! store id mapping should be common
   */
  if (isToSendStoreId) {
    queryParams.storeId = queryParams.storeId || curAppConfig.storeId
    delete combinedHeaders['x-store-id']
  }

  let queryPrefix = ''
  let queryParamString = ''
  let queryAddString = ''

  if (addPathParams) {
    queryAddString = addPathParams
  }
  if (pathParams && enableProxy) {
    queryPrefix = '&'
  } else if (Object.keys(queryParams).length > 0) {
    queryPrefix = '?'
  }

  if (queryParams) {
    queryParamString = queryPrefix + querystring.stringify(queryParams)
  }

  const path =
    pathParams && enableProxy
      ? `?pathParams=${pathParams}`
      : pathParams
      ? `/${pathParams}`
      : ''

  let personalOffer
  let contextValue
  let mysite
  /**
   * @note
   * replace the cart type name after checking whether it is personal_offer or mysite user
   */
  try {
    if (IS_BROWSER) {
      // const shoppingContext = getLocalStorage('shoppingContext') || {}
      // if (Object.keys(shoppingContext).length > 0) {
      // contextValue = shoppingContext?.context || ''
      contextValue = checkMysiteOrPersonalOffer()
      if (contextValue == 'personal_offer') {
        personalOffer = true
      } else {
        if (contextValue == 'storefront') {
          mysite = true
        }
      }
      // }
    }
  } catch (e) {
    console.error('JSON.parse error at renderCartViewButtons >>> ', e)
  }
  let finalUrl
  if (cartType) {
    const finalURLBasedOnCartTypeInURL = apiUrl?.replace(
      'USER',
      isExpressCheckout(true) ? 'EXPRESS' : cartType
    )
    finalUrl = finalURLBasedOnCartTypeInURL + path + queryParamString
  } else {
    if (contextValue) {
      if (isExpressCheckout(true)) {
        const personalOfferApiUrl = apiUrl?.replace('USER', 'EXPRESS')
        finalUrl = personalOfferApiUrl + path + queryParamString
      } else if (personalOffer === true) {
        const personalOfferApiUrl = apiUrl?.replace('USER', 'POCART')
        finalUrl = personalOfferApiUrl + path + queryParamString
      } else if (mysite === true) {
        const mysiteOfferApiUrl = apiUrl?.replace('USER', 'MSCART')
        finalUrl = mysiteOfferApiUrl + path + queryParamString
      } else if (
        contextValue === 'MOBILE_CONSUMER' ||
        contextValue === 'MOBILE_AFFILIATE'
      ) {
        // finalURL for VERA & STELA app when context value is present
        finalUrl = apiUrl + path + queryParamString
      } else {
        finalUrl = apiUrl + path + queryParamString
      }
    } else {
      const customURL = isExpressCheckout(true)
        ? apiUrl?.replace('USER', 'EXPRESS')
        : apiUrl
      finalUrl = customURL + path + queryParamString
    }
  }

  finalUrl = replaceLocaleInURL({ url: finalUrl })
  const modifiedHeader = ignoreCommonHeader ? {} : new Headers(combinedHeaders)
  const fetchProps = {
    method,
    headers: modifiedHeader,
    credentials: credentials,
    body:
      combinedHeaders['Content-Type'] === 'application/json'
        ? JSON.stringify(postData)
        : postData,
  }
  if (keepAlive) {
    fetchProps.keepalive = true
  }

  if (mode) {
    fetchProps.mode = mode
  }

  if (!fetchProps.body) {
    delete fetchProps.body
  }

  if (isToDeleteHeader) {
    delete fetchProps.headers
  }

  // Abort API on delay
  let controller = ''
  if (AbortController) {
    controller = new AbortController()
    fetchProps.signal = controller.signal
    if (!IS_BROWSER) {
      setTimeout(() => {
        controller.abort()
      }, API_ABORT_DELAY)
    }
  }
  /*
   * https://nuskin.atlassian.net/browse/CX12-9849
   * below code is to abort the fetch call,
   * if the same api is called again before the previous call is completed
   * when 43 is typed in input, call will be made for 4 and 43
   * so we need to abort the call for 4
   * to handle uncertainties in response time delays
   */
  if (endPointName === 'getProductbyIdandPromotion') {
    const isSubscription =
      postData?.isSubscriptionIncludedInPromotion?.toString() || ''
    const isModalRelatedCall = (
      postData?.isSubscriptionPromotion || false
    ).toString()
    const key =
      endPointName +
      '_isSubscriptionIncludedInPromotion_' +
      isSubscription +
      isModalRelatedCall
    /*
     * we are using the same endpoint for both true and false
     * so appending the isSubscriptionIncludedInPromotion value to the key
     */
    if (application.previousAbortControllers[key]) {
      const oldController = application.previousAbortControllers[key]
      oldController.abort?.()
    }
    application.previousAbortControllers[key] = controller
  }
  if (
    endPointName === 'validateProfileAddress' ||
    endPointName === 'emailLookupMx'
  ) {
    /*
     * we are using the same endpoint for both true and false
     * so appending the isSubscriptionIncludedInPromotion value to the key
     */
    if (application.previousAbortControllers[endPointName]) {
      const oldController = application.previousAbortControllers[endPointName]
      oldController.abort?.()
    }
    application.previousAbortControllers[endPointName] = controller
  }

  /**
   * Logs API response data and headers immediately.
   *
   * This is used to log API results for debugging before further response processing.
   *
   * @param {Object} data - The parsed JSON response body
   * @param {Object} response - The fetch response object
   */
  const logNow = async (data, response) => {
    if (!IS_BROWSER) {
      const { logger } = await import('src/lib/logger')
      const apiName = endPointName || ''
      const apiPath = finalUrl || ''
      const message = data?.message || data?.status || ''
      const traceId = response?.headers?._headers?.['x-b3-traceid'] || []
      const spanId = response?.headers?._headers?.['x-b3-spanid'] || []
      const logMessage = `${apiName} ${apiPath} ${message} x-traceid:${traceId.toString()} x-spanid:${spanId}`
      const isFailure = (data?.status || '') === 'failure'

      logger.error(logMessage, {
        className: `apiUtils.fetchResponseFromAPI >>> ${
          isFailure ? 'FAILED' : 'SUCCESS'
        } `,
      })
    }
  }

  /**
   * Common handler for successful API responses.
   *
   * @param {Object} response - The fetch response object
   * @param {Object} data - The parsed JSON response body
   */
  const commonAPISucess = (response, data) => {
    const totalCount = response?.headers?.get?.('x-total-count') || ''
    const status = isSuccessStatus(response) ? 'success' : 'failure'
    statusCode = response?.status

    // log the results for tracing
    // logNow(data, response)
    if (typeof data !== 'object') {
      const responseMessage = data
      data = {
        responseMessage,
      }
    }

    if (status === 'failure') {
      const apiStatusCode = response?.status || ''
      trackApiErrorThroughInstana({
        apiUrl: finalUrl,
        apiConfig,
        statusCode,
        headers: response?.headers,
        responseMessage: data,
      })
    }

    // status is over-written so added additional node to get the response status
    try {
      data.responseStatus = JSON.parse(JSON.stringify(data?.status || ''))
    } catch (error) {
      console.log('data responseStatus', error)
    }

    data.status = status
    data.totalCount = totalCount
    data.statusCode = statusCode

    if (!IS_BROWSER && isToEnableSSR) {
      if (contextName) {
        const responseData = {
          ...data,
          apiUrl: finalUrl,
        }
        if (contextUrlPathFromSSR) {
          responseData.contextUrlPathFromSSR = contextUrlPathFromSSR
        }
        if (contextQueryParamsFromSSR) {
          responseData.contextQueryParamsFromSSR = contextQueryParamsFromSSR
        }
        return Promise.resolve({
          [contextName]: responseData,
        })
      }
    }
    return data
  }
  let statusCode = ''

  /**
   * Handles successful API responses by parsing the response
   * and returning a standardized success object.
   *
   * @param {Object} response - The fetch API response object.
   * @returns {Promise} A promise resolving to the parsed success response object.
   */
  const onAPISuccess = async response => {
    application.IsAppLoading = false
    decrementFetchCount()
    // Setting the session id in header for SSR user based rendering.
    // Removing this we need to use x-sk-signed-user but this by SFO for UI domain
    // const getSessionId = response?.headers?.get?.('x-sk-session-id') || ''
    // setCookie({ cookieName: 'x-sk-session', cookieValue: getSessionId })

    if (endPointName === 'clearOktaUserSession') {
      return response.text().then(data => {
        return commonAPISucess(response, data)
      })
    } else {
      if (response.status === 204) {
        return commonAPISucess(response, '')
        // return { status: 'success' }
      } else {
        return response?.json().then(data => {
          return commonAPISucess(response, data)
        })
      }
    }
  }

  /**
   * Handles API request failures by logging the error and tracking it.
   *
   * @param {Object} error - The error object from the failed API request.
   */
  const onAPIFail = error => {
    trackErrorInInstana({
      errorReport: `on API fail ${finalUrl} `,
      errorData: error,
    })

    // console.log(
    //   'onAPIFail error from ' +
    //     (IS_BROWSER ? 'BROWSER ' : 'SERVER ') +
    //     JSON.stringify(apiConfig) +
    //     ' status ' +
    //     statusCode +
    //     ' ' +
    //     error
    // )
    trackApiErrorThroughInstana({
      statusCode,
      apiUrl: finalUrl,
      apiConfig,
      error,
    })
    // console.error('API Request Status: Failed >>> ', finalUrl)
    // console.error('API call error', e)
    application.IsAppLoading = false
    decrementFetchCount()
    return { status: 'failure' }
  }
  const { CS_ID = '', CS_TOKEN = '', CS_ENV = '' } = APPConfig?.getAppConfig()

  incrementFetchCount()
  if (endPointName === 'studioData' || endPointName === 'liveEventStudioData') {
    let contentStackLocale =
      queryParams?.locale?.split('_').reverse().join('-') || 'US-en'
    if (contentStackLocale === 'CN-zh') {
      contentStackLocale = 'CA-zh'
    }

    const entryName =
      endPointName === 'liveEventStudioData'
        ? 'ns_live_event_home_page'
        : 'homepage'

    let url = `https://cdn.contentstack.io/v3/content_types/${entryName}/entries/?locale=${contentStackLocale}&include_reference_content_type_uid=true&environment=${CS_ENV}`
    finalUrl = url
    console.log(`API Request >>> `, url)
    return await fetch(url, {
      method: 'GET',
      headers: {
        api_key: CS_ID,
        access_token: CS_TOKEN,
      },
    })
      .then(onAPISuccess)
      .catch(onAPIFail)
  } else if (endPointName === 'footerData') {
    let contentStackLocale =
      queryParams?.locale?.split('_')?.reverse()?.join('-') || 'US-en'
    if (contentStackLocale === 'CN-zh') {
      contentStackLocale = 'CA-zh'
    }
    let url = `https://cdn.contentstack.io/v3/content_types/footer/entries/blt0779283eb7dd0800/?locale=${contentStackLocale}&environment=${CS_ENV}`
    finalUrl = url
    console.log(`API Request >>> `, url)
    return await fetch(url, {
      method: 'GET',
      headers: {
        api_key: CS_ID,
        access_token: CS_TOKEN,
      },
    })
      .then(onAPISuccess)
      .catch(onAPIFail)
  } else {
    console.log(`API Request >>> `, finalUrl)
    try {
      return await fetch(finalUrl, fetchProps)
        .then(onAPISuccess, onAPIFail)
        .catch(onAPIFail)
    } catch (e) {
      console.error(`Fetch exception ${finalUrl}`, e)
    }
  }
}

export {
  fetchResponseFromAPI,
  getLocalStorage,
  setLocalStorage,
  deleteFromLocalStorage,
  setCookie,
  getCookie,
  deleteCookie,
  isB2BAccount,
  isB2BAdmin,
  isB2BUser,
  isB2B2C,
  isB2B2CAdmin,
  enableB2B2CUserInviteCreate,
  isenableUserInvite,
  hasOngoingFetches,
}
export default fetchResponseFromAPI
