import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { isB2BAccount, isB2BUser, trackEnhancedCheckout } from 'src/utils'
import {
  cartContainer,
  checkoutContainer,
  customerContainer,
  addressContainer,
  accountsContainer,
  accountsAddressContainer,
} from 'src/models'
import { storeContainer } from 'src/models/Store'
import {
  toastState,
  overlayState,
  ShippingAddressListWithForm,
  AddressValidation,
} from 'src/views/components'
import { isAddressEqual } from '../deps'
import { pageNames } from 'src/routes/pathParams'
import { handleAddressValidation } from 'src/deps'
import { isPromoWalletEnabled } from 'src/views/components/CheckoutAccordions/deps'
import { isToHaveOptionalPhoneSchema } from 'src/views/addresses/addressUtils'

@observer
class ShippingAccordion extends React.Component {
  @observable addressList = null
  @observable initiallySelectedAddress = ''
  @observable isAddressFromProfile = false
  @observable isToDisableShippingContinueBtn = false
  @observable shippingMethodAdded = false
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      flag: false,
      recommendations: [],
      formDataEnter: {},
      selectedAddress: {},
      addressInvalidFlag: false,
      isAddressNotAdded: false,
    }
  }

  async componentDidMount() {
    this.getAddressFromProfile()
    if (cartContainer?.cartResponse?.payments) {
      await checkoutContainer.deletePayment()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRegisteredUser !== this.props.isRegisteredUser) {
      this.getAddressFromProfile()
    }
  }

  modifyAndSetAddressForB2B = async response => {
    // ----------------below will happen in ShippingAddressListWithForm ----------------
    // if cart contains address,
    // cart address will be compared to addressList
    // if cart address matches with addressList, radio button will be selected
    // else default address will be selected

    // here if we dont have address in cart
    // and if  BUSINESS_RELATIONSHIP is B2B
    // make the below address as default address
    if (this.initiallySelectedAddress === '' && isB2BAccount()) {
      const address = accountsContainer?.profileResponse?.addresses?.['0'] || ''
      const newAddress =
        address === '' ? await this.makeAccountCalls() : address
      if (
        newAddress.country &&
        (newAddress.country.toLowerCase() === 'us' ||
          newAddress.country.toLowerCase() === 'united states')
      ) {
        newAddress.country = 'US'
        this.initiallySelectedAddress = newAddress
      }
    }
    this.addressList = response
  }

  getAddressList = async () => {
    if (isB2BAccount() && isB2BUser()) {
      const accountAddressResponse = accountsAddressContainer?.addressData || []
      // this.modifyAndSetAddressForB2B(accountAddressResponse)
      this.addressList = accountAddressResponse
    } else {
      const customerAddressResponse = await addressContainer.getProfileAddress()
      this.addressList =
        customerAddressResponse && customerAddressResponse.length
          ? customerAddressResponse
          : []
    }
  }

  getAddressFromProfile = async () => {
    const addressToSelectOrPrefill =
      cartContainer.cartResponse?.deliveryDetails?.address || ''
    this.initiallySelectedAddress = addressToSelectOrPrefill
    if (this.props.isRegisteredUser) {
      this.getAddressList()
    } else {
      this.addressList = []
    }
    this.isAddressFromProfile = true
  }

  handleModal = async (formData, reqBody) => {
    this.isToDisableShippingContinueBtn = true
    const formDataTemp = {
      ...formData,
      shippingInstructions: reqBody?.shippingInstructions || '',
      setDefaultAddress:
        reqBody?.setDefaultAddress || reqBody?.isDefault || false,
    }

    const res = await addressContainer.validateAddress(reqBody)
    if (res?.code === 'ADCOVAL0003') {
      this.setState({
        show: true,
        addressInvalidFlag: true,
        recommendations: [{ ...formDataTemp }, []],
        formDataEnter: { ...formDataTemp },
      })
      if (isExpressCheckout()) {
        this.setState({ isAddressNotAdded: true })
      }
    } else if (res?.code == 'ONESOURCE_ADPLGIN_VAL_ERROR') {
      this.setState({
        show: false,
      })
      if (isExpressCheckout()) {
        this.setState({ isAddressNotAdded: true })
      }
      toastState.setToastMessage(
        i18nTranslate('address.NotAdded', 'Address not added')
      )
      this.isToDisableShippingContinueBtn = false
      return
    } else if (checkoutContainer.isSuccessResponse(res)) {
      let recommendationAddress =
        res?.address?.validationDetails?.[0]?.recommendations || []
      let firstAddress = recommendationAddress?.[0] || {}
      if (
        Object.keys(firstAddress)?.length > 0 &&
        handleAddressValidation(formDataTemp, firstAddress)
      ) {
        this.setState({ addressInvalidFlag: false })
        // const validationcode = res.address.validationDetails[0].responseCode
        this.setState({
          show: true,
          recommendations: [
            { ...formDataTemp },
            ...res?.address?.validationDetails?.[0]?.recommendations,
          ],
          formDataEnter: { ...formDataTemp },
        })
        if (isExpressCheckout()) {
          this.setState({
            isAddressNotAdded: false,
          })
        }
      } else {
        this.handleClickOnPro(formDataTemp)
      }
    } else {
      this.setState({
        show: false,
      })
      if (isExpressCheckout()) {
        this.setState({ isAddressNotAdded: true })
      }
      toastState.setToastMessage(
        i18nTranslate('address.NotAdded', 'Address not added')
      )
      this.isToDisableShippingContinueBtn = false
      return
    }
    this.isToDisableShippingContinueBtn = false
  }

  makeAccountCalls = async () => {
    const accountId = customerContainer.profileResponse?.accounts?.id || ''
    const response = await accountsContainer.getProfile(accountId)
    return response?.address?.['0']
  }

  validateAddressFields = accountAddress => {
    if (
      accountAddress &&
      accountAddress.addressLine1 &&
      accountAddress.firstName &&
      accountAddress.lastName &&
      accountAddress.city &&
      accountAddress.state &&
      accountAddress.zip
    ) {
      return true
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'addressBook.invalidAddress',
          'Invalid address, please add a new address or update the existing address'
        )
      )
      return false
    }
  }

  validateCommunicationPreferences = accountAddress => {
    let isValid = true
    let isOptionalPhone = isToHaveOptionalPhoneSchema('checkout') || false
    if (!accountAddress?.phone && !isOptionalPhone) {
      toastState.setToastMessage(
        i18nTranslate('addressBook.invalidPhone', 'Please enter contact number')
      )
      isValid = false
    } else if (!accountAddress?.email) {
      toastState.setToastMessage(
        i18nTranslate('validation.emailErrorText', 'Please enter email')
      )
      isValid = false
    }
    return isValid
  }

  checkCommunicationPreference = async () => {
    const communicationResponse =
      await checkoutContainer.setCommunicationPreferenceForZeroCheckout()
    let isError = false
    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      toastState.setToastMessage(
        communicationResponse?.message || communicationResponse?.errorMessage
      )
      isError = true
      this.isToDisableShippingContinueBtn = false
    }
    return isError
  }

  handleAddressContinue = async formData => {
    if (isExpressCheckout()) {
      this.shippingMethodAdded = false
      overlayState?.showLoader()
    }
    this.isToDisableShippingContinueBtn = true
    const { onContinue, title } = this.props
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '1', option: title },
      products: products,
    })
    if (!formData.email) {
      formData.email = customerContainer.profileResponse.email
    }
    if (
      isExpressCheckout() &&
      addressContainer?.isFirstAddress &&
      !formData.setDefaultAddress
    ) {
      formData.setDefaultAddress = true
    }
    if (
      this.validateAddressFields(formData) &&
      this.validateCommunicationPreferences(formData)
    ) {
      let response = await checkoutContainer.setShippingAddress(formData)
      response?.items?.length > 0 &&
        response?.items?.forEach(item => {
          item &&
            item.skus &&
            item.skus?.map(bundleItem => {
              let inventoryProperties = bundleItem.inventoryProperties
              if (
                (inventoryProperties?.available === false &&
                  inventoryProperties?.backOrdered === true) ||
                (inventoryProperties?.available === false &&
                  inventoryProperties?.backOrdered === false) ||
                (inventoryProperties?.available === false &&
                  inventoryProperties?.backOrdered === null)
              ) {
                toastState.setToastMessage(
                  i18nTranslate(
                    'checkout.backOrder',
                    'Item got backordered or OOS'
                  ),
                  false
                )
                setTimeout(() => {
                  let originUrl =
                    window?.location?.href?.split('/checkout') || []
                  window.location.replace(
                    `${originUrl?.[0]}${pageNames?.viewCart}`
                  )
                }, 1000)
              }
            })
        })
      isPromoWalletEnabled() && (await cartContainer.promoCodeList())
      if (checkoutContainer.isSuccessResponse(response)) {
        // checkoutContainer.setCommunicationPreferenceForZeroCheckout()
        const isErrorInPreference = await this.checkCommunicationPreference()
        if (isExpressCheckout()) {
          overlayState?.hideLoader()
        }
        if (isErrorInPreference) {
          return
        }
        if (isExpressCheckout()) {
          this.initiallySelectedAddress = response?.deliveryDetails?.address
          this.shippingMethodAdded = true
          if (customerContainer?.isRegisterUser) {
            this.getAddressFromProfile()
          }
        }
        onContinue()
      } else {
        toastState.setToastMessage(response.message || response.responseMessage)
        if (isExpressCheckout()) {
          this.setState({ isAddressNotAdded: true })
          overlayState?.hideLoader()
        }
      }
    } else {
      if (isExpressCheckout()) {
        this.setState({ isAddressNotAdded: true })
        overlayState?.hideLoader()
      }
    }
    this.isToDisableShippingContinueBtn = false
  }

  setIsAddressNotAdded = val => {
    this.setState({ isAddressNotAdded: val })
  }

  handleModalConuBtn = async () => {
    await addressContainer.addAddress(this.state.selectedAddress)
    this.setState({
      show: false,
    })
  }

  handleClose() {
    this.setState({
      show: false,
    })
    if (isExpressCheckout()) {
      this.setState({
        isAddressNotAdded: true,
      })
    }
  }

  handleClickOnPro = async dataAfterValidation => {
    dataAfterValidation.type = 'shipping'
    if (dataAfterValidation.selectedIndex <= 0) {
      const response = await addressContainer.validateSignUpOriginalAddress(
        dataAfterValidation
      )
      if (response && response.status !== 'failure') {
        dataAfterValidation.addressId = response.addressId
        this.setState(
          {
            show: false,
          },
          () => {
            this.handleAddressContinue(dataAfterValidation)
            this.initiallySelectedAddress = ''
          }
        )
      } else {
        this.setState({
          show: false,
        })
      }
    } else {
      /**
       * @note
       * Checkout - As per BE Confirmation, removed Add Address API while adding New Shipping Address,
       * as Address will be automatically added to Profile from USER Address Patch API for Registered Users
       */
      this.setState(
        {
          show: false,
        },
        () => {
          this.handleAddressContinue(dataAfterValidation)
          if (isExpressCheckout()) {
            this.shippingMethodAdded = false
          }
        }
      )
    }
  }
  validationCallback = () => {
    this.getAddressFromProfile()
  }
  render() {
    const defaultCountry = storeContainer?.defaultShippingRegion || ''
    const filteredAddress = this.addressList
      ? this.addressList.filter(
          data => data?.country === defaultCountry && data?.type === 'shipping'
        )
      : this.addressList
    if (this.isAddressFromProfile && this.addressList) {
      return (
        <>
          {this.state.show && (
            <AddressValidation
              open={this.state.show}
              addressInvalidFlag={this.state.addressInvalidFlag}
              recommendations={this.state.recommendations}
              handleClose={() => this.handleClose()}
              handleClickOnPro={dataAfterValidation =>
                this.handleClickOnPro(dataAfterValidation)
              }
            />
          )}
          <ShippingAddressListWithForm
            addressList={filteredAddress}
            isAddressChecked={this.props.isAddressChecked}
            addressToSelectOrPrefill={this.initiallySelectedAddress || {}}
            handleAddressSelect={this.handleAddressSelect}
            onContinue={this.props.onContinue}
            toggleMultipleAddress={this.props.toggleMultipleAddress}
            isMultipleAdressActive={this.props.isMultipleAdressActive}
            handleModal={this.handleModal}
            isFromCheckout={true}
            handleAddressContinue={this.handleAddressContinue}
            isAddressNotAdded={this.state.isAddressNotAdded}
            setIsAddressNotAdded={this.setIsAddressNotAdded}
            shippingMethodAdded={this.shippingMethodAdded}
            validationCallback={this.validationCallback}
            isToDisableShippingContinueBtn={this.isToDisableShippingContinueBtn}
            isFromPDP={this.props.isFromPDP}
          />
        </>
      )
    }

    return <React.Fragment />
  }
}

export { ShippingAccordion }
export default ShippingAccordion
