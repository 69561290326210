import React from 'react'
import { Form, Col } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import './styles.scss'
import { addressContainer } from 'src/models'
import AddressList2 from '../AddressBook/AddressList2'
import { isToHaveOptionalPhoneSchema } from 'src/views/addresses/addressUtils'

@observer
class ShippingAddressComponent extends React.Component {
  render() {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      email,
      phone,
      country,
      isDefault,
    } = this.props.addressInfo
    return <Col></Col>
  }
}

@observer
class ShippingAddressList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEdit: null,
      viewMore: false,
      current: null,
      oldlist: addressContainer.addressData,
      newlist: null,
    }
    this.handleViewMore = this.handleViewMore.bind(this)
  }

  handleCallback = childData => {
    const editAddressId = this.state.isEdit == childData ? null : childData
    this.setState({ isEdit: editAddressId })
  }

  componentDidMount() {
    const { handleSelect } = this.props
    const { currentlySelectedAddress, addressList } = this.props
    const defaultAddress = addressList.find(address => address.isDefault)
    if (!isExpressCheckout()) {
      if (this.props.isAddressChecked) {
        handleSelect(currentlySelectedAddress)
      } else if (defaultAddress) {
        handleSelect(defaultAddress)
      } else {
        handleSelect(addressList[0])
      }
    }
  }

  handleViewMore() {
    const { viewMore } = this.state
    this.setState({ viewMore: !viewMore })
  }

  renderShippingAddressComponent = (address, isValidAddress) => {
    return (
      <AddressList2
        handleContinueBtnState={this.props.handleContinueBtnState}
        addressInfo={address}
        isEdit={this.state.isEdit}
        handleSelect={this.props.handleSelect}
        radioBtnDisabled={this.props.disableRadioBtn}
        parentCallback={this.handleCallback}
        isValidAddress={isValidAddress}
        validationCallback={this.props.validationCallback}
        isAddressNotAdded={this.props?.isAddressNotAdded}
        setIsAddressNotAdded={this.props?.setIsAddressNotAdded}
      />
    )
  }

  isValidAddressFields = accountAddress => {
    if (
      accountAddress &&
      accountAddress.addressLine1 &&
      accountAddress.firstName &&
      accountAddress.lastName &&
      accountAddress.city &&
      accountAddress.state &&
      accountAddress.zip
    ) {
      return true
    } else {
      return false
    }
  }

  validateCommunicationPreferences = accountAddress => {
    let isValidCommunication = true
    let communicationErrorMessage = ''
    let isOptionalPhone = isToHaveOptionalPhoneSchema('checkout') || false
    if (!accountAddress?.phone && !isOptionalPhone) {
      communicationErrorMessage = i18nTranslate(
        'addressBook.invalidPhone',
        'Please enter contact number'
      )
      isValidCommunication = false
    } else if (!accountAddress?.email) {
      communicationErrorMessage = i18nTranslate(
        'validation.emailErrorText',
        'Please enter email'
      )
      isValidCommunication = false
    }
    return { isValidCommunication, communicationErrorMessage }
  }

  render() {
    const {
      currentlySelectedAddress,
      handleSelect,
      isFromItemLevelShipping = false,
      cartItemId,
      addressList,
    } = this.props
    const { viewMore } = this.state
    const defaultAddress = addressList.find(address => address.isDefault)
    if (defaultAddress) {
      // Put 'Default address' to index 0
      addressList.splice(
        addressList.findIndex(address => address.isDefault),
        1
      )
      addressList.splice(0, 0, defaultAddress)
    }

    return (
      <React.Fragment>
        {addressList
          .filter(accountAddress =>
            this.state.isEdit
              ? accountAddress.addressId === this.state.isEdit
              : true
          )
          .filter((item, index) => (!this.state.viewMore ? index <= 1 : true))
          .map(accountAddress => {
            // 1) if cart address is present, check whether it matches address in list
            // if not matches new form will be selected
            // 2) if cart address is NOT present, default address will be selected
            let isValidAddress = true
            let errorMessage = ''
            const { isValidCommunication, communicationErrorMessage } =
              this.validateCommunicationPreferences(accountAddress)
            if (!this.isValidAddressFields(accountAddress)) {
              isValidAddress = false
              errorMessage = i18nTranslate(
                'addressBook.invalidAddress',
                `Invalid Address,
              Please add a new address or update the existing
              address`
              )
            } else if (!isValidCommunication) {
              isValidAddress = false
              errorMessage = communicationErrorMessage
            }
            // console.log(isValidAddress)

            {
              /* const shouldSelectAddress =
              currentlySelectedAddress && currentlySelectedAddress.addressLine1
                ? stringifyAddress(accountAddress) ===
                  stringifyAddress(currentlySelectedAddress)
                : !isFromItemLevelShipping
                ? accountAddress?.isDefault || false
                : false */
            }
            let shouldSelectAddress = false
            if (accountAddress?.addressId) {
              if (currentlySelectedAddress?.profileAddressId) {
                shouldSelectAddress =
                  accountAddress?.addressId ==
                  currentlySelectedAddress?.profileAddressId
              } else if (currentlySelectedAddress?.addressId) {
                shouldSelectAddress =
                  accountAddress?.addressId ==
                  currentlySelectedAddress?.addressId
              } else {
                if (accountAddress?.isDefault) {
                  shouldSelectAddress = accountAddress?.isDefault || false
                }
              }
            }
            return (
              <div
                tabIndex={
                  accountAddress.addressId === this.state.isEdit ? -1 : 0
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSelect(accountAddress)
                  }
                }}
                className={`express-address-item bb123 ${
                  shouldSelectAddress && isValidAddress
                    ? 'express-address-selected'
                    : ''
                }`}>
                {accountAddress.addressId === this.state.isEdit ? (
                  <>
                    {this.renderShippingAddressComponent(
                      accountAddress,
                      isValidAddress
                    )}
                  </>
                ) : (
                  <>
                    <Form.Check
                      className="my-2 w-100"
                      id={`address-${accountAddress?.addressId}-${cartItemId}`}
                      key={accountAddress?.addressId}
                      label={this.renderShippingAddressComponent(
                        accountAddress
                      )}
                      type="radio"
                      checked={shouldSelectAddress && isValidAddress}
                      disabled={this.props.disableRadioBtn || false}
                      data-testid={
                        shouldSelectAddress ? 'qa-facet-selected' : 'qa-facet'
                      }
                      onClick={() => {
                        handleSelect(accountAddress)
                      }}
                      tabIndex={0}
                    />
                    {!isValidAddress && (
                      <span className="text-danger">{errorMessage}</span>
                    )}
                  </>
                )}
              </div>
            )
          })}
        {addressList &&
        addressList.length > 2 &&
        !viewMore &&
        !this.state.isEdit ? (
          <button className="more-address-button" onClick={this.handleViewMore}>
            {i18nTranslate('accountProfile.viewMoreButton', 'View more')}
          </button>
        ) : (
          <button
            className="more-address-button v-hide-me"
            onClick={this.handleViewMore}>
            {i18nTranslate('accountProfile.viewMoreButton', 'View more')}
          </button>
        )}
      </React.Fragment>
    )
  }
}

export { ShippingAddressComponent, ShippingAddressList }
