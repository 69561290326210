import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react'
import { Row, Col, Container } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import {
  UserMenu,
  CartIcon,
  FavoriteIconWithLink,
  MobileMenu,
} from 'src/views/components'
import { CommonHeader } from 'src/views/components/Header/CommonHeader'
import { signOutWithRedirect } from 'src/utils/signInUtils'
import { customerContainer } from 'src/models'
import { getMysiteCartUrl } from 'src/utils'
import { LocaleSection } from 'src/views/components/Header/NuskinHeader/LocaleSection'
import { IS_BROWSER, getLocalStorage } from 'config/appConfig'
import './styles.scss'
import { observable } from 'mobx'

@observer
class PersonalHeader extends CommonHeader {
  @observable data = []
  @observable isModalVisible = false
  constructor(props, context) {
    super(props, context)
    this.state = {
      isLoading: true,
      userphoto: '',
    }
  }

  navigateToHome = () => {
    const currentUrl = window.location.href
    // const currentPage = currentUrl.split('/').pop()
    const getURL = url => url?.split('/')?.pop()
    const trimmedCurrentPage = getURL(currentUrl)
    try {
      if (IS_BROWSER) {
        let contextValue
        const shoppingContext = getLocalStorage('shoppingContext') || {}
        if (Object.keys(shoppingContext).length > 0) {
          contextValue = shoppingContext?.context || ''
          if (
            trimmedCurrentPage === getURL(pageNames.viewCart) ||
            trimmedCurrentPage === getURL(pageNames.checkout) ||
            trimmedCurrentPage === getURL(pageNames.expressCheckout) ||
            trimmedCurrentPage === getURL(pageNames.signUp) ||
            trimmedCurrentPage === getURL(pageNames.poSubscripiton) ||
            trimmedCurrentPage === getURL(pageNames.orderConfirmation) ||
            trimmedCurrentPage === getURL(pageNames.klarnaCheckout)
          ) {
            let redirectUrl = getMysiteCartUrl()
            window.location.href = redirectUrl
          } else {
            this.props.history.push(pageNames.home)
            localStorage.removeItem('shoppingContext')
            window.location.reload()
          }
        }
      }
    } catch (e) {
      console.error('JSON.parse may be an error PersonalHeader.render() ', e)
    }
  }

  toggleModal = () => {
    this.isModalVisible = !this.isModalVisible
  }

  render() {
    let profilePhoto = undefined
    let shoppingContext = getLocalStorage('shoppingContext') || {}
    let contextText = shoppingContext?.context || ''

    try {
      if (IS_BROWSER) {
        let footerUserData = sessionStorage.getItem('storefront')
        if (footerUserData) {
          footerUserData = JSON.parse(footerUserData)
          profilePhoto = footerUserData?.profilePhoto
        }
      }
    } catch (e) {
      console.error('JSON.parse may be an error PersonalHeader.render() ', e)
    }

    // const isRegisterUser = customerContainer.isRegisterUser
    const isGuestB2B = customerContainer.isGuestB2B
    // const requestDomain = get(this.props, 'staticContext.requestDomain')
    // const { data, isLoading, userphoto } = this.state
    this.state.userphoto = profilePhoto

    const isRegionPage =
      this.props?.location?.pathname == pageNames.SelectRegion

    if (isRegionPage) {
      return ''
    }

    const isKlarnaCheckoutPage =
      this.props?.location?.pathname === pageNames.klarnaCheckout

    if (isKlarnaCheckoutPage) {
      return <></>
    }

    return (
      <>
        <div className="personalHeader">
          <div className="user-logo" onClick={this.navigateToHome}>
            <img
              src={this.state.userphoto}
              className="rounded-circle shadow-4"
              style={{ width: '50px', cursor: 'pointer' }}
              alt="Avatar"
            />
          </div>
          <section className="header-middle-container">
            <Container fluid>
              <Row noGutters className="align-items-center header-align">
                <Col className="px-0 header-mobile-view header-right-section d-flex justify-content-end order-2 order-md-2 order-lg-3 align-items-center">
                  <div
                    className="personal-header-menu-wrapper"
                    onClick={() => {
                      this.toggleModal()
                    }}>
                    <UserMenu {...this.props} isNuskinHeader />
                  </div>
                  {this.isModalVisible && (
                    <div
                      onClick={signOutWithRedirect}
                      className="signout-mobile">
                      Sign Out
                    </div>
                  )}
                  {contextText === '' && (
                    <FavoriteIconWithLink className="text-decoration-none favorite-wrapper d-flex mx-2 p-0" />
                  )}
                  <LocaleSection data={this.data} isFromPersonalHeader={true} />
                  <div className="ml-lg-3">
                    <CartIcon {...this.props} isNuskinHeader />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {!isGuestB2B && <MobileMenu {...this.props} />}
        </div>
      </>
    )
  }
}

const PersonalHeaderWithRouter = withRouter(PersonalHeader)
export { PersonalHeaderWithRouter }
// export default PersonalHeaderWithRouter
export default withTranslation(['csr-resource'])(PersonalHeaderWithRouter)
